import { ProcessFlowActiveModel, ProcessFlowExecModel } from '../../types/processflow';
import React from 'react';
import { DocumentMagnifyingGlassIcon, MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/20/solid';
import { ProcessflowService } from '../../services/processflow.service';
import { AxiosError, AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { PaginatedResponse } from '../../types/paginated-response';

interface ActiveJobEntriesProps {
    activeJobs: Array<ProcessFlowActiveModel>,
    onTailProcess: Function
}

export const ActiveJobEntriesTable = (props: ActiveJobEntriesProps) => {
    const handleKill = (processFlowExec: ProcessFlowActiveModel) => {
        ProcessflowService.killProcessFlowExec(
            processFlowExec.execId
        ).then((response: AxiosResponse) => {
            console.log(response);
            if (response.status === 200) {
                toast.success('Job killed.');
            }
        }).catch((error: AxiosError) => {
            toast.error(error.message);
        });
    }

    return (
        <div className="w-full z-0">
            <div className='rounded-box px-4 py-2 bg-base-200 border border-base-content border-opacity-20'>
                {
                    !props.activeJobs ?
                        <div className='w-full text-sm'>
                            No active jobs running.
                        </div>
                        : props.activeJobs && props.activeJobs.length === 0 ?
                            <div className='w-full text-sm'>
                                No active jobs running.
                            </div>
                        :
                            <table className='table w-full table-compact table-zebra'>
                                <thead>
                                <tr>
                                    <th className="">Owner</th>
                                    <th className="">Process</th>
                                    <th className="">Result Count</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    props.activeJobs && props.activeJobs.map((activeJob, index) => (
                                            activeJob.execId !== '' &&
                                                <tr key={index} className="hover:drop-shadow">
                                                    <td>
                                                <span className="badge badge-lg text-sm">
                                                    {activeJob.owner}
                                                </span>
                                                    </td>
                                                    <td colSpan={1}>
                                                        <div className="w-full py-2.5 text-sm">
                                                    <span className="flex items-center">
                                                        <span className="w-24 font-bold">
                                                            Description
                                                        </span>
                                                        <span className="w-full text-center">
                                                            {activeJob.procDescr}
                                                        </span>
                                                    </span>
                                                            <div className='my-1' />
                                                            <span className="flex items-center">
                                                        <span className="w-24 font-bold">
                                                            Flow
                                                        </span>
                                                        <span className="rounded-btn px-2 py-0.5 w-full text-center text-primary font-bold">
                                                            {activeJob.procName}
                                                        </span>
                                                    </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="monospace font-bold">
                                                            {
                                                                activeJob.resultCount
                                                            }
                                                        </div>
                                                    </td>
                                                    <td align='center'>
                                                        <div className="flex items-center justify-center">
                                                            <label
                                                                className="btn btn-secondary btn-circle btn-xs mr-2 tooltip flex justify-center items-center"
                                                                data-tip={"Tail Process"}
                                                                htmlFor={'process-flow-exec-tail-modal'}
                                                                onClick={()=>{props.onTailProcess(activeJob)}}
                                                            >
                                                                <MagnifyingGlassIcon className="w-4 h-4"/>
                                                            </label>

                                                            <span className="btn btn-error btn-circle btn-xs tooltip flex justify-center items-center" data-tip={"Kill Job"}
                                                                  onClick={()=> handleKill(activeJob)}
                                                            >
                                                                <TrashIcon className="w-4 h-4"/>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                        ))
                                }
                                </tbody>
                            </table>

                }
            </div>

        </div>
    );
};

export default ActiveJobEntriesTable;