import React, { useEffect, useState } from 'react';
import PaginationButtonGroup, { ResultsPerPageOptions } from '.././pagination-button-group.component';
import { PaginatedResponse } from '../../types/paginated-response';
import { AxiosError, AxiosResponse } from 'axios';

import { ProcessflowService } from '../../services/processflow.service';
import { ProcessFlowExecModel, ProcessFlowExecModelInspect } from '../../types/processflow';
import {
    ClipboardIcon
} from '@heroicons/react/20/solid';
import toast from 'react-hot-toast';
import ProcessFlowExecViewModal from './view-modal.component';
import { SearchableTableProps } from '../../types/util-types';
import { copyToClipboard } from '../../utils/interaction';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

interface ProcessFlowExecEntriesProps extends SearchableTableProps {
    success: boolean,
    noOlderThanDays: number,
    refresher: number
}

export const ProcessFlowExecEntriesTable = (props: ProcessFlowExecEntriesProps) => {
    const [resultsPerPage, setResultsPerPage] = useState<number>(10);

    const [entries, setEntries] = useState<ProcessFlowExecModel[]>();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();

    const [inspectedProcessFlowExec, setInspectedProcessFlowExec] = useState<ProcessFlowExecModelInspect | null>( null);

    useEffect(() => {
        setCurrentPage(1);
    }, [props.success]);

    useEffect(() => {
        ProcessflowService.getFailedProcessFlowExec(
            props.success,
            props.noOlderThanDays,
            currentPage,
            resultsPerPage,
            props.focusedCustaccount,
            props.searchText
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            if (response) {
                console.log(response.data.content);
                setEntries(response.data.content);
                setPaginationResponse(response.data);
            }
        });
    }, [currentPage, props.noOlderThanDays, props.success, props.refresher, props.searchText, props.focusedCustaccount]);

    const handleRequeue = (processFlowExec: ProcessFlowExecModel) => {
        if (!processFlowExec.scheduled) {
            ProcessflowService.requeueProcessFlowExec(
                processFlowExec.execId
            ).then((response: AxiosResponse) => {
                if (response.status === 200) {
                    toast.success('Job requeued.');

                    ProcessflowService.getFailedProcessFlowExec(
                        props.success,
                        props.noOlderThanDays,
                        currentPage,
                        resultsPerPage,
                        props.focusedCustaccount
                    ).then((response: AxiosResponse<PaginatedResponse>) => {
                        if (response) {
                            setEntries(response.data.content);
                            setPaginationResponse(response.data);
                        }
                    });

                }
            }).catch((error: AxiosError) => {
                toast.error(error.message);
            });
        }
    }


    const handleInspect = (processFlowExec: ProcessFlowExecModel) => {
        ProcessflowService.inspectProcessFlowExec(
            processFlowExec.execId
        ).then((response: AxiosResponse) => {
            if (response.status === 200) {
                toast.success('Information retrieved.');
                setInspectedProcessFlowExec(
                    response.data
                );
            }
        }).catch((error: AxiosError) => {
            toast.error(error.message);
        });
    }

    const handleDiscard = (processFlowExec: ProcessFlowExecModel) => {
        ProcessflowService.discardProcessFlowExec(
            processFlowExec.execId
        ).then((response: AxiosResponse) => {
            if (response.status === 200) {
                toast.success('Job discarded.');

                ProcessflowService.getFailedProcessFlowExec(
                    props.success,
                    props.noOlderThanDays,
                    currentPage,
                    resultsPerPage,
                    props.focusedCustaccount
                ).then((response: AxiosResponse<PaginatedResponse>) => {
                    if (response) {
                        setEntries(response.data.content);
                        setPaginationResponse(response.data);
                    }
                });
            }
        }).catch((error: AxiosError) => {
            toast.error(error.message);
        });
    }

    console.log(entries);

    return (
        <div className="max-w-8xl w-full">
            <ProcessFlowExecViewModal
                inspectedProcessFlowExec={inspectedProcessFlowExec}
                onDiscard={handleDiscard}
                onRequeue={handleRequeue}
            />

            <div className=''>
                <table className='table w-full table-compact table-zebra'>
                    <thead>
                    <tr >
                        <th className="">Last Queued</th>
                        <th>Uuid</th>
                        <th className="">Owner</th>
                        <th className="">Process</th>
                        <th className=""></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        entries && entries.map(processflowexec => (
                            <React.Fragment key={processflowexec.execId}>
                                <tr className="hover:drop-shadow">
                                    <td colSpan={1} className="monospace">
                                        <AuditedInformation
                                            date={processflowexec.dateLastQueued}
                                            by={null}
                                            type={AUDITED_INFORMATION_TYPES.LAST_QUEUED}
                                        />
                                    </td>
                                    <td>
                                        <div
                                            className="text-xs btn btn-ghost btn-sm justify-start items-center pl-0 pr-1"
                                            onClick={()=>copyToClipboard(processflowexec.execId)}
                                        >
                                            <ClipboardIcon className="w-4 h-4 mr-1"/>
                                            Copy UUID
                                        </div>
                                        {/*<div className="flex flex-wrap max-w-4xs items-start justify-start text-xs font-mono">*/}
                                        {/*    <span className='font-mono'>*/}
                                        {/*          {processflowexec.execId.slice(0, 9)}*/}
                                        {/*    </span>*/}
                                        {/*    <span className='font-mono'>*/}
                                        {/*          {processflowexec.execId.slice(9, 18)}*/}
                                        {/*    </span>*/}
                                        {/*    <span className='font-mono'>*/}
                                        {/*          {processflowexec.execId.slice(18, 27)}*/}
                                        {/*    </span>*/}
                                        {/*    <span className='font-mono'>*/}
                                        {/*          {processflowexec.execId.slice(27, 36)}*/}
                                        {/*    </span>*/}
                                        {/*</div>*/}
                                    </td>
                                    <td colSpan={1}>
                                        {processflowexec.flowOwner}
                                    </td>
                                    <td colSpan={1}>
                                        <div className='w-full py-2.5 text-sm'>
                                            <span className='flex items-center'>
                                                <span className='w-24 font-bold'>
                                                    Description
                                                </span>
                                                <span className='w-full text-center'>
                                                    {processflowexec.procDescr}
                                                </span>
                                            </span>
                                            <div className='my-1' />
                                            <span className='flex items-center'>
                                                <span className='w-24 font-bold'>
                                                    Flow
                                                </span>
                                                <span
                                                    className='rounded-btn px-2 py-0.5 w-full text-center text-primary font-bold'>
                                                    {processflowexec.procName}
                                                </span>
                                            </span>

                                            <div className='my-2' />

                                            {
                                                processflowexec?.debug &&
                                                <pre>
                                                            <div
                                                                className={`w-full p-3 ${props.success ? 'bg-none' : 'bg-error'} bg-opacity-5 rounded text-base-content h-60 text-xs max-w-2xl overflow-scroll font-mono border border-base-content border-opacity-20`}
                                                                dangerouslySetInnerHTML={{ __html: processflowexec?.debug ? processflowexec?.debug : '' }}
                                                            />
                                                        </pre>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex items-center w-full gap-2 justify-center">
                                            {
                                                (!props.success) &&
                                                <button
                                                    className={`btn btn-primary btn-link btn-sm hover:opacity-70`}
                                                    onClick={()=>handleRequeue(processflowexec)}>
                                                    requeue
                                                </button>
                                            }

                                            <label
                                                htmlFor={"process-flow-exec-modal"}
                                                className="btn btn-link text-secondary hover:opacity-70 btn-sm"
                                                onClick={()=>handleInspect(processflowexec)}
                                            >
                                                inspect
                                            </label>

                                            <button
                                                className="btn btn-warning btn-outline btn-sm"
                                                onClick={()=>handleDiscard(processflowexec)}>
                                                discard
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                            ))
                    }
                    </tbody>
                </table>

                {entries && entries.length === 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>

            <PaginationButtonGroup onPageClick={setCurrentPage} page={currentPage}
                                   resultsPerPage={resultsPerPage}
                                   numberOfResults={paginationResponse?.numberOfElements}
                                   totalResults={paginationResponse?.totalElements}
                                   numberOfPages={paginationResponse?.totalPages}
            />

            <div className="w-full flex items-center justify-center gap-2 mb-2 monospace">
                <select
                    onChange={(e) => {
                        setResultsPerPage(Number(e.target.value))
                        setCurrentPage(1);
                    }}
                    value={resultsPerPage}
                    className='select select-bordered select-xs'>
                    {
                        ResultsPerPageOptions.map((results) => {
                            return <option key={results}>{results}</option>;
                        })
                    }
                </select>
            </div>
        </div>
    );
};

export default ProcessFlowExecEntriesTable;