import http from '../http-common';
import { CustaccountModel, CustaccountSearchResponse } from '../types/custaccount';

export const CustaccountService = {
    searchCustaccounts,
    getCustaccounts,

    getFocusedCustaccount,
    setFocusedCustaccount,
    removeFocusedCustaccount
};

async function searchCustaccounts(searchText: string, resultPage: number, resultsPerPage: number) {
    return http.get<CustaccountSearchResponse>('/app/custaccount?resultPage=' + resultPage + '&resultsPerPage=' + resultsPerPage + (searchText ? ('&searchText=' + searchText) : ''));
}

async function getCustaccounts() {
    return http.get<CustaccountSearchResponse>('/app/custaccount?resultPage=1&resultsPerPage=500');
}

function getFocusedCustaccount(): CustaccountModel | null {
    let json = localStorage.getItem('focusedCustaccount');
    if (json) {
        try {
            return JSON.parse(json);
        } catch (e) {
            return null;
        }

    } else {
        return null;
    }
}

function setFocusedCustaccount(custaccount: CustaccountModel) {
    localStorage.setItem('focusedCustaccount', JSON.stringify(custaccount));
}

function removeFocusedCustaccount() {
    localStorage.removeItem('focusedCustaccount');
}