import React, { useEffect, useState } from 'react';
import { MasterdataService } from '../../services/masterdata.service';
import { ProcessflowService } from '../../services/processflow.service';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { PasswordMaintain } from '../../components/password-maintain.component';
import { FocusablePageProps } from '../../types/util-types';

const HomePage = (props: FocusablePageProps) => {

    const navigate = useNavigate();

    const [numberOfMappingRequests, setNumberOfMappingRequests] = useState<number>();
    const [numberOfFailedJobs, setNumberOfFailedJobs] = useState<number>();

    useEffect(() => {
        document.title = 'UK1 Admin';
    }, []);

    useEffect(() => {

        MasterdataService.getMappingRequestTypes(
            props.focusedCustaccount
        ).then((response: AxiosResponse<any>) => {
            let sum = 0;
            Object.keys(response.data).map((key) => {
                sum += response.data[key];
            });
            setNumberOfMappingRequests(sum);
        });

    }, [props.focusedCustaccount]);

    useEffect(() => {

        ProcessflowService.getFailedProcessflowExecCount(
            props.focusedCustaccount
        ).then((response: AxiosResponse<any>) => {
            setNumberOfFailedJobs(response.data);
        });

    }, [props.focusedCustaccount]);

    return (
        <>
            <div className='flex justify-center items-center mt-10 space-x-2 space-y-2 flex-wrap'>

                <PasswordMaintain />

                <div className='stats shadow bg-base-200'>

                    {numberOfMappingRequests != null && <div
                        onClick={() => navigate('/masterdata/mapping-request')}
                        className='stat place-items-center cursor-pointer hover:bg-base-300/50'>
                        <div className='stat-title'>Masterdata</div>
                        <div
                            className={`stat-value ${numberOfMappingRequests > 0 ? 'text-error' : 'text-success'}`}>{numberOfMappingRequests}</div>
                        <div className='stat-desc'>Mapping Requests</div>
                    </div>}

                    {numberOfFailedJobs != null && <div
                        className='stat place-items-center cursor-pointer hover:bg-base-300/50'
                        onClick={() => navigate('/processflowexec')}
                    >
                        <div className='stat-title'>Process Flow</div>
                        <div className='stat-value text-error'>{numberOfFailedJobs}</div>
                        <div className='stat-desc'>Current Exceptions</div>
                    </div>}

                </div>
            </div>
        </>
    );
};

export default HomePage;