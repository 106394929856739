import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { MsginMapping } from '../../types/msginmapping';
import { MsginMappingService } from '../../services/msginmapping.service';
import { ArrowPathIcon, FingerPrintIcon, TrashIcon } from '@heroicons/react/20/solid';
import toast from 'react-hot-toast';
import { CustaccountModel } from '../../types/custaccount';
import { jpaDateToString } from '../../utils/date';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

interface MsginMappingEntriesProps {
    refresher: number,
    onRefresh: Function,
    focusedCustaccount: CustaccountModel | null
}

export const MsginMappingEntriesTable = (props: MsginMappingEntriesProps) => {
    const [entries, setEntries] = useState<MsginMapping[]>([]);

    useEffect(() => {
        MsginMappingService.getAllMsginMappings(props.focusedCustaccount)
            .then((response: AxiosResponse<MsginMapping[]>) => {
            setEntries(response.data);
        });
    }, [props.refresher, props.focusedCustaccount]);

    const handleDelete = (msginMapping: MsginMapping) => {
        MsginMappingService.removeMsginMapping(msginMapping.control).then((response: AxiosResponse)=>{
            if (response.status >= 200 && response.status < 300) {
                toast.success("Msginmapping removed.")
                props.onRefresh();
            }
        });
    }

    const handleResubmit = (msginMapping: MsginMapping) => {
        MsginMappingService.resubmitMsginMapping(msginMapping.uuid).then((response: AxiosResponse)=>{
            if (response.status >= 200 && response.status < 300) {
                toast.success("Resubmitted mapping.")
                props.onRefresh();
            }
        });
    }

    return (
        <div className="max-w-8xl w-full">
            <div className='pb-4'>
                <table className='table w-full table-compact table-zebra'>
                    <thead>
                    <tr >
                        {/*<th className="normal-case" colSpan={1}>Date Created</th>*/}
                        <th className="" colSpan={1}>Owner</th>
                        <th className="" colSpan={1}>Date Created</th>
                        <th className="" colSpan={1}>Process Flow</th>
                        <th className="text-center" colSpan={1}>File Rules</th>
                        <th className="" colSpan={1}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        entries && entries.map((msginMapping, index) => (
                                <tr key={msginMapping.control} className="hover:drop-shadow">
                                    <td colSpan={1}>
                                        <div className="">
                                            {msginMapping.custaccountModel?.systemCode}
                                        </div>
                                    </td>

                                    <td colSpan={1}>
                                        <div className="monospace">
                                            <AuditedInformation date={msginMapping.dateCreated} by={msginMapping.createdBy} type={AUDITED_INFORMATION_TYPES.CREATED}/>
                                        </div>
                                    </td>

                                    <td colSpan={1}>
                                        <div className="font-bold text-primary">
                                            {msginMapping.processFlowModel?.descr}
                                        </div>
                                    </td>
                                    <td colSpan={1}>
                                        <div className="w-full flex justify-start items-center gap-2">
                                            <div className="flex flex-col justify-center items-center w-1/3">
                                                <p className="text-xs text-base-content text-opacity-60 lowercase">
                                                    starts with
                                                </p>
                                                <p className="font-bold my-2 text-base h-8 tooltip" data-tip={msginMapping.startsWith}>
                                                    {
                                                        msginMapping.startsWith && msginMapping.startsWith.length>24? msginMapping.startsWith.substring(0, 24)+'…' : msginMapping.startsWith
                                                    }
                                                </p>
                                                <span className={`rounded-full tooltip p-0.5 ${msginMapping.startsWithCaseSensitive ? 'badge-error' : 'badge-success'}`} data-tip={msginMapping.startsWithCaseSensitive ? 'Case Sensitive' : 'Ignore Case'}>
                                                    <FingerPrintIcon className="w-4 h-4"/>
                                                </span>
                                            </div>

                                            <div className="flex flex-col justify-center items-center w-1/3">
                                                <p className="text-xs text-base-content text-opacity-60 lowercase">
                                                    contains
                                                </p>
                                                <p className="font-bold my-2 text-base h-8">
                                                    {
                                                        msginMapping.fileNameContains && msginMapping.fileNameContains.length>18? msginMapping.fileNameContains.substring(0, 18)+'…' : msginMapping.fileNameContains
                                                    }
                                                </p>
                                                <span className={`rounded-full tooltip p-0.5 ${msginMapping.fileNameContainsCaseSensitive ? 'badge-error' : 'badge-success'}`} data-tip={msginMapping.fileNameContainsCaseSensitive ? 'Case Sensitive' : 'Ignore Case'}>
                                                    <FingerPrintIcon className="w-4 h-4"/>
                                                </span>
                                            </div>

                                            <div className="flex flex-col justify-center items-center w-1/3">
                                                <p className="text-xs text-base-content text-opacity-60 lowercase">
                                                    ends with
                                                </p>
                                                <p className="font-bold my-2 text-base h-8">
                                                    {
                                                        msginMapping.endsWith
                                                    }
                                                </p>
                                                <span className={`rounded-full tooltip p-0.5 ${msginMapping.endsWithCaseSensitive ? 'badge-error' : 'badge-success'}`} data-tip={msginMapping.endsWithCaseSensitive ? 'Case Sensitive' : 'Ignore Case'}>
                                                    <FingerPrintIcon className="w-4 h-4"/>
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td colSpan={1}>
                                        <div className="flex items-center justify-center gap-2">
                                            <span className="btn btn-sm btn-circle btn-primary tooltip flex justify-center items-center" data-tip={"Resubmit Rule"} onClick={()=>handleResubmit(msginMapping)}>
                                                <ArrowPathIcon className="w-4 h-4"/>
                                            </span>

                                            <span className="btn btn-sm btn-circle btn-error tooltip flex justify-center items-center" data-tip={"Remove Rule"} onClick={()=>handleDelete(msginMapping)}>
                                                <TrashIcon className="w-4 h-4"/>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                    }
                    </tbody>
                </table>

                {entries && entries.length === 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>

        </div>
    );
};

export default MsginMappingEntriesTable;