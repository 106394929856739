import React, { useEffect, useState } from 'react';
import ProcessEntriesTable from '../../components/process/entries-table.component';

const ProcessPage = () => {
    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        document.title = 'Processes';
    }, []);

    return (
        <div>
            <div className='p-2 flex w-full justify-center'>
                <div
                    className="w-full bg-base-300 shadow flex flex-col"
                    style={{
                        maxWidth: '100rem'
                    }}
                >

                <div className='flex space-x-3 justify-between items-center w-full p-2'>

                    <div className='text-sm breadcrumbs'>
                        <ul>
                            <li>
                                <span className="badge badge-lg badge-primary">
                                    Processes
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className='form-control'>
                        <div className='input-group'>
                            <input onChange={(e) => setSearchText(e.target.value)}
                                   type='search'
                                   value={searchText}
                                   placeholder='search…'
                                   className='input input-bordered input-sm w-60' />
                        </div>
                    </div>
                </div>

                <ProcessEntriesTable
                    searchText={searchText}
                    focusedCustaccount={null}
                />

                </div>
            </div>

        </div>
    );
};

export default ProcessPage;