
import React, { useEffect, useState } from 'react';
import { MasterdataMapping } from '../types/masterdata-mapping';
// @ts-ignore
import { ObjectView } from 'react-object-view';
import { isProd } from '../server.config';

interface MetadataView {
    metadata: { [id: string]: any };
    className: string | null
}

export const MetadataViewHorizontal = (props: MetadataView) => {

    const options = {
        hidePreviews: true,
        hideDataTypes: false,
        hideObjectSize: false,
        expandLevel: 8
    };

    const palette = isProd() ? {
        // base00: '#f8fafc', // background
        base00: '#EEF4F4', // background
        base06: '#020617', // key

        base0B: '#3480C7', // strings
        base09: '#1e293b', // numbers

        base0D: '#59677D'
        // https://monojack.github.io/react-object-view/
    } : {
        base00: '#0C111C', // background
        base06: '#F2F5F8', // key

        base0B: '#A08EE1', // strings
        base09: '#626c91', // numbers

        base0D: '#1F2937'
    };

    const styles = {
        fontSize: 12,
        padding: '0px',
        margin: '0px'
    };

    if (Object.keys(props.metadata).length < 1) {
        return (<div></div>);
    }

    return (

        <div
            className={`border border-base-content border-opacity-20 bg-base-100 rounded p-2 max-h-96 h-full overflow-auto ${props.className}`}
        >
            <ObjectView
                data={props.metadata}
                options={options}
                styles={styles}
                palette={palette}
            />
        </div>
    );
};

export default MetadataViewHorizontal;