import React, { useEffect, useState } from 'react';
import { useSubscription } from 'react-stomp-hooks';
import toast from 'react-hot-toast';
import { OPS_WEBSOCKET_HOST } from '../server.config';
import { AuthService } from '../services/auth.service';
interface SocketSubscriptionProps {
    path: string,
    topic: string,
    callback: Function | null
}

export const SocketSubscriptionComponent = (props: SocketSubscriptionProps) => {
    const [lastMessage, setLastMessage] = useState("No message received yet");

    useSubscription(`/${props.path}/${props.topic}`, (message: any) => {
        if (props.callback) {
            props.callback(message.body);
        } else {
            console.log("-------")
            console.log(props.topic)
            console.log(message);
            console.log(message.body);
            setLastMessage(message)
            console.log("-------")
        }
    });

    useEffect(()=>{

    }, [])

    return (
        <>
        </>
    );
};