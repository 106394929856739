import { Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AuthService } from '../services/auth.service';

export const ProtectedRoute = ({ children }: any) => {

    const isLoggedIn = AuthService.checkLoggedIn();

    if (!isLoggedIn) {
        // user is not authenticated
        // user is not authenticated
        return <Navigate to='../login' replace={true} />;
    }

    return children;
};

export const M2ProtectedRoute = ({ children }: any) => {

    const isLoggedIn = AuthService.checkLoggedIn();

    if (!isLoggedIn) {
        // user is not authenticated
        return <Navigate to='../login' replace={true} />;
    }

    if (!(AuthService.isM2Admin() || AuthService.isM2Support())){
        // User is not from m2north
        return <Navigate to='../' replace={true} />;
    }

    return children;
};