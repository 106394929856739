import React, { useEffect, useState } from 'react';
import { ProcessFlowExecModelInspect } from '../../types/processflow';
import MetadataViewHorizontal from '../metadata-view-horizontal.component';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';


interface ProcessFlowExecEntriesTable {
    inspectedProcessFlowExec: ProcessFlowExecModelInspect | null,
    onDiscard: Function,
    onRequeue: Function
}

export const ProcessFlowExecViewModal = (props: ProcessFlowExecEntriesTable) => {
    useEffect(()=> {
    }, [props])

    return (
        <>
            <input type="checkbox" id="process-flow-exec-modal" className="modal-toggle" />

            <div className="modal">
                <div className="modal-box max-w-8xl">

                    <span className="w-full flex flex-col lg:flex-row justify-between items-center gap-2">
                        <div className="flex items-center">
                            <h3 className="font-bold text-base mr-2 w-28">Process</h3>
                            <span className="rounded-box text-primary bg-base-200 text-md py-2 px-4 font-bold">
                                {
                                    props.inspectedProcessFlowExec?.procName
                                }
                            </span>
                        </div>

                        <div className="flex items-center">
                            <h3 className="font-bold text-base mr-2 w-28">Description</h3>
                            <span className="rounded-box text-primary bg-base-200 text-md py-2 px-4 font-bold">
                                {
                                    props.inspectedProcessFlowExec?.procDescr
                                }
                            </span>
                        </div>
                    </span>

                    <div className='divider my-1' />
                    <span className="w-full flex flex-col lg:flex-row justify-between items-center gap-2">
                        <div className="">
                            <div className="flex items-center mb-2">
                                <h3 className="font-bold mr-2 w-28">Id</h3>
                                <span className="rounded-box bg-base-300 text-sm py-2 px-4 monospace">
                                        {
                                            props.inspectedProcessFlowExec?.execId
                                        }
                                </span>
                            </div>

                            <div className="flex items-center">
                                <h3 className="font-bold mr-2 w-28">Flow Owner</h3>
                                <span className="rounded-box bg-base-300 text-sm py-2 px-4">
                                    {
                                        props.inspectedProcessFlowExec?.flowOwner
                                    }
                                </span>
                            </div>
                        </div>

                        <div className="">
                            <div className="flex items-center mb-2">
                                <h3 className="font-bold mr-2 w-28">Created</h3>
                                {
                                    props.inspectedProcessFlowExec?.dateCreated &&
                                    <AuditedInformation by={null} date={props.inspectedProcessFlowExec?.dateCreated} type={AUDITED_INFORMATION_TYPES.CREATED} />
                                }
                            </div>
                            <div className="flex items-center">
                                <h3 className="font-bold mr-2 w-28">Last Queued</h3>
                                {
                                    props.inspectedProcessFlowExec?.dateLastQueued &&
                                    <AuditedInformation by={null} date={props.inspectedProcessFlowExec?.dateLastQueued} type={AUDITED_INFORMATION_TYPES.LAST_QUEUED} />
                                }
                            </div>
                        </div>
                    </span>

                    <div className='divider my-1' />

                    <h3 className="font-bold mr-2 w-28 mb-2">Job Param</h3>
                    {
                        props.inspectedProcessFlowExec &&
                        <MetadataViewHorizontal
                            metadata={JSON.parse(props.inspectedProcessFlowExec?.jobParamJson)}
                            className={null}
                        />
                    }

                    <div className='divider my-1' />
                    <h3 className="font-bold mr-2 w-28 mb-2">Output</h3>
                    <pre>
                        <div className="w-full bg-base-300 rounded-box p-4 text-base-content h-96 text-xs overflow-scroll monospace"
                             dangerouslySetInnerHTML={{__html: props.inspectedProcessFlowExec?.debug ? props.inspectedProcessFlowExec?.debug : ''}}
                        />
                    </pre>

                    <div className="modal-action">
                        <label onClick={()=>props.onDiscard(props.inspectedProcessFlowExec)} htmlFor="process-flow-exec-modal" className="btn border-amber-600 bg-amber-600 hover:bg-amber-700 hover:border-amber-700 text-error-content btn-sm">Discard</label>
                        {/* TODO NOW NOW */}
                        {/*<label*/}
                        {/*    onClick={()=>props.onRequeue(props.inspectedProcessFlowExec)}*/}
                        {/*    htmlFor="process-flow-exec-modal"*/}
                        {/*    className={`btn btn-primary btn-sm normal-case ${props.inspectedProcessFlowExec}`}>*/}
                        {/*        Requeue*/}
                        {/*</label>*/}
                        <label htmlFor="process-flow-exec-modal" className="btn btn-secondary btn-sm btn-outline">Close</label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProcessFlowExecViewModal;