import { MasterdataService } from '../../services/masterdata.service';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MasterdataMappingRequestsTable from '../../components/masterdata/mapping-requests-table.component';
import { AxiosResponse } from 'axios';
import { ExclamationTriangleIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { FocusablePageProps } from '../../types/util-types';
import { AuthService } from '../../services/auth.service';

const MasterdataMappingRequestsPage = (props: FocusablePageProps) => {

    const navigate = useNavigate();

    const [masterdataTypeCode, setMasterdataTypeCode] = useState<string>('');
    const [mappingKey, setMappingKey] = useState<string>('');

    const [searchText, setSearchText] = useState<string>('');

    const [masterdataTypeCodes, setMasterdataTypeCodes] = useState<string[]>([]);

    useEffect(() => {

        MasterdataService.getMappingRequestTypes(
            props.focusedCustaccount
        ).then((response: AxiosResponse<any>) => {

            let masterdataTypeCodes: string[] = [];

            Object.keys(response.data).map((key) => {
                masterdataTypeCodes.push(key);
            });

            setMasterdataTypeCodes(masterdataTypeCodes);

        });

    }, [props.focusedCustaccount]);

    useEffect(() => {
        document.title = 'Mapping Requests';
    }, []);

    return (
        <div>
            <div className='p-2 flex w-full justify-center'>
                <div className="w-full max-w-8xl bg-base-300 shadow flex flex-col">
                    <div className='flex space-x-3 items-center justify-between w-full p-2'>

                    <div className='text-sm breadcrumbs'>
                        <ul>
                            <li><a onClick={() => navigate('/masterdata')}>Masterdata</a></li>
                            <li>Mapping</li>
                        </ul>
                    </div>

                        {
                            (AuthService.isM2Admin() || AuthService.isM2Support()) &&
                            <div className="flex justify-end items-center gap-2 py-2 px-6 max-w-lg bg-warning text-warning-content rounded-box">
                                <ExclamationTriangleIcon className={"w-5 h-5"}/>
                                <p>
                                    You may only update mapping requests for <span className="font-bold">{AuthService.getUser()?.custaccountName}</span>
                                </p>
                            </div>
                        }

                    <div className='flex'>
                        <div className='form-control'>
                            <div className='input-group'>
                                <select onChange={(e) => setMasterdataTypeCode(e.target.value)} defaultValue=''
                                        className='select select-bordered select-sm'>
                                    <option value='' disabled>Mapping Type</option>
                                    {masterdataTypeCodes.sort().map((masterdataTypeCode) => {
                                        return <option key={masterdataTypeCode}>{masterdataTypeCode}</option>;
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='form-control'>
                            <div className='input-group'>
                                <input onChange={(e) => setSearchText(e.target.value)} type='text'
                                       placeholder='key/reference search…'
                                       className='input input-bordered input-sm' />
                                <button onClick={() => setMappingKey(searchText)} className='btn btn-square btn-sm'>
                                    <MagnifyingGlassIcon className="h-4 w-4"/>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

                <div>

                    <MasterdataMappingRequestsTable
                        masterdataTypeCode={masterdataTypeCode}
                        mappingKey={mappingKey}
                        focusedCustaccount={props.focusedCustaccount}
                    />

                </div>

            </div>
            </div>

        </div>
    );
};

export default MasterdataMappingRequestsPage;