import React, { useEffect, useState } from 'react';
import UserEntriesTable from '../../components/user/entries-table.component';
import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import { UserModel } from '../../types/user';
import { UserService } from '../../services/user.service';
import toast from 'react-hot-toast';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid';
import { FocusablePageProps } from '../../types/util-types';
import UserCreateUpdateModal from '../../components/user/create-update-modal.component';

const UserPage = (props: FocusablePageProps) => {

    const [triggerReload, setTriggerReload] = useState<number>(0);

    const [userAdd, setUserAdd] = useState<UserModel | null>(null);

    const [searchText, setSearchText] = useState<string>('');

    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const [custaccountModels, setCustaccountModels] = useState<CustaccountModel[]>([]);

    useEffect(() => {

        CustaccountService.getCustaccounts().then(
            (response) => {
                setCustaccountModels(response.data.content);
            }
        );

    }, []);

    useEffect(() => {
        document.title = 'Users';
    }, []);

    return (
        <div>
            <UserCreateUpdateModal
                onFinish={()=>{
                    if (searchText.length > 2) setSearchText('');
                    else setSearchText(searchText+'#');
                }}
                type={"create"}
                userEdit={null}
                focusedCustaccount={props.focusedCustaccount}
            />

            <div className='p-2 flex w-full justify-center'>
                <div className="w-full max-w-8xl bg-base-300 shadow flex flex-col">
                    <div className='flex space-x-3 items-center justify-between w-full p-2'>

                    <div className='text-sm breadcrumbs'>
                        <ul>
                            <li>
                                <span className="badge badge-lg badge-primary">
                                    Users
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className='flex items-center gap-2'>
                        <label htmlFor={`user-create-modal`} className='btn btn-sm btn-primary'>
                            <PlusIcon className="w-4 h-4 mr-1" />
                            Add User
                        </label>

                        <div className='form-control'>
                            <div className='input-group'>
                                <input onChange={(e) => setSearchInputValue(e.target.value)}
                                       type='search'
                                       placeholder='search…'
                                       className='input input-bordered input-sm w-60' />
                                <button onClick={() => setSearchText(searchInputValue)}
                                        className='btn btn-sm'>
                                    <MagnifyingGlassIcon className="h-4 w-4"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <UserEntriesTable
                    custaccountModels={custaccountModels} key={triggerReload}
                    focusedCustaccount={props.focusedCustaccount}
                    searchText={searchText}
                />
                </div>
            </div>

        </div>
    );
};

export default UserPage;