import { ProcessFlowActiveModel } from './processflow';

export const TOPICS = {
    ENGINE_INIT: 'engineInit',

    ENGINE_STATUS: 'engineStatus',
    ACTIVE_JOBS: 'activeJobs',
    QUEUES: 'queues',
    REPO_STATUS: 'repoStatus',
    LOAD_SUMMARY: 'loadSummary',
    STORAGE_VOLUMES: 'storageVolumes',
    SETTINGS: 'settings',

    MSG_SUCCESS: 'msg-success',
    MSG_ERROR: 'msg-error',
    BACKLOG_LIST: 'backlog-list',

    FAILED_JOBS: 'failedJobs',
    TAIL: 'tail',
}

export interface EngineSocketResponse {
    engineStatus: EngineStatusResponse,
    activeJobs: ActiveJobsResponse,
    loadSummary: LoadSummaryResponse,
    queues: QueuesResponse,
    repoStatus: RepoStatusResponse,
    settings: SettingsResponse,
    storageVolumes: StorageVolumeResponse
}

export interface EngineStatusResponse {
    hostName: string,
    payLoad: string
}

export interface ActiveJobsResponse {
    hostName: string,
    payLoad: Array<ProcessFlowActiveModel>
}

export interface LoadSummaryResponse {
    hostName: string,
    payLoad: {
        availableProcessors: number,
        cpuLoad: number,
        totalMemory: number,
        availableMemory: number
    } | null
}

interface Queue {
    name: string,
    size: number
}

export interface QueuesResponse {
    hostName: string,
    payLoad: Array<Queue>
}

export interface RepoStatusResponse {
    hostName: string,
    payLoad: {
        branchName: string,
        jobCount: number,
        statusMessage: number
    } | null
}


interface Setting {
    name: string,
    value: number
}

export interface SettingsResponse {
    hostName: string,
    payLoad: Array<Setting>
}

interface Storage {
    root: string,
    totalSpace: string,
    freeSpace: string
}

export interface StorageVolumeResponse {
    hostName: string,
    payLoad: Array<Storage>
}