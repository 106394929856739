import { XdocService } from '../../services/xdoc.service';
import React, { useEffect, useState } from 'react';
import PaginationButtonGroup from '.././pagination-button-group.component';
import { PaginatedResponse } from '../../types/paginated-response';
import { AxiosResponse } from 'axios';

import { Xdoc } from '../../types/xdoc';
import XdocViewModal from './view-modal.component';
import { codeToDescr } from '../../utils/xdoc';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { copyToClipboard } from '../../utils/interaction';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { MetadataItem, SortableTableProps } from '../../types/util-types';
import { AuthService } from '../../services/auth.service';
import toast from 'react-hot-toast';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

interface XdocEntriesProps extends SortableTableProps {
    xdocTypeCode: string,
    xdocStatusCode: string,
    partnerCode: string
}

export const XdocEntriesTable = (props: XdocEntriesProps) => {
    const resultsPerPage = 10;

    const [entries, setEntries] = useState<Xdoc[]>([]);

    const [xdocView, setXdocView] = useState<Xdoc | null>(null);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();

    useEffect(() => {
        setCurrentPage(1);
    }, [props.xdocTypeCode, props.xdocStatusCode, props.searchText]);

    useEffect(() => {
        XdocService.searchXdocs(
            props.searchText,
            props.partnerCode,
            props.xdocTypeCode,
            props.xdocStatusCode,
            currentPage,
            resultsPerPage,
            props.sortField,
            props.sortDirection,
            props.focusedCustaccount
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            setEntries(response.data.content);
            setPaginationResponse(response.data);
        });
    }, [currentPage, props.xdocTypeCode, props.xdocStatusCode, props.searchText, props.partnerCode, props.focusedCustaccount]);

    return (
        <div className="max-w-8xl w-full">

            {xdocView &&
                <>
                    <input type='checkbox' checked onChange={() => {
                    }} className='modal-toggle' />
                    <div className='modal'>
                        <div className='modal-box w-11/12 max-w-full'>
                                <span onClick={() => setXdocView(null)}
                                      className='btn btn-sm btn-ghost btn-circle absolute top-4 right-4'>
                                        <XMarkIcon className={"w-4 h-4"}/>
                                </span>

                            <XdocViewModal
                                xdoc={xdocView}
                                focusedCustaccount={props.focusedCustaccount}
                            />

                            <div className='modal-action'>
                                <span onClick={() => setXdocView(null)}
                                      className='btn btn-sm btn-primary btn-outline'>Close</span>
                            </div>
                        </div>
                    </div>
                </>
            }

            <div className=''>
                <table className='table w-full table-compact table-zebra max-w-full overflow-x-auto'>
                    <thead>
                    <tr>
                        <th>Date Created / Updated</th>
                        <th>Partner</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Docno</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        entries && entries.map(xdocEntry => (
                            <tr key={xdocEntry.control} className="hover:drop-shadow">
                                <td>
                                    <div className="flex flex-col gap-1 overflow-x-auto">
                                        <AuditedInformation
                                            date={xdocEntry.dateCreated}
                                            by={xdocEntry.createdBy}
                                            type={AUDITED_INFORMATION_TYPES.CREATED}
                                        />
                                        <AuditedInformation
                                            date={xdocEntry.dateUpdated}
                                            by={xdocEntry.updatedBy}
                                            type={AUDITED_INFORMATION_TYPES.UPDATED}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="monospace w-full">{xdocEntry.partnerCode}</div>
                                </td>
                                <td>{codeToDescr(xdocEntry.typeCode)}</td>
                                <td>
                                    <div>{codeToDescr(xdocEntry.statusCode)}</div>
                                </td>
                                <td>
                                    <div className="flex items-center">
                                        <span className="btn btn-sm btn-ghost btn-circle tooltip flex items-center justify-center mr-2 font-normal" data-tip="Copy Filename" onClick={()=>{copyToClipboard(xdocEntry.docNo)}}>
                                            <ClipboardDocumentIcon className="h-4 w-4"/>
                                        </span>
                                        <span className="monospace font-bold">
                                            {xdocEntry.docNo}
                                        </span>
                                    </div>
                                </td>
                                <td align='center'>
                                    <div className='flex items-center justify-between'>
                                        <div className="flex items-center gap-2">
                                            <div onClick={() => setXdocView(xdocEntry)}
                                                 className='btn btn-sm btn-primary btn-outline'>
                                                View
                                            </div>

                                            {/*{*/}
                                            {/*    xdocEntry.statusCode === "integration_failed" && AuthService.isM2Admin() &&*/}
                                            {/*    <div onClick={() => {*/}
                                            {/*        XdocService.resetXdocStatus(xdocEntry?.uuid, props.focusedCustaccount).then(*/}
                                            {/*            (response) => {*/}
                                            {/*                if (response.status >= 200 && response.status < 300) {*/}
                                            {/*                    toast.success("Reset xdoc status to Integration Pending");*/}
                                            {/*                    window.location.reload();*/}
                                            {/*                }*/}
                                            {/*            }*/}
                                            {/*        );*/}
                                            {/*    }}*/}
                                            {/*         className='btn btn-sm btn-success'*/}
                                            {/*    >*/}
                                            {/*        Set to Pending*/}
                                            {/*    </div>*/}
                                            {/*}*/}

                                            {
                                                AuthService.getUser()?.name.includes("Liam") && <span onClick={() => {
                                                if (!xdocEntry?.uuid) {
                                                    toast.error("Can not delete xDoc at this time. Try refreshing page.")
                                                } else {
                                                    XdocService.deleteXdoc(xdocEntry?.uuid, props.focusedCustaccount).then(
                                                        (response) => {
                                                            if (response.status >= 200 && response.status < 300) {
                                                                toast.success("Delete successful");
                                                                // window.location.reload();
                                                            }
                                                        }
                                                    );
                                                }
                                            }} className='btn btn-sm btn-error btn-outline hover:bg-opacity-80'
                                            >
                                                Delete
                                            </span>
                                            }
                                        </div>
                                    </div>
                                </td>
                            </tr>))
                    }
                    </tbody>
                </table>

                {entries && entries.length == 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>

            <PaginationButtonGroup onPageClick={setCurrentPage} page={currentPage}
                                   resultsPerPage={resultsPerPage}
                                   numberOfResults={paginationResponse?.numberOfElements}
                                   totalResults={paginationResponse?.totalElements}
                                   numberOfPages={paginationResponse?.totalPages}></PaginationButtonGroup>

        </div>
    );
};

export default XdocEntriesTable;