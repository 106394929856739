import React, { useEffect, useState } from 'react';
import { PlusCircleIcon, PlusIcon } from '@heroicons/react/20/solid';
import MsginMappingEntriesTable from '../../components/msginmapping/entries-table.component';

import MsginMappingAddModal from '../../components/msginmapping/add-modal.component';
import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import { AuthService } from '../../services/auth.service';
import CustaccountSelectorComponent from '../../components/custaccount/selector.component';
import { FocusablePageProps } from '../../types/util-types';

const MsginMappingPage = (props: FocusablePageProps) => {
    const [refresher, setRefresher] = useState<number>(0);
    const [custaccountModels, setCustaccountModels] = useState<CustaccountModel[]>([]);

    useEffect(() => {
        CustaccountService.getCustaccounts().then(
            (response) => {
                setCustaccountModels(response.data.content);
            }
        );
    }, []);

    useEffect(()=>{
    }, [refresher])

    useEffect(() => {
        document.title = 'Msgin Mappings';
    }, []);

    return (
        <>
            <div className='p-2 flex w-full justify-center z-0'>
                <div className="w-full max-w-8xl bg-base-300 shadow flex flex-col">
                    <div className='flex space-x-3 items-center justify-between w-full p-2'>

                        <div className='text-sm breadcrumbs'>
                            <ul>
                                <li>
                                <span className="badge badge-lg badge-primary">
                                    Msgin Mappings
                                </span>
                                </li>
                            </ul>
                        </div>

                        <div className='flex gap-4 items-center'>
                            <label htmlFor="msginmapping-add-modal" className="btn btn-primary btn-sm">
                                <PlusIcon className="w-5 h-5 mr-1"/>
                                Add Msgin Mapping
                            </label>
                        </div>

                    </div>

                    <MsginMappingEntriesTable
                        refresher={refresher}
                        onRefresh={()=>setRefresher(refresher+1)}
                        focusedCustaccount={props.focusedCustaccount}
                    />
                </div>
            </div>

            <MsginMappingAddModal
                onSuccess={()=>setRefresher(refresher+1)}
                focusedCustaccount={
                    props.focusedCustaccount ?
                        props.focusedCustaccount :
                        (
                            custaccountModels.find((model)=>model.name===AuthService.getUser()?.custaccountName) !== undefined ?
                                custaccountModels.find((model)=>model.name===AuthService.getUser()?.custaccountName)
                                : null
                        )
                }
            />
        </>
    );
};

export default MsginMappingPage;