import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { M2ProtectedRoute, ProtectedRoute } from '../utils/protected-route';

import LoginPage from '../pages/public/login.page';
import EnginePage from '../pages/authenticated/engine.page';
import MasterdataPage from '../pages/authenticated/masterdata.page';
import HomePage from '../pages/authenticated/home.page';
import toast, { Toaster } from 'react-hot-toast';
import MasterdataMappingRequestsPage from '../pages/authenticated/masterdata-mapping-requests.page';
import XdocPage from '../pages/authenticated/xdoc.page';
import { AuthService } from '../services/auth.service';
import UserPage from '../pages/authenticated/user.page';
import SetUserPage from '../pages/public/set-user.page';
import MsginPage from '../pages/authenticated/msgin.page';
import MsgoutPage from '../pages/authenticated/msgout.page';
import ProcessFlowExecPage from '../pages/authenticated/processflowexec.page';

import MsginMappingPage from '../pages/authenticated/msginmapping.page';
import CustaccountPage from '../pages/authenticated/custaccount.page';
import ProcessFlowPage from '../pages/authenticated/processflow.page';
import ProcessPage from '../pages/authenticated/process.page';
import { CustaccountModel } from '../types/custaccount';
import { CustaccountService } from '../services/custaccount.service';
import NavigationBar from '../components/navigation-bar.component';
import { StompSessionProvider } from 'react-stomp-hooks';
import { isDev, isProd, OPS_WEBSOCKET_HOST } from '../server.config';
import { SocketSubscriptionComponent } from '../components/socket-subscription.component';
import { TOPICS } from '../types/socket';

function App() {
    const [focusedCustaccount, setFocusedCustaccount] = useState<CustaccountModel | null>(CustaccountService.getFocusedCustaccount());

    return (
        <div
            data-theme={isProd() ? "corporate" : "dark"}
            className='min-h-screen bg-base-100'
        >
            <Toaster
                position='top-left'
            />
            <NavigationBar
                focusedCustaccount={focusedCustaccount}
                onFocusCustaccountChange={(custaccountModel: CustaccountModel | null)=>{
                    setFocusedCustaccount(custaccountModel);
                    if (custaccountModel) CustaccountService.setFocusedCustaccount(custaccountModel);
                    else CustaccountService.removeFocusedCustaccount();
                }}
            />

            {
                AuthService.checkLoggedIn() && (AuthService.isM2Admin() || AuthService.isM2Support()) &&
                <StompSessionProvider
                    url={OPS_WEBSOCKET_HOST}
                >
                    <SocketSubscriptionComponent path={"topic"} topic={TOPICS.MSG_SUCCESS} callback={(message: string)=>toast.success(message)} />
                    <SocketSubscriptionComponent path={"topic"} topic={TOPICS.MSG_ERROR} callback={(message: string)=>toast.error(message)} />
                </StompSessionProvider>
            }

            <Routes>
                <Route path='login' element={<LoginPage />} />
                <Route path='set-user/:encodedUser' element={<SetUserPage />} />

                <Route index={true} path='/' element={
                    <ProtectedRoute>
                        <HomePage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </ProtectedRoute>} />

                <Route path='engine' element={
                    <M2ProtectedRoute>
                        <EnginePage />
                    </M2ProtectedRoute>} />

                <Route path='masterdata' element={
                    <ProtectedRoute>
                        <MasterdataPage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </ProtectedRoute>} />

                <Route path='masterdata/mapping-request' element={
                    <ProtectedRoute>
                        <MasterdataMappingRequestsPage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </ProtectedRoute>} />

                <Route path='xdoc' element={
                    <ProtectedRoute>
                        <XdocPage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </ProtectedRoute>} />

                <Route path='msgin' element={
                    <ProtectedRoute>
                        <MsginPage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </ProtectedRoute>} />

                <Route path='msgout' element={
                    <ProtectedRoute>
                        <MsgoutPage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </ProtectedRoute>} />

                <Route path='process' element={
                    <M2ProtectedRoute>
                        <ProcessPage />
                    </M2ProtectedRoute>} />

                <Route path='processflow' element={
                    <M2ProtectedRoute>
                        <ProcessFlowPage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </M2ProtectedRoute>} />

                <Route path='processflowexec' element={
                    <ProtectedRoute>
                        <ProcessFlowExecPage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </ProtectedRoute>} />

                <Route path='msginmapping' element={
                    <M2ProtectedRoute>
                        <MsginMappingPage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </M2ProtectedRoute>} />

                <Route path='custaccount' element={
                    <M2ProtectedRoute>
                        <CustaccountPage />
                    </M2ProtectedRoute>} />

                <Route path='user' element={
                    <M2ProtectedRoute>
                        <UserPage
                            focusedCustaccount={focusedCustaccount}
                        />
                    </M2ProtectedRoute>} />

            </Routes>
        </div>
    );
}
export default App;