import http from '../http-common';
import {
    ProcessFlowCreate,
    ProcessFlowExecModel,
    ProcessFlowExecModelInspect,
    ProcessFlowExecSearchResponse,
    ProcessFlowModel, ProcessFlowSearchResponse, ProcessFlowUpdate, ProcessFlowView
} from '../types/processflow';
import { Masterdata } from '../types/masterdata';
import { CustaccountModel } from '../types/custaccount';

export const ProcessflowService = {
    getProcessFlows,

    getFailedProcessflowExecCount,
    getFailedProcessFlowExec,
    getOrphanedProcessFlowExec,

    inspectProcessFlowExec,
    requeueProcessFlowExec,
    discardProcessFlowExec,
    killProcessFlowExec,

    createProcessFlow,
    updateProcessFlow,
    deleteProcessFlow,
    runProcessFlow
};

async function getProcessFlows(searchText: string, resultPage: number, resultsPerPage: number, focusedCustaccount: CustaccountModel | null | undefined) {
    return http.get<ProcessFlowSearchResponse>('/app/processflow?resultPage=' + resultPage + '&resultsPerPage=' + resultsPerPage + (searchText ? ('&searchText=' + searchText.replace('[', '%5B')).replace(']', '%5D') : '') + (focusedCustaccount ? ('&focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function createProcessFlow(processFlowCreate: ProcessFlowCreate) {
    return http.post<ProcessFlowCreate>('/app/processflow', processFlowCreate);
}

async function updateProcessFlow(processFlowUpdate: ProcessFlowUpdate) {
    return http.put<ProcessFlowCreate>('/app/processflow/'+processFlowUpdate.control, processFlowUpdate);
}

async function deleteProcessFlow(processFlowView: ProcessFlowView) {
    return http.delete<ProcessFlowCreate>(`/app/processflow/${processFlowView.control}`);
}

async function runProcessFlow(processFlowView: ProcessFlowView) {
    return http.post<ProcessFlowCreate>(`/app/processflow/${processFlowView.control}/run`);
}

async function getFailedProcessflowExecCount(focusedCustaccount: CustaccountModel | null) {
    return http.get<number>('/app/processflow/exec/failed-count' + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function getFailedProcessFlowExec(success: boolean, noOlderThanDays: number, resultPage: number, resultsPerPage: number, focusedCustaccount: CustaccountModel | null, searchText: string = '') {
    if (searchText.slice(-1) === '#') searchText = '';
    return http.get<ProcessFlowExecSearchResponse>(`/app/processflow/exec/?resultPage=${resultPage}&resultsPerPage=${resultsPerPage}&success=${success}&noOlderThanDays=${noOlderThanDays}` + (searchText ? ('&searchText=' + searchText.replace('[', '%5B')).replace(']', '%5D') : '') + (focusedCustaccount ? ('&focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function getOrphanedProcessFlowExec(resultPage: number, resultsPerPage: number, focusedCustaccount: CustaccountModel | null) {
    return http.get<ProcessFlowExecSearchResponse>(`/app/processflow/exec/orphaned/?resultPage=${resultPage}&resultsPerPage=${resultsPerPage}` + (focusedCustaccount ? ('&focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function inspectProcessFlowExec(execId: string) {
    return http.get<ProcessFlowExecModelInspect>(`/app/engine/exec/${execId}/inspect`);
}

async function requeueProcessFlowExec(execId: string) {
    return http.post<ProcessFlowExecSearchResponse>(`/app/engine/exec/${execId}/requeue`);
}

async function discardProcessFlowExec(execId: string) {
    return http.post<ProcessFlowExecSearchResponse>(`/app/engine/exec/${execId}/discard`);
}

async function killProcessFlowExec(execId: string) {
    return http.post<ProcessFlowExecSearchResponse>(`/app/engine/exec/${execId}/kill`);
}