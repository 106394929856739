import React, { useEffect, useState } from 'react';
import { MagnifyingGlassIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import CustaccountEntriesTable from '../../components/custaccount/entries-table.component';

const CustaccountPage = () => {

    const [searchText, setSearchText] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    return (
        <>
            <div className='p-2 flex w-full justify-center z-0'>
                <div className="w-full max-w-8xl bg-base-300 shadow flex flex-col">
                    <div className='flex space-x-3 items-center justify-between w-full p-2'>

                        <div className='text-sm breadcrumbs'>
                            <ul>
                                <li>
                                <span className="badge badge-lg badge-primary">
                                    Custaccounts
                                </span>
                                </li>
                            </ul>
                        </div>

                        <div className='flex gap-4 items-center'>
                            {/*TODO: Add functionality to add customer on backend and link it to here*/}
                            {/*<label htmlFor="msgin-upload-modal" className="btn btn-primary btn-sm">*/}
                            {/*    <PlusCircleIcon className="w-5 h-5 mr-1"/>*/}
                            {/*    Add Customer*/}
                            {/*</label>*/}

                            <div className='form-control'>
                                <div className='input-group'>
                                    <input onChange={(e) => setSearchInputValue(e.target.value)}
                                           type='search'
                                           placeholder='custaccount search…'
                                           className='input input-bordered input-sm w-60' />
                                    <button onClick={() => setSearchText(searchInputValue)}
                                            className='btn btn-sm'>
                                        <MagnifyingGlassIcon className="h-4 w-4"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <CustaccountEntriesTable
                        searchText={searchText}
                        focusedCustaccount={null}
                    />
                </div>
            </div>
        </>
    );
};

export default CustaccountPage;