import React, { useEffect, useState } from 'react';
import PaginationButtonGroup, { ResultsPerPageOptions } from '.././pagination-button-group.component';
import { PaginatedResponse } from '../../types/paginated-response';
import { AxiosError, AxiosResponse } from 'axios';
import { ProcessflowService } from '../../services/processflow.service';
import { ProcessFlowView } from '../../types/processflow';
import MetadataViewHorizontal from '../metadata-view-horizontal.component';
import { PencilIcon, PlayIcon, TrashIcon } from '@heroicons/react/20/solid';
import cronstrue from 'cronstrue';
import { SearchableTableProps } from '../../types/util-types';
import toast from 'react-hot-toast';
import ProcessFlowCreateUpdateModal from './create-update-modal.component';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

export const ProcessFlowEntriesTable = (props: SearchableTableProps) => {
    const [resultsPerPage, setResultsPerPage] = useState<number>(10);
    const [processFlowEdit, setProcessFlowEdit] = useState<ProcessFlowView | null>(null);

    const [entries, setEntries] = useState<ProcessFlowView[]>();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();

    useEffect(() => {
        setCurrentPage(1);
    }, [props.searchText]);

    useEffect(() => {
        ProcessflowService.getProcessFlows(
            props.searchText ? props.searchText : '',
            currentPage,
            resultsPerPage,
            props.focusedCustaccount
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            setEntries(response.data.content);
            setPaginationResponse(response.data);
        });
    }, [props.searchText, currentPage, props.focusedCustaccount]);

    const onDelete = (processFlowView: ProcessFlowView) => {
        if (window.confirm("Confirm delete?")) {
            ProcessflowService.deleteProcessFlow(
                processFlowView
            ).then((response: AxiosResponse) => {
                if (response.status >= 200 && response.status < 400) {
                    toast.success("Process flow deleted.")
                    ProcessflowService.getProcessFlows(
                        props.searchText ? props.searchText : '',
                        currentPage,
                        resultsPerPage,
                        props.focusedCustaccount
                    ).then((response: AxiosResponse<PaginatedResponse>) => {
                        setEntries(response.data.content);
                        setPaginationResponse(response.data);
                    });
                }
            });
        }
    }

    const onRun = (processFlowView: ProcessFlowView) => {
        ProcessflowService.runProcessFlow(
            processFlowView
        ).then((response: AxiosResponse) => {
            if (response.status >= 200 && response.status < 400) {
                toast.success("Running process flow.")
            }
        });
    }

    return (
        <div className='w-full'>
            {
                processFlowEdit &&
                <ProcessFlowCreateUpdateModal
                    onFinish={()=>{
                        ProcessflowService.getProcessFlows(
                            props.searchText ? props.searchText : '',
                            currentPage,
                            resultsPerPage,
                            props.focusedCustaccount
                        ).then((response: AxiosResponse<PaginatedResponse>) => {
                            setEntries(response.data.content);
                            setPaginationResponse(response.data);
                        });
                    }}
                    type={"update"}
                    processFlowEdit={processFlowEdit}
                    focusedCustaccount={props.focusedCustaccount}
                />
            }

            <div className="grid grid-cols-12 gap-2 rounded-box p-4 bg-base-200 w-full text-sm">
                <p className="font-bold">
                    Owner
                </p>
                <p className="font-bold">
                    Date Created
                </p>
                {/*<p className="font-bold">*/}
                {/*    Roles*/}
                {/*</p>*/}
                <p className="font-bold col-span-3">
                    Flow
                </p>
                <p className="font-bold col-span-3">
                    Steps
                </p>

                <p className="font-bold col-span-3">
                    Flow Vars
                </p>

                <p className="font-bold">
                    Schedule/Actions
                </p>

                <div className='divider col-span-12 my-1'/>

                {
                    entries && entries.map(processFlowView => (
                        <React.Fragment key={processFlowView.control}>
                                <div className={"font-bold"}>
                                    {
                                        processFlowView.custaccountName
                                    }
                                </div>

                                <div className="monospace">
                                    {
                                        <AuditedInformation
                                            date={processFlowView.dateCreated}
                                            by={null}
                                            type={AUDITED_INFORMATION_TYPES.CREATED}
                                        />
                                    }
                                </div>

                                {/*<div className='flex flex-col justify-start items-start gap-0.5'>*/}
                                {/*    {*/}
                                {/*        processFlowView.roles.Supplier &&*/}
                                {/*        <>*/}
                                {/*            <p className='text-base-content text-opacity-60'>*/}
                                {/*                Suppliers*/}
                                {/*            </p>*/}
                                {/*            <div className='divider my-0' />*/}
                                
                                {/*            {processFlowView.roles.Supplier.length === 0 ?*/}
                                {/*                'None'*/}
                                {/*                :*/}
                                {/*                processFlowView.roles.Supplier.map((dist, index) => (*/}
                                {/*                    <p key={index} className='font-bold text-primary'>*/}
                                {/*                        {dist}*/}
                                {/*                    </p>*/}
                                {/*                ))}*/}
                                {/*        </>*/}
                                {/*    }*/}
                                
                                {/*    {*/}
                                {/*        processFlowView.roles.Distributor &&*/}
                                {/*        <>*/}
                                {/*            <p className='text-base-content text-opacity-60'>*/}
                                {/*                Distributors*/}
                                {/*            </p>*/}
                                {/*            <div className='divider my-0' />*/}
                                
                                {/*            {processFlowView.roles.Distributor.length === 0 ?*/}
                                {/*                'None'*/}
                                {/*                :*/}
                                {/*                processFlowView.roles.Distributor.map((dist, index) => (*/}
                                {/*                    <p key={index} className='font-bold text-primary'>*/}
                                {/*                        {dist}*/}
                                {/*                    </p>*/}
                                {/*                ))}*/}
                                {/*        </>*/}
                                {/*    }*/}
                                
                                {/*    {*/}
                                {/*        processFlowView.roles.Partner &&*/}
                                {/*        <>*/}
                                {/*            <div className='divider my-0' />*/}
                                {/*            <p className='text-base-content text-opacity-60'>*/}
                                {/*                Partners*/}
                                {/*            </p>*/}
                                {/*            <div className='divider my-0' />*/}
                                
                                {/*            {processFlowView.roles.Partner.length === 0 ?*/}
                                {/*                'None'*/}
                                {/*                :*/}
                                {/*                processFlowView.roles.Partner.map((dist, index) => (*/}
                                {/*                    <p key={index} className='font-bold text-primary'>*/}
                                {/*                        {dist}*/}
                                {/*                    </p>*/}
                                {/*                ))}*/}
                                {/*        </>*/}
                                {/*    }*/}
                                {/*</div>*/}

                                <div className="w-full col-span-3 flex flex-col overflow-x-auto">
                                        <p className=' text-base-content text-opacity-60'>
                                            Description
                                        </p>
                                        <p className='w-full text-start font-bold'>
                                            {processFlowView.descr}
                                        </p>

                                        <div className='my-1' />

                                        <p className='text-base-content text-opacity-60'>
                                            System Code
                                        </p>
                                        <div
                                            className='text-primary font-bold text-start'>
                                            {
                                                processFlowView.systemCode ? processFlowView.systemCode : '-'
                                            }
                                        </div>
                                </div>

                                <div className="col-span-3">
                                    <div className='flex flex-col justify-start items-start gap-1 overflow-x-auto'>
                                        {
                                            processFlowView.consolidatedProcessFlowStepViews.length > 0 && processFlowView.consolidatedProcessFlowStepViews.map((processFlowStepViews, index)=>(
                                                <div key={index}>
                                                    <span className="badge badge-primary badge-sm">
                                                        Step {processFlowStepViews[0].sequence+1}
                                                    </span>
                                                    <br/>
                                                    {
                                                        processFlowStepViews.map((processFlowStepView, index) => (
                                                            <React.Fragment key={index}>
                                                                <div className="badge mb-1">
                                                                    {processFlowStepView.jsonData.className}
                                                                </div>
                                                                {
                                                                    (processFlowStepView.jsonData.flowStepMasterdataTypes !== null) && (processFlowStepView.jsonData.flowStepMasterdataTypes !== undefined) && (Object.keys(processFlowStepView.jsonData.flowStepMasterdataTypes).length !== 0) &&
                                                                    <div className={"my-1"}>
                                                                        <p className={"text-xs text-base-content text-opacity-60"}>
                                                                            Flow Masterdata Types
                                                                        </p>
                                                                        {
                                                                            Object.keys(processFlowStepView.jsonData.flowStepMasterdataTypes).length > 0 &&
                                                                            <MetadataViewHorizontal
                                                                                className={null}
                                                                                metadata={processFlowStepView.jsonData.flowStepMasterdataTypes}
                                                                            />
                                                                        }
                                                                    </div>
                                                                }

                                                            </React.Fragment>
                                                        ))
                                                    }
                                                    <div className='divider my-0' />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                    <div className="col-span-3">
                                        {
                                            Object.keys(processFlowView.flowVars).length > 0 &&
                                            <MetadataViewHorizontal
                                                metadata={processFlowView.flowVars}
                                                className={null}
                                            />
                                        }
                                    </div>

                                <div className="flex flex-col justify-start items-center">
                                    <div className="flex items-center gap-2 justify-center">
                                        <button
                                            className="btn btn-primary btn-circle btn-xs tooltip flex justify-center "
                                            data-tip={"Run"}
                                            onClick={()=>onRun(processFlowView)}
                                        >
                                            <PlayIcon className="w-3 h-3" />
                                        </button>

                                        <label
                                            htmlFor={`process-flow-update-modal`}
                                            className="btn btn-secondary btn-circle btn-xs tooltip flex justify-center"
                                            data-tip={"Edit"}
                                            onClick={()=>setProcessFlowEdit(processFlowView)}
                                        >
                                            <PencilIcon className="w-3 h-3" />
                                        </label>

                                        <button
                                            className="btn btn-error btn-circle btn-xs tooltip flex justify-center "
                                            data-tip={"Delete"}
                                            onClick={()=>onDelete(processFlowView)}
                                        >
                                            <TrashIcon className="w-3 h-3" />
                                        </button>
                                    </div>
                                    <div className='divider my-2' />
                                    {
                                        processFlowView.cronEntries.length > 0 ? processFlowView.cronEntries.map((cronTab, index) => {
                                            if (index===0) {
                                                return (
                                                    <p className="w-full text-sm text-base-content text-opacity-60 px-2 text-center" key={index}>{cronstrue.toString(cronTab)}</p>
                                                )
                                            }
                                            }) :
                                            <p className="w-full text-sm text-base-content text-opacity-40 px-2 text-center">-</p>
                                    }
                                </div>
                            <div className='divider col-span-12 my-2'/>
                        </React.Fragment>
                    ))
                }

            </div>

            {entries && entries.length == 0 &&
                <div className='flex w-full flex-1 justify-center m-5'>
                    <span>No results found.</span>
                </div>}


            <PaginationButtonGroup
                onPageClick={setCurrentPage}
                page={currentPage}
                resultsPerPage={resultsPerPage}
                numberOfResults={paginationResponse?.numberOfElements}
                totalResults={paginationResponse?.totalElements}
                numberOfPages={paginationResponse?.totalPages}
            />

            <div className="w-full flex items-center justify-center gap-2 mb-2 monospace">
                <select
                    onChange={(e) => {
                        setResultsPerPage(Number(e.target.value))
                        setCurrentPage(1);
                    }}
                    value={resultsPerPage}
                    className='select select-bordered select-xs'>
                    {ResultsPerPageOptions.map((results) => {
                        return <option key={results}>{results}</option>;
                    })}
                </select>
            </div>
        </div>
    );
};

export default ProcessFlowEntriesTable;