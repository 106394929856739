import React from 'react';
import { EngineSocketResponse } from '../../types/socket';
import { RUNNING_TYPES } from '../../types/engine';
import ActiveJobEntriesTable from './entries-table.component';

interface EngineInstanceProps {
    engineInstance: EngineSocketResponse | null,
    onTailProcess: Function
}
export const EngineInstanceComponent = (props: EngineInstanceProps) => {
    return (
        <>
            {
                props.engineInstance &&
                <div className="w-full bg-base-200 rounded-box px-4 pt-4 pb-8 shadow-lg">
                    <div className="w-full flex justify-between items-center relative drop-shadow z-30">
                        <span className="flex items-center">
                            <p className="mr-2">
                                IP
                            </p>
                            <span className="badge badge-primary">
                                {
                                    props.engineInstance?.engineStatus.hostName
                                }
                            </span>
                        </span>

                        <span>
                            <ul className="menu menu-horizontal px-1">
                                {
                                    props.engineInstance?.settings.payLoad.length !== 0 &&
                                    <li tabIndex={0}>
                                        <a className="text-sm">
                                            Config
                                        </a>

                                        <ul className="p-4 bg-base-200 gap-2 shadow">
                                            {
                                                props.engineInstance?.settings.payLoad.map((setting, index)=>{
                                                    return (
                                                        <span key={index} className="flex items-center text-xs">
                                                        <p className="mr-2 w-44 font-bold">
                                                            {setting.name}
                                                        </p>
                                                        <span className="badge badge-primary monospace">
                                                            {
                                                                setting.value
                                                            }
                                                        </span>
                                                    </span>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                }

                                {
                                    props.engineInstance?.queues.payLoad.length !== 0 &&
                                    <li tabIndex={0}>
                                        <a className="text-sm">
                                            Queues
                                        </a>

                                        <ul className="p-4 bg-base-200 gap-2 shadow">
                                            {
                                                props.engineInstance?.queues.payLoad.map((queue, index)=>{
                                                    return (
                                                        <span key={index} className="flex items-center text-xs">
                                                        <p className="mr-2 w-32 font-bold">
                                                            {queue.name}
                                                        </p>
                                                        <span className="badge badge-primary monospace">
                                                            {
                                                                queue.size
                                                            }
                                                        </span>
                                                    </span>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                }

                                {
                                    props.engineInstance?.loadSummary.payLoad?.availableProcessors &&
                                    <li tabIndex={0}>
                                        <a className="text-sm">
                                            Performance
                                        </a>

                                        <ul className="p-4 bg-base-200 gap-2 shadow">
                                        <span className="flex items-center text-xs">
                                            <p className="mr-2 w-32 font-bold">
                                                CPU Count
                                            </p>
                                            <span className="badge badge-primary monospace">
                                                {
                                                    props.engineInstance?.loadSummary.payLoad?.availableProcessors
                                                }%
                                            </span>
                                        </span>

                                            <span className="flex items-center text-xs">
                                            <p className="mr-2 w-32 font-bold">
                                                CPU Load
                                            </p>
                                            <span className="badge badge-primary monospace">
                                                {
                                                    props.engineInstance?.loadSummary.payLoad?.cpuLoad
                                                }%
                                            </span>
                                        </span>

                                            <div className='divider my-0' />

                                            <span className="flex items-center text-xs">
                                            <p className="mr-2 w-32 font-bold">
                                                Available Memory
                                            </p>
                                            <span className="badge badge-primary monospace">
                                                {
                                                    props.engineInstance?.loadSummary.payLoad?.availableMemory
                                                }GB
                                            </span>
                                        </span>

                                            <span className="flex items-center text-xs">
                                            <p className="mr-2 w-32 font-bold">
                                                Total Memory
                                            </p>
                                            <span className="badge badge-primary monospace">
                                                {
                                                    props.engineInstance?.loadSummary.payLoad?.totalMemory
                                                }GB
                                            </span>
                                        </span>
                                        </ul>
                                    </li>
                                }

                                {
                                    props.engineInstance?.storageVolumes.payLoad.length !== 0 &&
                                    <li tabIndex={0}>
                                        <a className="text-sm">
                                            Storage
                                        </a>

                                        <ul className="p-4 bg-base-200 gap-2 shadow">
                                            {
                                                props.engineInstance?.storageVolumes.payLoad && props.engineInstance?.storageVolumes.payLoad.map((storage, index)=>{
                                                    return (
                                                        <span key={index} className="flex flex-col justify-start items-start text-xs gap-2">
                                                        <span className="flex items-center">
                                                            <p className="mr-2 w-32 font-bold">
                                                                Mount
                                                            </p>

                                                            <span className="badge badge-primary monospace">
                                                                {storage.root}

                                                            </span>
                                                        </span>

                                                        <span className="flex items-center">
                                                            <p className="mr-2 w-32 font-bold">
                                                                Total Space
                                                            </p>

                                                            <span className="badge badge-primary monospace">
                                                                {storage.totalSpace}

                                                            </span>
                                                        </span>

                                                        <span className="flex items-center">
                                                            <p className="mr-2 w-32 font-bold">
                                                                Free Space
                                                            </p>

                                                            <span className="badge badge-primary monospace">
                                                                {storage.freeSpace}

                                                            </span>
                                                        </span>

                                                        <div className='divider my-0' />
                                                    </span>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                }

                                {
                                    props.engineInstance?.repoStatus.payLoad &&
                                    <li tabIndex={0}>
                                        <a className="text-sm">
                                            Repository
                                        </a>

                                        {
                                            props.engineInstance?.repoStatus.payLoad &&
                                            <ul className="p-4 bg-base-200 gap-2 shadow">
                                            <span className="flex w-full justify-between items-center text-xs">
                                                <p className="mr-2 w-32 font-bold">
                                                    Branch
                                                </p>
                                                <span className="badge badge-primary">
                                                    {
                                                        props.engineInstance?.repoStatus.payLoad?.branchName
                                                    }
                                                </span>
                                            </span>

                                                <span className="flex w-full justify-between items-center text-xs">
                                                <p className="mr-2 w-32 font-bold">
                                                    Status Message
                                                </p>
                                                <span className="rounded-box bg-base-300 p-2 monospace">
                                                    {
                                                        props.engineInstance?.repoStatus.payLoad?.statusMessage
                                                    }
                                                </span>
                                            </span>

                                                {/*TODO: Fix job count on the backend before showing it here*/}
                                                {/*<div className='divider my-0' />*/}

                                                {/*<span className="flex w-full justify-between items-center text-xs">*/}
                                                {/*    <p className="mr-2 w-32 font-bold">*/}
                                                {/*        Job Count*/}
                                                {/*    </p>*/}
                                                {/*    <span className="badge badge-primary monospace">*/}
                                                {/*        {*/}
                                                {/*            props.engineInstance?.repoStatus.payLoad?.jobCount*/}
                                                {/*        }*/}
                                                {/*    </span>*/}
                                                {/*</span>*/}
                                            </ul>
                                        }
                                    </li>
                                }
                            </ul>
                        </span>

                        <span className="flex items-center">
                            <p className="mr-2">
                                Status
                            </p>
                            <span className={`badge ${props.engineInstance?.engineStatus.payLoad.toLowerCase() === RUNNING_TYPES.RUNNING ? 'badge-success' : props.engineInstance?.engineStatus.payLoad.toLowerCase() === RUNNING_TYPES.INITIALISING ? 'badge-warning' : 'badge-error'}`}>
                                {
                                    props.engineInstance?.engineStatus.payLoad
                                }
                            </span>
                        </span>
                    </div>

                    <div className='divider' />

                    <div className="w-full flex justify-start items-center">
                        <ActiveJobEntriesTable
                            activeJobs={props.engineInstance?.activeJobs.payLoad}
                            onTailProcess={props.onTailProcess}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default EngineInstanceComponent;