import http from '../http-common';
import { CreateMasterdataMappingRequest, MasterdataMapping } from '../types/masterdata-mapping';
import { Masterdata, MasterdataResultSet, MasterdataTypeOption } from '../types/masterdata';
import { MasterdataTemplateResponse } from '../types/masterdata-template';
import { CustaccountModel } from '../types/custaccount';

export const MasterdataService = {
    getMasterdataTypes: getMasterdataTypeSummary,
    searchMasterdata,
    getMappingRequestTypes,
    getMappingRequests,
    createMasterdataMapping,
    getMasterdataTemplates,
    getMasterdataTypeOptions,
    createMasterdata,
    updateMasterdata,
    deleteMasterdata,
    uploadMasterdataFile,
    deleteMasterdataMapping,
    deleteMasterdataMappingRequest
};

async function getMasterdataTypeSummary(focusedCustaccount: CustaccountModel | null) {
    return http.get<{ [id: string]: number }>('/app/masterdata/summary' + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function getMasterdataTypeOptions(focusedCustaccount: CustaccountModel | null) {
    return http.get<MasterdataTypeOption[]>('/app/masterdata/type-option' + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function getMasterdataTemplates() {
    return http.get<MasterdataTemplateResponse>('/app/masterdata/template');
}

async function searchMasterdata(typeCode: string, searchText: string, resultPage: number, resultsPerPage: number, focusedCustaccount: CustaccountModel | null) {
    return http.get<MasterdataResultSet>('/app/masterdata?resultPage=' + resultPage + '&resultsPerPage=' + resultsPerPage + (typeCode ? ('&typeCode=' + typeCode) : '') + (searchText ? ('&searchText=' + searchText) : '') + (focusedCustaccount ? ('&focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function getMappingRequestTypes(focusedCustaccount: CustaccountModel | null) {
    return http.get<any>('/app/masterdata/mapping-request/summary' + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function getMappingRequests(masterdataTypeCode: string, mappingKey: string, resultPage: number, resultsPerPage: number, focusedCustaccount: CustaccountModel | null) {
    return http.get<any>('/app/masterdata/mapping-request?resultPage=' + resultPage + '&resultsPerPage=' + resultsPerPage + (masterdataTypeCode ? ('&masterdataTypeCode=' + masterdataTypeCode) : '') + (mappingKey ? ('&mappingKey=' + mappingKey) : '') + (focusedCustaccount ? ('&focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function deleteMasterdataMapping(uuid: string, focusedCustaccount: CustaccountModel | null) {
    return http.delete<number>('/app/masterdata/mapping/' + uuid + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function deleteMasterdataMappingRequest(control: number) {
    return http.delete<number>('/app/masterdata/mapping/request/' + control);
}

async function createMasterdata(masterdataModel: Masterdata, focusedCustaccount: CustaccountModel | null) {
    return http.post<Masterdata>('/app/masterdata' + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''), masterdataModel);
}

async function updateMasterdata(masterdataModel: Masterdata, focusedCustaccount: CustaccountModel | null) {
    return http.put<Masterdata>('/app/masterdata' + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''), masterdataModel);
}

async function deleteMasterdata(uuid: string, focusedCustaccount: CustaccountModel | null) {
    return http.delete<number>('/app/masterdata/' + uuid + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function uploadMasterdataFile(file: File) {

    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    };

    const formData = new FormData();
    formData.append('file', file);

    return http.post('/app/masterdata/upload', formData, config);
}

async function createMasterdataMapping(createMasterdataMappingRequest: CreateMasterdataMappingRequest) {
    return http.post<MasterdataMapping>('/app/masterdata/mapping', createMasterdataMappingRequest);
}