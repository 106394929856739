import React, { useEffect, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import MsgoutEntriesTable from '../../components/msgout/entries-table.component';
import { AuthService } from '../../services/auth.service';
import CustaccountSelectorComponent from '../../components/custaccount/selector.component';
import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import { FocusablePageProps } from '../../types/util-types';

const MsgoutPage = (props: FocusablePageProps) => {

    const [searchText, setSearchText] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    useEffect(() => {
        document.title = 'Msgout';
    }, []);

    return (
        <div>
            <div className='p-2 flex w-full justify-center'>
                <div className="w-full max-w-8xl bg-base-300 shadow flex flex-col">
                    <div className='flex space-x-3 items-center justify-between w-full p-2'>

                        <div className='text-sm breadcrumbs'>
                            <ul>
                                <li>
                                <span className="badge badge-lg badge-primary">
                                    Msgout
                                </span>
                                </li>
                            </ul>
                        </div>

                        <div className='flex'>
                            <div className='form-control'>
                                <div className='input-group'>
                                    <input onChange={(e) => setSearchInputValue(e.target.value)}
                                           type='search'
                                           placeholder='filename search…'
                                           className='input input-bordered input-sm w-60' />
                                    <button onClick={() => setSearchText(searchInputValue)}
                                            className='btn btn-sm'>
                                        <MagnifyingGlassIcon className="h-4 w-4"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <MsgoutEntriesTable
                        sortField='dateUpdated'
                        sortDirection='desc'
                        searchText={searchText}
                        focusedCustaccount={props.focusedCustaccount}
                    />
                </div>
            </div>

        </div>
    );
};

export default MsgoutPage;