import React, { useEffect, useState } from 'react';

import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import { AuthService } from '../../services/auth.service';
import { compareFunction } from '../../utils/interaction';

interface CustaccountSelectorProps {
    focusedCustaccount: CustaccountModel | null,
    onChange: Function,
    canBeAll: Boolean
}

export const CustaccountSelectorComponent = (props: CustaccountSelectorProps) => {
    const [custaccountModels, setCustaccountModels] = useState<CustaccountModel[]>([]);

    useEffect(() => {
        CustaccountService.getCustaccounts().then(
            (response) => {
                setCustaccountModels(response.data.content);
            }
        );
    }, []);

    useEffect(()=>{
    }, [props.focusedCustaccount])

    return (
        <div className='form-control '>
            <div className='input-group flex items-center gap-2 tooltip tooltip-left font-normal'
                 // data-tip={"Filter By Custaccount"}
            >
                <select
                    id={"custaccount-selector"}
                    onChange={(event)=> {
                        const newFocusedCustaccount = custaccountModels.find((custaccountModel)=>custaccountModel.control===Number(event.target.value));
                        if (newFocusedCustaccount) props.onChange(newFocusedCustaccount);
                        else props.onChange(null);
                    }}
                    value={
                        props.focusedCustaccount ?
                        props.focusedCustaccount.control :
                        props.canBeAll ?
                            '0' :
                            custaccountModels.find(model => model.name===AuthService.getUser()?.custaccountName)?.control
                    }
                    className='select select-bordered select-sm font-bold'
                >
                    {
                        AuthService.isM2Admin() && props.canBeAll &&
                        <option value='0'>
                            All Custaccounts
                        </option>
                    }
                    {custaccountModels
                        .sort((a, b) => compareFunction(a, b, "name", false))
                        .map((custaccountModel) => {
                        return <option
                            key={custaccountModel.control}
                            value={custaccountModel.control}
                        >
                            {custaccountModel.name}
                        </option>;
                    })}
                </select>
            </div>
        </div>
    );
};

export default CustaccountSelectorComponent;