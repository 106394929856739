import React, { useEffect, useState } from 'react';
import { isValidCron } from 'cron-validator';
import cronstrue from 'cronstrue';
import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import { AuthService } from '../../services/auth.service';
import { ProcessflowService } from '../../services/processflow.service';
import {
    ProcessFlowCreate,
    ProcessFlowCreateEntry,
    ProcessFlowRoles,
    ProcessFlowUpdate,
    ProcessFlowView
} from '../../types/processflow';
import Select from 'react-select';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { generateSelectStylesDaisy } from '../../utils/styles';
import { ProcessView } from '../../types/process';
import { ProcessService } from '../../services/process.service';
import MetadataViewHorizontal from '../metadata-view-horizontal.component';
import Flowvars, { FlowvarsCreate } from '../flowvars.component';
import { LooseObject } from '../../types/util-types';
import toast from 'react-hot-toast';
import { isDev, isProd } from '../../server.config';

export const selectStyles = generateSelectStylesDaisy(isProd() ? "corporate" : "dark");

interface ProcessFlowCreateUpdateModalProps {
    onFinish: Function,
    type: string,
    processFlowEdit: ProcessFlowView | null,
    focusedCustaccount: CustaccountModel | null
}
export const ProcessFlowCreateUpdateModal = (props: ProcessFlowCreateUpdateModalProps) => {
    const [description, setDescription] = useState<string>('');
    const [systemCode, setSystemCode] = useState<string>('');
    const [cronTab, setCronTab] = useState<string>('');

    const [custaccountModels, setCustaccountModels] = useState<CustaccountModel[]>([]);
    const [processViews, setProcessViews] = useState<ProcessView []>([]);

    const [processFlowSteps, setProcessFlowSteps] = useState<Array<ProcessView []>>([[]]);

    const [flowvarRules, setFlowvarRules] = useState<LooseObject>({});
    const [flowvars, setFlowvars] = useState<LooseObject>({});

    // MIGHT BE REMOVING ROLES ALTOGETHER. FOR NOW, I'LL LEAVE THE IMPLEMENTATION COMMENTED OUT
    // const [roles, setRoles] = useState<ProcessFlowRoles>({
    //     Distributor: [],
    //     Supplier: [],
    //     Partner: []
    // });

    const [owner, setOwner] = useState<CustaccountModel | undefined>(undefined);

    const handleSubmit = () => {
        let valid = true;
        if (description === '') {
            toast.error('Description is required.');
            valid = false;
        }

        if (processFlowSteps.length === 0) {
            toast.error('No process flow steps have been added.');
            valid = false;
        } else {
            processFlowSteps.forEach((processFlowStep, index) => {
                if (processFlowStep.length === 0) {
                    toast.error(`No processes have been added to step [${index + 1}]`);
                    valid = false;
                }
            });
        }

        Object.keys(flowvars).forEach((className: string) => {
            Object.keys(flowvars[className]).forEach((key) => {
                if (!flowvarRules[className][key]['optional']) {
                    flowvars[className][key].forEach((attribute: LooseObject, index: number) => {
                        Object.keys(attribute).forEach((attributeStr) => {
                            if (flowvars[className][key][index][attributeStr] === '') {
                                toast.error(`Missing value for required flowvar [${className}.${key}.${attributeStr}-${index}]`);
                                valid = false;
                            }
                        });
                    });
                }
            });
        });

        if (valid) {
            let processFlowsFlattened: ProcessFlowCreateEntry[] = [];
            processFlowSteps.forEach((processFlowStep, sequence)=>{
                processFlowStep.forEach(processView => {
                    processFlowsFlattened.push({
                        sequence: sequence,
                        className: processView.className,
                        type: "process"
                    })
                })
            });

            let flowvarsFlattened: LooseObject = {};
            Object.keys(flowvars).forEach((key: string) => {
                Object.keys(flowvars[key]).forEach((attribute)=>{
                    flowvars[key][attribute].forEach((attributeObject: LooseObject, index: number) => {
                        let validValue = true;
                        Object.keys(attributeObject).forEach((item)=>{
                            if (attributeObject[item] === "") validValue = false;
                        })
                        if (validValue && !((`${key}.${attribute}`) in flowvarsFlattened)) flowvarsFlattened[`${key}.${attribute}`] = [];
                        if (validValue) flowvarsFlattened[`${key}.${attribute}`].push(attributeObject);
                    })
                })
            })

            if (props.type === "create") {
                let createdProcessFlow: ProcessFlowCreate = {
                    ownerCustaccountControl: owner?.control,

                    descr: description,

                    systemCode: systemCode,
                    roles: { Distributor: [], Partner: [], Supplier: [] },
                    scheduleCron: cronTab,

                    processSteps: processFlowsFlattened,

                    flowVariableMap: flowvarsFlattened
                };

                console.log(createdProcessFlow);
                ProcessflowService.createProcessFlow(createdProcessFlow).then(
                    (response) => {
                        if (response.status >= 200 && response.status < 400) {
                            toast.success("Process flow created.")
                            props.onFinish();
                        }
                    }
                );
            } else {
                let updatedProcessFlow: ProcessFlowUpdate = {
                    control: props.processFlowEdit?.control,

                    ownerCustaccountControl: owner?.control,

                    descr: description,

                    systemCode: systemCode,
                    roles: { Distributor: [], Partner: [], Supplier: [] },
                    scheduleCron: cronTab,

                    processSteps: processFlowsFlattened,

                    flowVariableMap: flowvarsFlattened
                };

                ProcessflowService.updateProcessFlow(updatedProcessFlow).then(
                    (response) => {
                        if (response.status >= 200 && response.status < 400) {
                            toast.success("Process flow updated.")
                            props.onFinish();
                        }
                    }
                );
            }
        }

    };

    useEffect(() => {
        CustaccountService.getCustaccounts().then(
            (response) => {
                setCustaccountModels(response.data.content);
                setOwner(
                    response.data.content.find(
                        (custaccount) => (custaccount.name === AuthService.getUser()?.custaccountName)
                    ));
            }
        );

        ProcessService.getProcesses().then((response) => {
                setProcessViews(response.data);
            }
        );
    }, []);

    useEffect(()=>{
        if (processViews.length !== 0) {
            setDescription(props.processFlowEdit?.descr ? props.processFlowEdit?.descr : '');
            setSystemCode(props.processFlowEdit?.systemCode ? props.processFlowEdit?.systemCode : '')
            setCronTab(props.processFlowEdit?.cronEntries[0] ? props.processFlowEdit?.cronEntries[0] : '');

            setOwner(custaccountModels.find(custaccount => (custaccount.name === props.processFlowEdit?.custaccountName)));

            let newProcessFlowSteps: Array<ProcessView []> = [];

            let newFlowvars: LooseObject = {};
            if (props.processFlowEdit?.flowVars) {
                Object.keys(props.processFlowEdit?.flowVars).forEach((flowvarkey: string)=>{
                    let splitKey = flowvarkey.split('.');
                    if (splitKey.length !== 2) {
                        toast.error("Something unhappy decoding flowvariables for " + props.processFlowEdit?.descr);
                    } else {
                        if (!(splitKey[0] in newFlowvars)) newFlowvars[splitKey[0]] = {};
                        if (!(splitKey[1] in newFlowvars[splitKey[0]])) newFlowvars[splitKey[0]][splitKey[1]] = [];

                        newFlowvars[splitKey[0]][splitKey[1]] = props.processFlowEdit?.flowVars[flowvarkey];
                    }
                })
                setFlowvars({...newFlowvars});
            }

            props.processFlowEdit?.consolidatedProcessFlowStepViews.forEach((processFlowStep, step) => {
                if (step > (newProcessFlowSteps.length - 1)) {
                    newProcessFlowSteps.push([]);
                }

                processFlowStep.forEach((processData, index) => {
                    newProcessFlowSteps[step].push(processViews.find((processView) => (processView.className === processData.jsonData.className)) as ProcessView);
                })

                // Need to update the flowvar rules
                let newFlowvarRules: LooseObject = {};

                newProcessFlowSteps.forEach((processViews) => {
                    processViews.forEach((processView) => {
                        if (Object.keys(processView.flowVars).length > 0) {
                            newFlowvarRules[processView.className] = { ...processView.flowVars };
                        }
                    });
                });
                setFlowvarRules(newFlowvarRules);

                let additionalFlowvars = { ...newFlowvars };

                Object.keys(newFlowvarRules).forEach(className => {
                    Object.keys(newFlowvarRules[className]).forEach((key) => {
                        let attributeObject: LooseObject = {};
                        newFlowvarRules[className][key].attributes.forEach((attribute: string) => {
                            attributeObject[attribute] = '';
                        });

                        if (!(className in additionalFlowvars) || !(key in additionalFlowvars[className])) {
                            if (!(className in additionalFlowvars)) additionalFlowvars[className] = {};
                            if (!(key in additionalFlowvars[className])) additionalFlowvars[className][key] = [];

                            additionalFlowvars[className][key] = [
                                attributeObject
                            ];
                        }
                    });
                });

                Object.keys(additionalFlowvars).forEach((className) => {
                    if (!(className in newFlowvarRules)) delete additionalFlowvars[className];
                });
                setFlowvars(additionalFlowvars);
            })
            setProcessFlowSteps(newProcessFlowSteps);
        }
    }, [props.processFlowEdit, processViews])

    useEffect(() => {
    }, [owner]);

    return (
        <>
            <input type='checkbox' id={`process-flow-${props.type}-modal`} className='modal-toggle' />

            <div className='modal'>
                <div className='modal-box max-w-8xl'>

                    <span className='w-full flex flex-col lg:flex-row justify-between items-center gap-2'>
                        <h3 className='font-bold text-base mr-2'>
                            <span className="capitalize">{props.type}</span> Process Flow
                        </h3>

                        <label htmlFor={`process-flow-${props.type}-modal`} className='btn btn-sm btn-circle btn-ghost'>
                            <XMarkIcon className='w-5 h-5' />
                        </label>
                    </span>

                    <div className='divider my-2' />

                    <div className='flex flex-col justify-start items-start w-full'>
                        <div className='flex justify-between items-center w-full gap-4'>
                            <div className='w-full'>
                                <label htmlFor='description'
                                       className='text-sm text-base-content text-opacity-60'>
                                    Description
                                    {
                                        description === '' &&
                                        <span
                                            className={`badge badge-sm badge-error ml-2`}>
                                            required
                                        </span>
                                    }
                                </label>
                                <input
                                    id='description'
                                    required={true}
                                    type='text'
                                    placeholder='Description'
                                    className='input input-bordered w-full'
                                    value={description}
                                    onChange={(event) => setDescription(event.currentTarget.value)}
                                />
                            </div>

                            <div className='w-full'>
                                <label htmlFor='systemcode' className='text-sm text-base-content text-opacity-60 mt-4'>
                                    System
                                    Code
                                </label>
                                <input
                                    id='systemcode'
                                    type='text'
                                    placeholder='System Code'
                                    className='input input-bordered w-full'
                                    value={systemCode}
                                    onChange={(event)=>setSystemCode(event.currentTarget.value)}
                                />
                            </div>
                        </div>

                        <div className='divider my-2' />

                        <div className='flex justify-between items-start w-full gap-4'>
                            <div className='w-full'>
                                <label className='text-sm text-base-content text-opacity-60'>
                                    Owner
                                </label>
                                {
                                    !owner &&
                                    <span
                                        className={`badge badge-sm badge-error ml-2`}
                                    >
                                    required
                                </span>
                                }

                                <Select
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setOwner(custaccountModels.find((custaccountModel) => custaccountModel.control === event.value));
                                    }}
                                    styles={selectStyles}
                                    placeholder='Select owner'
                                    value={owner && { label: owner.name, value: owner.control }}
                                    options={custaccountModels.map((custaccountModel) => {
                                        return (
                                            {
                                                label: custaccountModel.name,
                                                value: custaccountModel.control
                                            }
                                        );
                                    })}
                                />
                            </div>

                            <div className='w-full'>
                                <label htmlFor='schedule'
                                       className='text-sm text-base-content text-opacity-60 flex items-center'>
                                    Schedule
                                    <span className='flex items-center mx-2'>
                                        [
                                        <p className='font-bold text-primary tooltip' data-tip='Minute'>
                                            minute
                                        </p>
                                        <p className='font-bold text-secondary mx-2 tooltip' data-tip='Hour'>
                                            hour
                                        </p>
                                        <p className='font-bold text-primary mr-2 tooltip' data-tip='Day of Month'>
                                            day of month
                                        </p>
                                        <p className='font-bold text-secondary mr-2 tooltip' data-tip='Month'>
                                            month
                                        </p>
                                        <p className='font-bold text-primary tooltip' data-tip='Day of Week'>
                                            day of week
                                        </p>
                                        ]
                                    </span>
                                </label>
                                <input
                                    id='schedule'
                                    type='text'
                                    placeholder='Schedule'
                                    className={`input input-bordered w-full ${cronTab!=='' ? (isValidCron(cronTab) ? 'input-success' : 'input-error') : ''}`}
                                    value={cronTab}
                                    onChange={(event) => setCronTab(event.target.value)}
                                />
                                {
                                    cronTab !== '' && isValidCron(cronTab) &&
                                    <p className='text-sm text-base-content text-opacity-60 ml-4 mt-2'>
                                        <span className='font-bold text-success mx-2'>
                                            {
                                                cronstrue.toString(cronTab)
                                            }
                                        </span>
                                    </p>
                                }
                            </div>
                        </div>

                        <div className='divider my-2' />

                        {/*<div className='flex justify-between items-center w-full gap-4'>*/}
                        {/*    <div className='w-full'>*/}
                        {/*        <label className='text-sm text-base-content text-opacity-60 mt-4 mb-2'>Roles</label>*/}

                        {/*        <div className='w-full flex justify-start items-start gap-4'>*/}
                        {/*            {*/}
                        {/*                Object.keys(roles).map((roleString) => {*/}
                        {/*                    return (*/}
                        {/*                        <div key={roleString}*/}
                        {/*                             className='flex flex-col w-full h-full items-center justify-start'>*/}
                        {/*                            <span*/}
                        {/*                                className={`badge ${roleString === 'Distributor' ? 'badge-primary' : roleString === 'Supplier' ? 'badge-accent' : 'badge-secondary'}`}>*/}
                        {/*                                {roleString}s*/}
                        {/*                            </span>*/}
                        {/*                            {*/}
                        {/*                                roles[roleString].length === 0 ?*/}
                        {/*                                    <p className='text-sm text-base-content text-opacity-60 my-2'>*/}
                        {/*                                        None selected.*/}
                        {/*                                    </p>*/}
                        {/*                                    :*/}
                        {/*                                    <>*/}
                        {/*                                        {*/}
                        {/*                                            roles[roleString].map((roleCustaccount, index) => {*/}
                        {/*                                                return (*/}
                        {/*                                                    <div*/}
                        {/*                                                        className="w-full flex items-center my-2"*/}
                        {/*                                                        key={index}*/}
                        {/*                                                    >*/}
                        {/*                                                        <Select*/}
                        {/*                                                            className="w-full"*/}
                        {/*                                                            styles={selectStyles}*/}
                        {/*                                                            onChange={(event) => {*/}
                        {/*                                                                let oldRolesPerKey = [ ...roles[roleString] ];*/}
                        {/*                                                                // @ts-ignore*/}
                        {/*                                                                oldRolesPerKey[index] = event.label;*/}
                        {/*                                                                setRoles({ ...roles, [roleString]: oldRolesPerKey})*/}
                        {/*                                                            }}*/}
                        {/*                                                            value={{*/}
                        {/*                                                                value: custaccountModels.find(custaccountModel=>custaccountModel.name===roleCustaccount)?.control,*/}
                        {/*                                                                label: roleCustaccount*/}
                        {/*                                                            }}*/}
                        {/*                                                            placeholder='Select custaccount'*/}
                        {/*                                                            options={custaccountModels.map((custaccountModel) => {*/}
                        {/*                                                                return (*/}
                        {/*                                                                    {*/}
                        {/*                                                                        label: custaccountModel.name,*/}
                        {/*                                                                        value: custaccountModel.control*/}
                        {/*                                                                    }*/}
                        {/*                                                                );*/}
                        {/*                                                            })}*/}
                        {/*                                                        />*/}

                        {/*                                                        <button className="btn btn-xs btn-circle btn-error tooltip tooltip-bottom flex items-center justify-center normal-case font-normal mx-2"*/}
                        {/*                                                                data-tip={"Remove"}*/}
                        {/*                                                                onClick={()=>{*/}
                        {/*                                                                    const oldRolesPerKey = [ ...roles[roleString] ].filter(custaccount => custaccount !== roleCustaccount);*/}
                        {/*                                                                    setRoles({ ...roles, [roleString]: oldRolesPerKey})}}*/}
                        {/*                                                        >*/}
                        {/*                                                            <TrashIcon className="w-4 h-4" />*/}
                        {/*                                                        </button>*/}
                        {/*                                                    </div>*/}
                        {/*                                                );*/}
                        {/*                                            })*/}
                        {/*                                        }*/}
                        {/*                                    </>*/}
                        {/*                            }*/}

                        {/*<span*/}
                        {/*    className={`btn btn-circle btn-sm font-normal normal-case flex justify-center items-center tooltip tooltip-bottom ${roleString === 'Distributor' ? 'btn-primary' : roleString === 'Supplier' ? 'btn-accent' : 'btn-secondary'}`}*/}
                        {/*    data-tip={`Add ${roleString}`}>*/}
                        {/*    <PlusIcon className={`w-5 h-5`}*/}
                        {/*              onClick={() => {*/}
                        {/*                  let newRoles = { ...roles };*/}
                        {/*                  newRoles[roleString].push('');*/}
                        {/*                  setRoles(newRoles);*/}
                        {/*              }}*/}
                        {/*    />*/}
                        {/*</span>*/}
                        {/*                        </div>*/}
                        {/*                    );*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className='divider' />*/}

                        <div className='flex justify-between items-center w-full gap-4'>
                            <div className='w-full'>
                                <label className='text-sm text-base-content text-opacity-60'>
                                    Process Flow
                                </label>
                                <div className='divider my-2 max-w-xs' />
                                <div className='w-full flex flex-col justify-center items-start gap-4'>
                                    {
                                        processFlowSteps.map((processFlowStep, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <label
                                                        className='badge badge-primary badge-lg w-28'>
                                                        Step {index + 1}
                                                    </label>
                                                    <Select
                                                        className='w-full'
                                                        onChange={(event) => {
                                                            let selectedValues = event.map((selectedValue) => selectedValue.value);
                                                            let selectedProcessFlowViews = processViews.filter((processView) => selectedValues.includes(processView.className));
                                                            let newProcessFlowSteps = [...processFlowSteps];
                                                            newProcessFlowSteps[index] = selectedProcessFlowViews;
                                                            setProcessFlowSteps(newProcessFlowSteps);

                                                            let newFlowvarRules: LooseObject = {};

                                                            newProcessFlowSteps.forEach((processViews) => {
                                                                processViews.forEach((processView) => {
                                                                    if (Object.keys(processView.flowVars).length > 0) {
                                                                        newFlowvarRules[processView.className] = { ...processView.flowVars };
                                                                    }
                                                                });
                                                            });

                                                            setFlowvarRules(newFlowvarRules);

                                                            let newFlowvars = { ...flowvars };
                                                            Object.keys(newFlowvarRules).forEach(className => {
                                                                Object.keys(newFlowvarRules[className]).forEach((key) => {
                                                                    let attributeObject: LooseObject = {};
                                                                    newFlowvarRules[className][key].attributes.forEach((attribute: string) => {
                                                                        attributeObject[attribute] = '';
                                                                    });

                                                                    if (!(className in newFlowvars)) newFlowvars[className] = {};
                                                                    if (!(key in newFlowvars[className])) newFlowvars[className][key] = [];
                                                                    newFlowvars[className][key] = [
                                                                        attributeObject
                                                                    ];
                                                                });
                                                            });

                                                            Object.keys(newFlowvars).forEach((className) => {
                                                                if (!(className in newFlowvarRules)) delete newFlowvars[className];
                                                            });
                                                            setFlowvars(newFlowvars);
                                                        }}
                                                        value={processFlowSteps[index].map((processView)=>{return {label: processView.className, value: processView.className }})}
                                                        styles={selectStyles}
                                                        isMulti={true}
                                                        closeMenuOnSelect={true}
                                                        placeholder='Select process flows'
                                                        options={processViews.map((processView) => {
                                                            return (
                                                                {
                                                                    label: processView.className,
                                                                    value: processView.className
                                                                }
                                                            );
                                                        })}
                                                    />
                                                </React.Fragment>
                                            );
                                        })
                                    }
                                </div>


                                <span className='w-full flex justify-center items-center gap-2'>
                                    <span
                                        className={`btn btn-sm btn-link font-normal flex justify-center items-center tooltip tooltip-bottom btn-primary mt-4`}
                                        data-tip={`Add Step`}
                                        onClick={() => {
                                            let newProcessFlowSteps = [...processFlowSteps];
                                            newProcessFlowSteps.push([]);
                                            setProcessFlowSteps(newProcessFlowSteps);
                                        }}
                                    >
                                        <PlusIcon className={`w-5 h-5 mr-2`} />
                                        Add Step
                                </span>
                                <span
                                    className={`btn btn-sm btn-link font-normal flex justify-center items-center tooltip tooltip-bottom btn-error mt-4`}
                                    data-tip={`Remove Last Step`}
                                    onClick={() => {
                                        let newProcessFlowSteps = [...processFlowSteps];
                                        newProcessFlowSteps.pop();
                                        setProcessFlowSteps(newProcessFlowSteps);

                                        let newFlowvarRules: LooseObject = {};
                                        newProcessFlowSteps.forEach((processViews) => {
                                            processViews.forEach((processView) => {
                                                if (Object.keys(processView.flowVars).length > 0) {
                                                    newFlowvarRules[processView.className] = { ...processView.flowVars };
                                                }
                                            });
                                        });
                                        setFlowvarRules(newFlowvarRules);

                                        let newFlowvars = { ...flowvars };
                                        Object.keys(newFlowvars).forEach((className) => {
                                            if (!(className in newFlowvarRules)) delete newFlowvars[className];
                                        });

                                        setFlowvars(newFlowvars);
                                    }}
                                >
                                    <MinusIcon className={`w-5 h-5 mr-2`} />
                                    Remove Last Step
                                </span>
                                </span>

                            </div>
                        </div>

                        <div className='divider my-2' />

                        <div className='flex justify-between items-center w-full gap-4'>
                            <div className='w-full flex flex-col gap-2'>
                                <label htmlFor='flowVars'
                                       className='text-sm text-base-content text-opacity-60'>Flow Vars</label>

                                {
                                    Object.keys(flowvars).map((className) => {
                                        return (
                                            <div key={className}
                                                 className='w-full rounded-box p-4 border border-base-content border-opacity-20 bg-base-200'>
                                                <div className='flex items-center gap-2'>
                                                    <p className='font-bold text-xs text-primary'>
                                                        [{className}]
                                                    </p>
                                                </div>
                                                <div className='divider my-0' />

                                                <FlowvarsCreate
                                                    flowvarRules={flowvarRules[className]}
                                                    flowvars={flowvars[className]}

                                                    onAttributeAdd={(newFlowVars: LooseObject) => {
                                                        setFlowvars({ ...flowvars, [className]: newFlowVars });
                                                    }}
                                                    onAttributeRemove={(newFlowVars: LooseObject) => {
                                                        setFlowvars({ ...flowvars, [className]: newFlowVars });
                                                    }}

                                                    onFlowvarChange={(key: string, attribute: string, index: number, value: string) => {
                                                        // console.log(`Process ${className} changed [${key}]-[${attribute}]-[${index}] to ${value}`);
                                                        let newFlowvars = { ...flowvars };
                                                        newFlowvars[className][key][index][attribute] = value;
                                                        setFlowvars(newFlowvars);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>

                        <div className='divider my-2' />

                        <div className='flex justify-between items-center w-full gap-4'>
                            <div className='w-full flex flex-col gap-2'>
                                <label htmlFor='flowVars'
                                       className='text-sm text-base-content text-opacity-60'>Flow Masterdata
                                    Types</label>

                                {
                                    processFlowSteps.map((processFlowStep) => {
                                        return processFlowStep.map((processFlowView, index) => {
                                            if (processFlowView.flowStepMasterdataTypes.length > 0) {
                                                return (
                                                    <div key={index}
                                                         className='w-full rounded-box p-4 border border-base-content border-opacity-20 bg-base-200'>
                                                        <div className='flex items-center gap-2'>
                                                            <p className='font-bold text-xs text-primary'>
                                                                [{processFlowView.className}]
                                                            </p>
                                                        </div>
                                                        <div className='divider my-0' />
                                                        <MetadataViewHorizontal
                                                            metadata={processFlowView.flowStepMasterdataTypes}
                                                            className={null}
                                                        />
                                                    </div>
                                                );
                                            } else return null;
                                        });
                                    })
                                }
                            </div>
                        </div>

                        <div className='divider my-2' />

                    </div>

                    <div className='modal-action'>
                        <label
                            className='btn btn-primary btn-sm btn-link'
                            onClick={handleSubmit}
                        >
                            <span className="capitalize">{props.type}</span>
                        </label>
                        <label htmlFor={`process-flow-${props.type}-modal`}
                               className='btn btn-secondary btn-sm btn-outline'>Close</label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProcessFlowCreateUpdateModal;