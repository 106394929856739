import {
    ArrowLeftCircleIcon,
    ChevronDoubleLeftIcon, ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from '@heroicons/react/20/solid';
import { AuthService } from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export const ResultsPerPageOptions: Array<number> = [
    5,
    10,
    20,
    50,
    100
];

interface Pagination {
    page: number,
    resultsPerPage: number,
    numberOfPages: number | undefined,
    totalResults: number | undefined,
    numberOfResults: number | undefined,
    onPageClick: (page: number) => void;
}

class PaginationButton {
    text: string;
    page: number;
    active: boolean;

    constructor(page: number, text: string, active: boolean) {
        this.page = page;
        this.text = text;
        this.active = active;
    }
}

export const PaginationButtonGroup = (props: Pagination) => {
    useEffect(()=>{
        props.onPageClick(2);
        setTimeout(()=>{
            props.onPageClick(1);
        }, 5)
    }, [props.resultsPerPage])

    if (!props.numberOfPages) return (<div></div>);

    let buttonGroup = [];

    let pagesAround = 3;

    if ((props.numberOfPages - props.page) < pagesAround) {
        pagesAround += pagesAround - (props.numberOfPages - props.page);
    }

    buttonGroup.push(new PaginationButton(1, '<<', 1 == props.page));
    if (props.page > 1) {
        buttonGroup.push(new PaginationButton(props.page - 1, '<', 1 == props.page));
    } else {
        buttonGroup.push(new PaginationButton(props.page, '<', 1 == props.page));
    }

    for (let page = props.page - pagesAround; page < props.page; page++) {
        if (page < 1) continue;
        buttonGroup.push(new PaginationButton(page, page.toString(), page == props.page));
    }

    if (props.page < pagesAround) {
        pagesAround += pagesAround - props.page + 1;
    }

    for (let page = props.page; page <= props.numberOfPages; page++) {
        if (page > props.page + pagesAround) break;
        buttonGroup.push(new PaginationButton(page, page.toString(), page == props.page));
    }

    if (props.page < props.numberOfPages) {
        buttonGroup.push(new PaginationButton(props.page + 1, '>', props.numberOfPages == props.page));
    } else {
        buttonGroup.push(new PaginationButton(props.numberOfPages, '>', props.numberOfPages == props.page));
    }
    buttonGroup.push(new PaginationButton(props.numberOfPages, '>>', props.numberOfPages == props.page));

    const getRange = () => {
        
        if (!props.numberOfResults) return '0';

        let from = (props.page * props.resultsPerPage) - props.resultsPerPage + 1;
        let to = ((props.page - 1) * props.resultsPerPage) + props.numberOfResults;

        return from + '-' + to;
    };

    return (
        <div className='grid place-items-center m-3'>
            <div className='btn-group my-1'>
                {buttonGroup.map((button: PaginationButton) =>
                    <button onClick={() => props.onPageClick(button.page)} key={button.text}
                            className={`btn btn-xs monospace ${button.active ? 'btn-active' : 'btn-ghost'}`}>
                        {
                            button.text==="<" ?
                                <ChevronLeftIcon className="w-4 h-4"/>
                                :
                                button.text==="<<" ?
                                    <ChevronDoubleLeftIcon className="w-4 h-4"/>
                                    :
                                    button.text === ">" ?
                                        <ChevronRightIcon className="w-4 h-4"/>
                                        :
                                        button.text === ">>" ?
                                            <ChevronDoubleRightIcon className="w-4 h-4"/>
                                            :
                                            button.text
                        }
                    </button>
                )}
            </div>
            {props.numberOfResults && props.totalResults &&
                <div className='text-xs my-1 font-bold monospace'><span className="monospace">{getRange()}</span><span className="mx-2">of</span><span className="monospace">{props.totalResults}</span></div>
            }
        </div>
    );
};

export default PaginationButtonGroup;