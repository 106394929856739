import { MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import {
    AuthService
} from '../../services/auth.service';
import toast from 'react-hot-toast';
import * as EmailValidator from 'email-validator';
import { AuthUser } from '../../types/auth';

const SetUserPage = () => {

    const { encodedUser } = useParams();

    useEffect(() => {
        if (encodedUser != null) {
            localStorage.setItem('user', Base64.decode(encodedUser));
            window.location.href = '/';
        }
    });

    return (

        <div className='w-screen h-screen flex justify-center items-center bg-base-100'>

            loading...

        </div>
    );
};

export default SetUserPage;
