import { MasterdataService } from '../../services/masterdata.service';
import React, { useEffect, useState } from 'react';
import { MasterdataMapping } from '../../types/masterdata-mapping';
import { Masterdata, MasterdataTypeOption } from '../../types/masterdata';
import Select from 'react-select';
import { MasterdataEditField, MasterdataMetadataEditRows } from './metadata-edit-rows.component';
import { CustaccountModel } from '../../types/custaccount';
import { AuthService } from '../../services/auth.service';
import { compareFunction } from '../../utils/interaction';

interface MasterdataEditProps {
    masterdata: Masterdata;
    onChange: ((masterdata: Masterdata) => void);
    focusedCustaccount: CustaccountModel | null;
}

export const MasterdataEditModal = (props: MasterdataEditProps) => {
    const [initialised, setInitialised] = useState<boolean>(false);

    const [masterdataTypeOptions, setMasterdataTypeOptions] = useState<MasterdataTypeOption[]>([]);

    const [selectedMasterdataTypeOption, setSelectedMasterdataTypeOption] = useState<MasterdataTypeOption | null>();

    const [masterdataModel, setMasterdataModel] = useState<Masterdata | null>(null);

    useEffect(() => {
        setMasterdataModel(props.masterdata);
        MasterdataService.getMasterdataTypeOptions(null).then(
            (response) => {
                setMasterdataTypeOptions(response.data);

                response.data.map((masterdataTypeOption) => {
                    if (props.masterdata?.control && masterdataTypeOption.typeCode == props.masterdata?.typeCode) {
                        setSelectedMasterdataTypeOption(masterdataTypeOption);
                    }
                });
            }
        );

    }, []);

    useEffect(() => {
        if (!initialised) {
            setInitialised(true);
            return;
        }

        if (props.onChange) {
            if (masterdataModel) props.onChange(masterdataModel);
        }

    }, [masterdataModel]);

    /* if it's a new record, remove all mappings when the type changes. */
    useEffect(() => {
        if (props.masterdata.control) return;
        if (!masterdataModel) return;
        let copy = { ...masterdataModel };
        copy.masterdataMappingModels = [];
        if (selectedMasterdataTypeOption?.typeCode) {
            copy.typeCode = selectedMasterdataTypeOption.typeCode;
        }
        setMasterdataModel(copy);
    }, [selectedMasterdataTypeOption]);

    const addMapping = () => {
        if (!masterdataModel) return;

        const masterdataMapping: MasterdataMapping = {
            control: masterdataModel?.control,
            masterdataControl: masterdataModel?.control,
            key: '',
            scope: '',
            masterdataTypeCode: masterdataModel?.typeCode,
            keyReference: '',
            metadata: {}
        };

        let copy = { ...masterdataModel };

        if (selectedMasterdataTypeOption?.masterdataTemplateModel) {
            selectedMasterdataTypeOption?.masterdataTemplateModel.masterdataTemplateFieldModels.filter((field) => field.mappingField).map((field) => {
                masterdataMapping.metadata[field.name] = undefined;
            });
        }

        copy.masterdataMappingModels?.push(
            masterdataMapping
        );

        setMasterdataModel(copy);
    };

    const removeMapping = (masterdataMappingToRemove: MasterdataMapping) => {
        if (!masterdataModel) return;

        let copy = { ...masterdataModel };

        copy.masterdataMappingModels = masterdataModel?.masterdataMappingModels.filter((masterdataMapping) => {
            if (masterdataMapping.masterdataTypeCode !== masterdataMappingToRemove.masterdataTypeCode) return true;
            if (masterdataMapping.scope !== masterdataMappingToRemove.scope) return true;
            if (masterdataMapping.key !== masterdataMappingToRemove.key) return true;
            return false;
        });

        setMasterdataModel(copy);
    };

    const onMetadataUpdate = (metadataFields: { [id: string]: MasterdataEditField; }) => {
        if (!masterdataModel) return;
        if (!metadataFields) return;

        let copy = { ...masterdataModel };

        copy.metadata = {};

        Object.keys(metadataFields).map((key, index) => {
            copy.metadata[key] = metadataFields[key].value;
        });

        setMasterdataModel(copy);
    };

    const onMappingMetadataUpdate = (masterdataMappingIndex: number, metadataFields: {
        [id: string]: MasterdataEditField;
    }) => {
        if (!masterdataModel) return;
        if (!metadataFields) return;

        let copy = { ...masterdataModel };

        copy.masterdataMappingModels[masterdataMappingIndex].metadata = {};

        Object.keys(metadataFields).map((key, index) => {
            copy.masterdataMappingModels[masterdataMappingIndex].metadata[key] = metadataFields[key].value;
        });

        setMasterdataModel(copy);
    };

    const masterdataTypeSelectOptions = masterdataTypeOptions.sort((a, b) => compareFunction(a, b, "typeCode")).map((masterdataTypeOption => {
        let label = masterdataTypeOption.typeCode + ' (' + masterdataTypeOption.numEntries + ')';

        if (masterdataTypeOption.masterdataTemplateModel) {
            label += ' - ' + masterdataTypeOption.masterdataTemplateModel.descr;
        } else {
            label += ' - Custom Type';
        }

        return {
            value: masterdataTypeOption,
            label: label
        };
    }));

    return (
        <div>
            <div className="w-full flex items-center justify-between">
                <h3 className="text-base font-bold">
                    Masterdata
                </h3>
                <span className="rounded-box bg-primary text-primary-content text-center px-4 py-1">
                    {
                        props.focusedCustaccount?.name ? props.focusedCustaccount?.name : AuthService.getUser()?.custaccountName
                    }
                </span>
            </div>
            <div className='divider my-2'>

            </div>
            <table className='table w-full table-compact'>
                <thead>
                </thead>
                <tbody>
                <tr>
                    <th className='w-1/3' align='right'>Type<span
                        className='text-orange-500 font-bold'> *</span></th>
                    <td>
                        {masterdataModel?.control ? masterdataModel.typeCode : ''}
                        {!masterdataModel?.control && <Select
                            className='z-50'
                            onChange={(option) => {
                                setSelectedMasterdataTypeOption(option ? option.value : null);
                            }}
                            placeholder='Masterdata type...'
                            isClearable={true}
                            options={masterdataTypeSelectOptions} />}
                    </td>
                </tr>
                <tr>
                    <th align='right'>Key<span
                        className='text-orange-500 font-bold'> *</span></th>
                    <td><input type='text' placeholder='key'
                               value={masterdataModel ? masterdataModel.key : ''}
                               onChange={(e) => {
                                   if (!masterdataModel) return;
                                   let copy = { ...masterdataModel };
                                   copy.key = e.target.value;
                                   setMasterdataModel(copy);
                               }}
                               className='input input-sm input-bordered w-full max-w-xs' />
                    </td>
                </tr>
                <tr>
                    <th align='right'>Key Reference</th>
                    <td><input type='text' placeholder='key reference'
                               value={masterdataModel ? masterdataModel.keyReference : ''}
                               onChange={(e) => {
                                   if (!masterdataModel) return;
                                   let copy = { ...masterdataModel };
                                   copy.keyReference = e.target.value;
                                   setMasterdataModel(copy);
                               }}
                               className='input input-sm input-bordered w-full max-w-xs' />
                    </td>
                </tr>
                </tbody>
            </table>


            <table className='table w-full table-compact mt-2'>

                <thead>
                <tr>
                    <th>Scope</th>
                    <th>Mapped Key</th>
                    <th>Key Reference</th>
                    <th>Metadata</th>
                </tr>
                </thead>
                <tbody>

                {masterdataModel && masterdataModel.masterdataMappingModels.map((masterdataMapping, index) => (
                    <tr key={index}>
                        <td><input className='input input-sm input-bordered w-full max-w-xs' type='text'
                                   onChange={(e) => {
                                       let copy = { ...masterdataModel };
                                       copy.masterdataMappingModels[index].scope = e.target.value;
                                       setMasterdataModel(copy);
                                   }}
                                   value={masterdataMapping.scope} /></td>
                        <td><input className='input input-sm input-bordered w-full max-w-xs' type='text'
                                   onChange={(e) => {
                                       let copy = { ...masterdataModel };
                                       copy.masterdataMappingModels[index].key = e.target.value;
                                       setMasterdataModel(copy);
                                   }}
                                   value={masterdataMapping.key} /></td>
                        <td><input className='input input-sm input-bordered w-full max-w-xs' type='text'
                                   onChange={(e) => {
                                       let copy = { ...masterdataModel };
                                       copy.masterdataMappingModels[index].keyReference = e.target.value;
                                       setMasterdataModel(copy);
                                   }}
                                   value={masterdataMapping.keyReference} /></td>
                        <td>

                            <table className='w-full'>

                                <tbody>
                                {
                                    selectedMasterdataTypeOption && masterdataModel &&
                                    <MasterdataMetadataEditRows
                                        key={index}
                                        isMapping={true}
                                        isNewEntry={!masterdataModel.control}
                                        masterdataTemplate={selectedMasterdataTypeOption?.masterdataTemplateModel}
                                        onChange={(metadataUpdateFields: {
                                            [id: string]: MasterdataEditField;
                                        }) => onMappingMetadataUpdate(index, metadataUpdateFields)}
                                        metadata={masterdataMapping.metadata}
                                    />
                                }
                                </tbody>

                            </table>

                            <div className='text-right'>
                                <div onClick={() => removeMapping(masterdataMapping)}
                                     className='btn btn-xs btn-link w-fit'>remove mapping
                                </div>
                            </div>

                        </td>
                    </tr>
                ))
                }

                </tbody>
            </table>

            <div className='text-center'><span onClick={() => addMapping()}
                                               className='btn btn-sm btn-link'>add mapping</span></div>

            <div className='mt-3'>

                <table className='table table-compact w-full'>
                    <thead>
                    <tr>
                        <th colSpan={3}>Metadata</th>
                    </tr>
                    </thead>

                    <tbody>
                    {selectedMasterdataTypeOption && masterdataModel &&
                        <MasterdataMetadataEditRows
                            isMapping={false}
                            isNewEntry={!masterdataModel.control}
                            masterdataTemplate={selectedMasterdataTypeOption?.masterdataTemplateModel}
                            onChange={(metadataUpdateFields: {
                                [id: string]: MasterdataEditField;
                            }) => onMetadataUpdate(metadataUpdateFields)}
                            metadata={masterdataModel.metadata}
                        />}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default MasterdataEditModal;