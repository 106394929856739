import { MasterdataService } from '../../services/masterdata.service';
import React, { useRef, useEffect, useState } from 'react';
import { MasterdataUploadResponse } from '../../types/masterdata';
import { ChangeEvent } from 'react';
import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios/index';
import { ArrowUpOnSquareIcon } from '@heroicons/react/20/solid';

export const MasterdataUploadModal = () => {

    const inputFileRef = useRef(null);

    const [masterDataUploadResponse, setMasterDataUploadResponse] = useState<MasterdataUploadResponse>();
    const [visible, setVisible] = useState<boolean>(false);
    const [file, setFile] = useState<File>();

    useEffect(() => {

        resetFileInput();
        setMasterDataUploadResponse(undefined);

    }, [visible]);

    const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {

        if (!event.target.files?.length) {
            return;
        }

        setFile(event.target.files[0]);
        setMasterDataUploadResponse(undefined);
    };

    const resetFileInput = () => {
        if (inputFileRef.current != null) (inputFileRef.current as HTMLInputElement).value = '';
        setFile(undefined);
    };

    const submitFile = () => {

        if (!file) {
            toast.error('No file selected.');
            return;
        }

        MasterdataService.uploadMasterdataFile(file).then((response: AxiosResponse<MasterdataUploadResponse>) => {
            setMasterDataUploadResponse(response.data);
            resetFileInput();
        });
    };

    const formatResultRowNumbers = (rowNumbers: [number] | null) => {

        if (rowNumbers == null) return 'n/a';
        if (!rowNumbers.length) return '0';

        if (rowNumbers.length > 50) {
            return rowNumbers.length + ' [' + rowNumbers.slice(0, 49).join(',') + '...]';
        } else {
            return rowNumbers.length + ' [' + rowNumbers.join(',') + ']';
        }
    };

    return (

        <>
            <div className='indicator mx-2'>
                <button onClick={() => setVisible(true)} className='btn btn-sm btn-secondary  btn-outline'>
                    <ArrowUpOnSquareIcon className="w-4 h-4 mr-1"/>
                    Bulk Upload
                </button>
            </div>

            <input type='checkbox' onChange={() => {
            }} checked className='modal-toggle' />
            {visible &&
                <div className='modal'>
                    <div className='modal-box w-11/12 max-w-5xl'>

                        <div>
                            <table className='w-5/6'>
                                <tbody>
                                <tr>
                                    <th className='w-1/3' align='right'>File<span
                                        className='text-orange-500 font-bold mx-2'>*</span></th>
                                    <td>
                                        <input className="file-input file-input-sm file-input-primary max-w-xs" ref={inputFileRef} type='file' name='file' onChange={handleChangeFile} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <table className='table table-compact w-full mt-2'>

                                            <thead>
                                            <tr>
                                                <th align={'left'} colSpan={2}>Masterdata</th>
                                            </tr>
                                            </thead>
                                            {masterDataUploadResponse?.masterdata && <tbody>
                                            <tr>
                                                <th className='w-20' align='right'>Updated:</th>
                                                <td>{masterDataUploadResponse?.masterdata.updated ? masterDataUploadResponse?.masterdata.updated.length : 'n/a'}</td>
                                            </tr>
                                            <tr>
                                                <th align='right'>Invalid:</th>
                                                <td>{formatResultRowNumbers(masterDataUploadResponse?.masterdata.invalid)}</td>
                                            </tr>
                                            <tr>
                                                <th align='right'>Failed:</th>
                                                <td>{formatResultRowNumbers(masterDataUploadResponse?.masterdata.failed)}</td>
                                            </tr>
                                            <tr>
                                                <th align='right'>Duplicates:</th>
                                                <td>{formatResultRowNumbers(masterDataUploadResponse?.masterdata.duplicated)}</td>
                                            </tr>
                                            </tbody>}
                                            {!masterDataUploadResponse?.masterdata && <tbody>
                                            <tr>
                                                <td colSpan={2}>imported from the <strong>data</strong> sheet</td>
                                            </tr>
                                            </tbody>
                                            }
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <table className='table table-compact w-full mt-2'>

                                            <thead>
                                            <tr>
                                                <th align={'left'} colSpan={2}>Mapping</th>
                                            </tr>
                                            </thead>
                                            {masterDataUploadResponse?.mapping && <tbody>
                                            <tr>
                                                <th className='w-20' align='right'>Updated:</th>
                                                <td>{masterDataUploadResponse?.mapping.updated ? masterDataUploadResponse?.mapping.updated.length : 'n/a'}</td>
                                            </tr>
                                            <tr>
                                                <th align='right'>Invalid:</th>
                                                <td>{formatResultRowNumbers(masterDataUploadResponse?.mapping?.invalid)}</td>
                                            </tr>
                                            <tr>
                                                <th align='right'>Failed:</th>
                                                <td>{formatResultRowNumbers(masterDataUploadResponse?.mapping?.failed)}</td>
                                            </tr>
                                            <tr>
                                                <th align='right'>Duplicates:</th>
                                                <td>{formatResultRowNumbers(masterDataUploadResponse?.mapping?.duplicated)}</td>
                                            </tr>
                                            <tr>
                                                <th align='right'>Invalid Masterdata Key:</th>
                                                <td>{formatResultRowNumbers(masterDataUploadResponse?.mapping?.missingMasterdataKey)}</td>
                                            </tr>
                                            </tbody>}

                                            {!masterDataUploadResponse?.mapping && <tbody>
                                            <tr style={{
                                                borderBottom: '1px solid #e5e7eb',
                                                borderTop: '1px solid #e5e7eb'
                                            }}>
                                                <td colSpan={2}>imported from the <strong>mapping</strong> sheet</td>
                                            </tr>
                                            </tbody>
                                            }
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>

                        <div className='modal-action'>
                            <span onClick={() => setVisible(false)} className='btn btn-sm btn-secondary btn-outline'>Close</span>
                            <span onClick={() => submitFile()} className='btn btn-sm btn-primary btn-outline'>Submit</span>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default MasterdataUploadModal;