import React, { useEffect, useState } from 'react';
import { codeToDescr, convertFileSize } from '../../utils/xdoc';
import { AuthService } from '../../services/auth.service';
import { MsginService } from '../../services/msgin.service';
import { AxiosError, AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { CustaccountModel } from '../../types/custaccount';
import { TrashIcon } from '@heroicons/react/20/solid';

interface MsginUploadProps {
    callback: Function,
    focusedCustaccount: CustaccountModel | null
}

export const MsginUploadModal = (props: MsginUploadProps) => {
    const [fileList, setFileList] = useState<File []>([]);

    const handleFileUpload = () => {
        fileList.forEach((file)=>{
            MsginService.uploadMsginAttachment(file, props.focusedCustaccount).then((response: AxiosResponse<any>) => {
                    if (response.status >= 200 && response.status < 300) {
                        toast.success('Upload successful.');
                    }
                    props.callback(file);
                }
            ).catch((error: AxiosError) => {
                toast.error(error.message);
            })
        })
        clearFiles();
    }

    // TODO: for sure not the react way to do this. In a pinch, need to fix later.
    const clearFiles = () => {
        setFileList([]);
        const fileObject: any = document.getElementById('msgin-files');
        fileObject.value = null;
    }

    return (
        <>
            <input type="checkbox" id="msgin-upload-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box">
                    <div className="flex w-full items-center justify-between px-2">
                        <h3 className="text-lg font-bold">
                            Msgin Upload
                        </h3>
                        <span className="rounded-box bg-primary text-primary-content px-3 py-1">
                            {
                                props.focusedCustaccount ?
                                    props.focusedCustaccount.name
                                    :
                                    AuthService.getUser()?.custaccountName
                            }
                        </span>
                    </div>

                    <span className="w-full flex items-center justify-center">
                        <div className='divider my-2 w-8/12' />
                    </span>

                    <span className="w-full flex items-center justify-center">
                        <input
                            multiple
                            id="msgin-files"
                            type="file"
                            placeholder="Upload msgin"
                            className="w-full file-input file-input-xs file-input-primary cursor-pointer"
                            onChange={(event) => {
                                // @ts-ignore
                                let files: File [] = Array.from(event.target.files);
                                setFileList(files)
                            }}
                        />
                    </span>
                    {
                        fileList.length !== 0 &&
                        <span className="w-full flex flex-col items-center justify-center my-1 gap-1 border border-success p-2 rounded-box">
                        {
                            fileList.map((file: File, index: number) => {
                                return (
                                    <span key={index} className="text-xs flex w-full justify-between items-center">
                                        <span className="badge badge-primary text-xs w-20 monospace">
                                            {
                                                convertFileSize(file.size)
                                            }mb
                                        </span>
                                        <p className="monospace w-full text-start ml-4">
                                            {file.name}
                                        </p>
                                    </span>
                                    )
                                })
                            }

                            <span className="btn btn-xs btn-error" onClick={clearFiles}>
                                Clear Files
                            </span>
                        </span>
                    }

                    <span className="w-full flex items-center justify-center">
                        <div className='divider my-2 w-8/12' />
                    </span>

                    <div className="modal-action">
                        <label htmlFor="msgin-upload-modal" className="btn btn-sm btn-secondary btn-link">Close</label>
                        <label htmlFor="msgin-upload-modal" onClick={handleFileUpload} className={`btn btn-sm btn-primary btn-link ${fileList.length === 0 && 'btn-disabled'}`}>Upload</label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MsginUploadModal;