import toast from 'react-hot-toast';
import { LooseObject } from '../types/util-types';

export function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(function() {
        toast.success("Copied to clipboard");
    }, function(err) {
        toast.error("Could not copy to clipboard");
    });
}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function compareFunction(a: LooseObject, b: LooseObject, compareKey: string = "name", desc: boolean = false): number {
    let reverse = desc ? -1 : 1;
    return a[compareKey] > b[compareKey] ? reverse : reverse*-1;
}