import { ArrowLeftCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { AuthService } from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { AuthUser } from '../types/auth';
import React, { useState } from 'react';
import CustaccountSelectorComponent from './custaccount/selector.component';
import { CustaccountModel } from '../types/custaccount';
import { CustaccountService } from '../services/custaccount.service';
import { isDev, isLocal, isProd, OPS_API_HOST } from '../server.config';

interface NavigationBarProps {
    onFocusCustaccountChange: Function,
    focusedCustaccount: CustaccountModel | null
}

export const NavigationBar = (props: NavigationBarProps) => {

    const navigate = useNavigate();

    const handleLogout = () => {
        AuthService.logout();
        navigate('../login', { replace: true });
    };

    let authUserJson = localStorage.getItem('user');

    let authUser = null;

    if (authUserJson != null) {
        authUser = JSON.parse(authUserJson);
    }

    return (
        <div className='w-full flex justify-center bg-base-200 drop-shadow z-50 relative'>
            <div className='navbar max-w-8xl'>
                <div className='flex-none'>
                    <a onClick={() => navigate('/')} className='btn btn-ghost rounded-lg text-xl'>
                        UK1 OPS
                        <span className="badge badge-lg badge-primary shadow-lg mx-2">
                            {
                                isProd() ? 'Live' : isDev() ? 'Dev' : isLocal() ? 'Local' : ''
                            }
                        </span>
                    </a>
                </div>

                {
                    AuthService.getUser() ?
                        <>
                            <div className='flex-1 px-4'>
                                <ul className='menu menu-horizontal'>
                                    <li tabIndex={0}>
                                        <a className={'btn btn-ghost rounded-lg justify-center font-normal hover:text-primary'}>
                                            Tracking
                                            <ChevronDownIcon className='w-4 h-4' />
                                        </a>
                                        <ul className='p-2 bg-base-200 drop-shadow z-50'>
                                            <li>
                                                <a className={'btn btn-ghost rounded-lg justify-start font-normal hover:text-primary'}
                                                   href={'/xdoc'}
                                                >
                                                    xDocs
                                                </a>
                                            </li>

                                            <li>
                                                <a className={'btn btn-ghost rounded-lg justify-start font-normal hover:text-primary'}
                                                   href={'/msgin'}
                                                >
                                                    Msgin
                                                </a>
                                            </li>

                                            <li>
                                                <a className={'btn btn-ghost rounded-lg justify-start font-normal hover:text-primary'}
                                                   href={'/msgout'}
                                                >
                                                    Msgout
                                                </a>
                                            </li>
                                        </ul>
                                    </li>

                                    {
                                        (AuthService.isM2Admin() || AuthService.isM2Support()) ?
                                            <li tabIndex={0}>
                                                <a className={'btn btn-ghost rounded-lg justify-center font-normal hover:text-primary'}>
                                                    Process
                                                    <ChevronDownIcon className='w-4 h-4' />
                                                </a>
                                                <ul className='p-2 bg-base-200 drop-shadow z-50'>
                                                    <li>
                                                        <a className={'btn btn-ghost rounded-lg justify-start font-normal hover:text-primary'}
                                                           href={'/processflow'}
                                                        >
                                                            Process Flows
                                                        </a>
                                                    </li>


                                                    <>
                                                        <li>
                                                            <a className={'btn btn-ghost rounded-lg justify-start font-normal hover:text-primary'}
                                                               href={'/processflowexec'}
                                                            >
                                                                Process Flow Executions
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a className={'btn btn-ghost rounded-lg justify-start font-normal hover:text-primary'}
                                                               href={'/process'}
                                                            >
                                                                Processes
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a className={'btn btn-ghost rounded-lg justify-start font-normal hover:text-primary'}
                                                               href={OPS_API_HOST+'/sandbox?token='+AuthService.getUser()?.token} target={'_blank'}
                                                            >
                                                                Sandbox
                                                            </a>
                                                        </li>
                                                    </>
                                                </ul>
                                            </li>
                                            :
                                            <li>
                                                <a className={'btn btn-ghost rounded-lg justify-start font-normal hover:text-primary'}
                                                   href={'/processflowexec'}
                                                >
                                                    Process Flow Executions
                                                </a>
                                            </li>
                                    }

                                    {
                                        (AuthService.isM2Admin() || AuthService.isM2Support()) &&
                                        <li>
                                            <a className={'btn btn-ghost rounded-lg justify-center font-normal hover:text-primary'}
                                               href={'/msginmapping'}
                                            >
                                                Msgin Mapping
                                            </a>
                                        </li>
                                    }

                                    <li>
                                        <a className={'btn btn-ghost rounded-lg justify-center font-normal hover:text-primary'}
                                           href={'/masterdata'}
                                        >
                                            Masterdata
                                        </a>
                                    </li>

                                    {
                                        AuthService.isM2Admin() &&
                                        <li>
                                            <a className={'btn btn-ghost rounded-lg justify-center font-normal hover:text-primary'}
                                               href={'/engine'}
                                            >
                                                Engine
                                            </a>
                                        </li>
                                    }


                                    {
                                        AuthService.isM2Admin() &&
                                        <>
                                            <div className='divider divider-horizontal mx-0' />

                                            <li tabIndex={0}>
                                                <a className={'btn btn-ghost rounded-lg justify-center font-normal hover:text-primary'}>
                                                    Admin
                                                    <ChevronDownIcon className='w-4 h-4' />
                                                </a>
                                                <ul className='p-2 bg-base-200 drop-shadow z-50'>
                                                    <li>
                                                        <a className={'btn btn-ghost rounded-lg justify-center font-normal hover:text-primary'}
                                                           href={'/user'}
                                                        >
                                                            Users
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a className={'btn btn-ghost rounded-lg justify-center font-normal hover:text-primary'}
                                                           href={'/custaccount'}
                                                        >
                                                            Custaccounts
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </>
                                    }

                                </ul>

                            </div>

                            <div className='flex-none'>

                                <div className='flex flex-col gap-1 mr-2 items-center'>
                                    <div className='flex items-center text-base-content text-opacity-60'>
                                        <span className='font-bold text-base'>
                                            {
                                                authUser?.name
                                            }
                                        </span>

                                        <span className='badge badge-primary mx-2'>
                                            {
                                                authUser?.custaccountName
                                            }
                                        </span>

                                        {/*<span className='badge badge-primary text-sm'>*/}
                                        {/*    {*/}
                                        {/*        authUser?.role*/}
                                        {/*    }*/}
                                        {/*</span>*/}
                                    </div>

                                    <div className='font-bold text-sm text-base-content text-opacity-60'>
                                        {
                                            (AuthService.isM2Admin() || AuthService.isM2Support()) &&
                                            <CustaccountSelectorComponent
                                                onChange={(custaccountModel: CustaccountModel | null) => {
                                                    props.onFocusCustaccountChange(custaccountModel);
                                                }}
                                                focusedCustaccount={props.focusedCustaccount}
                                                canBeAll={true}
                                            />
                                        }
                                    </div>
                                </div>

                                            <button className='btn btn-ghost rounded-lg' onClick={handleLogout}>
                                    <span className='w-full h-full flex justify-center items-center'>
                                        <p>
                                            Logout
                                        </p>
                                        <ArrowLeftCircleIcon className='w-6 h-6 ml-2' />
                                    </span>
                                </button>

                            </div>
                        </>

                        :
                        <></>
                }
            </div>
        </div>
    );
};

export default NavigationBar;