import http from '../http-common';
import { MsgoutSearchResponse } from '../types/msgout';
import { CustaccountModel } from '../types/custaccount';

export const MsgoutService = {
    searchMsgout
};

async function searchMsgout(searchText: string, resultPage: number, resultsPerPage: number, sortField: string, sortDirection: string, focusedCustaccount: CustaccountModel | null) {
    return http.get<MsgoutSearchResponse>('/app/msgout?resultPage=' + resultPage + '&resultsPerPage=' + resultsPerPage + (searchText ? ('&searchText=' + searchText.replace('[', '%5B')).replace(']', '%5D') : '') + (sortField ? ('&sortField=' + sortField) : '') + (sortDirection ? ('&sortDirection=' + sortDirection) : '') + (focusedCustaccount ? ('&focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}