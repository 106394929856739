import http from '../http-common';

export const engineService = {
    pauseAll,
    enableAll,

    initTail
};

function pauseAll() {
    return http.post<any>('/app/engine/pauseAll', {});
}

function enableAll() {
    return http.post<any>('/app/engine/enableAll', {});
}

function initTail(execId: string) {
    return http.post<any>('/app/engine/exec/' + execId + '/initTail', {});
}