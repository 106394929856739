import { OPS_API_HOST } from './server.config';
import { AuthUser } from './types/auth';
import request from 'axios';
import axios, { AxiosError } from 'axios';
import toast from 'react-hot-toast';

let headers: any = { 'Content-type': 'application/json' };

let userJson: string | null = localStorage.getItem('user');

if (userJson != null) {

    try {
        let user: AuthUser = JSON.parse(userJson);

        if (user.token) {
            headers.Authorization = 'Bearer ' + user.token;
        }
    } catch (e) {
        ;
    }
}

const instance = axios.create({
    baseURL: OPS_API_HOST,
    headers
});

type M2ExceptionResponse = {
    code: string;
    type: string;
    message: string;
};

instance.interceptors.response.use(function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (request.isAxiosError(error) && error.response) {

        if (error.response.status === 401) {
            window.location.href = '/login';
            return;
        }

        try {

            if (error.response?.data) {
                toast.error((error.response?.data as M2ExceptionResponse).message);
            } else {
                if (error.response.status === 403) {
                    toast.error('Access denied');
                }
            }

        } catch (err) {
            ;
        }
    }

    return Promise.reject(error);
});

export default instance;