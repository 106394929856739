import React, { useEffect, useState } from 'react';
import PaginationButtonGroup, { ResultsPerPageOptions } from '.././pagination-button-group.component';
import { PaginatedResponse } from '../../types/paginated-response';
import { AxiosError, AxiosResponse } from 'axios';

import { convertFileSize } from '../../utils/xdoc';
import { Msgin } from '../../types/msgin';
import { MsginService } from '../../services/msgin.service';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { AuthService } from '../../services/auth.service';
import { OPS_API_HOST } from '../../server.config';
import { copyToClipboard } from '../../utils/interaction';
import { SortableTableProps } from '../../types/util-types';
import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import CustaccountSelectorComponent from '../custaccount/selector.component';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

export const MsginEntriesTable = (props: SortableTableProps) => {
    const [resultsPerPage, setResultsPerPage] = useState<number>(10);

    const [entries, setEntries] = useState<Msgin[]>();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();

    const handleSubmitMsginAttachment = (uuid: string) => {
        MsginService.resubmitMsginAttachment(uuid).then((response: AxiosResponse<any>) => {
                if (response.status >= 200 && response.status < 300) {
                    toast.success('Job resubmitted.');
                }
            }
        ).catch((error: AxiosError) => {
            console.log(error);
        })
    }

    useEffect(() => {
        setCurrentPage(1);
    }, [props.searchText]);

    useEffect(() => {
        MsginService.searchMsgin(
            props.searchText ? props.searchText : '',
            currentPage,
            resultsPerPage,
            props.sortField,
            props.sortDirection,
            props.focusedCustaccount
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            setEntries(response.data.content);
            setPaginationResponse(response.data);
        });

    }, [currentPage, props.searchText, props.focusedCustaccount]);

    return (
        <div className="max-w-8xl w-full">
            <div className=''>
                <table className='table w-full table-compact table-zebra'>
                    <thead>
                    <tr >
                        <th className="">Date Created</th>
                        <th className="">From</th>
                        <th className="">Subject</th>
                        <th className="">File/s</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        entries && entries.map((msgin, index) => (
                            <React.Fragment key={msgin.control}>
                                <tr className="hover:drop-shadow">
                                    <td colSpan={1}>
                                        <AuditedInformation
                                            date={msgin.dateCreated}
                                            by={null}
                                            type={AUDITED_INFORMATION_TYPES.CREATED}
                                        />
                                    </td>
                                    <td colSpan={1}>
                                        {msgin.custaccountName}
                                    </td>
                                    <td colSpan={1} className="max-w-xs overflow-x-auto">
                                        {msgin.subject}
                                    </td>
                                        {
                                        msgin.msginAttachments.length > 0 && msgin.msginAttachments.map(msginAttachment => {
                                            return (
                                                <td key={msginAttachment.uuid} colSpan={2}>
                                                    <div className='flex items-center justify-between'>
                                                        <div className="flex items-center justify-start w-full">
                                                            <span className="badge badge-primary w-20 text-xs font-bold monospace">{convertFileSize(msginAttachment.fileSize)}mb</span>
                                                            <span className="btn btn-sm btn-circle btn-ghost tooltip flex justify-center items-center font-normal ml-1" data-tip={'Copy Filename'} onClick={()=>copyToClipboard(msginAttachment.fileName)}>
                                                                <ClipboardDocumentIcon className="h-4 w-4"/>
                                                            </span>
                                                            <a target="_blank" href={`${OPS_API_HOST}/app/msgin/attachment/download/${msginAttachment.uuid}?token=${AuthService.getUser()?.token}`} className="tooltip flex justify-start items-center btn btn-ghost text-sm monospace font-normal px-2 max-w-sm overflow-x-auto" data-tip={"Download"}>
                                                                {msginAttachment.fileName}
                                                            </a>
                                                        </div>

                                                        <span className="btn btn-primary btn-sm btn-outline" onClick={()=>handleSubmitMsginAttachment(msginAttachment.uuid)}>
                                                            <ArrowPathIcon className="mr-2 w-5 h-5"/>
                                                            re-submit
                                                        </span>
                                                    </div>
                                                </td>)
                                            })
                                        }
                                </tr>
                            </React.Fragment>
                            ))
                    }
                    </tbody>
                </table>

                {entries && entries.length == 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>

            <PaginationButtonGroup onPageClick={setCurrentPage} page={currentPage}
                                   resultsPerPage={resultsPerPage}
                                   numberOfResults={paginationResponse?.numberOfElements}
                                   totalResults={paginationResponse?.totalElements}
                                   numberOfPages={paginationResponse?.totalPages}></PaginationButtonGroup>

            <div className="w-full flex items-center justify-center gap-2 mb-2 monospace">
                <select
                    onChange={(e) => {
                        setResultsPerPage(Number(e.target.value))
                        setCurrentPage(1);
                    }}
                    value={resultsPerPage}
                    className='select select-bordered select-xs'>
                    {
                        ResultsPerPageOptions.map((results) => {
                            return <option key={results}>{results}</option>;
                        })
                    }
                </select>
            </div>
        </div>
    );
};

export default MsginEntriesTable;