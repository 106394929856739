import React, { useEffect, useState } from 'react';
import PaginationButtonGroup from '.././pagination-button-group.component';
import { PaginatedResponse } from '../../types/paginated-response';
import { AxiosResponse } from 'axios';

import { Msgout } from '../../types/msgout';
import { MsgoutService } from '../../services/msgout.service';
import { convertFileSize } from '../../utils/xdoc';
import { OPS_API_HOST } from '../../server.config';
import { AuthService } from '../../services/auth.service';
import MsgoutViewModal from './msgout-view-modal.component';
import { copyToClipboard } from '../../utils/interaction';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { SortableTableProps } from '../../types/util-types';
import MetadataViewHorizontal from '../metadata-view-horizontal.component';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

export const parseDeliveryResponse = (response: string) => {
    if (response) {
        try {
            JSON.parse(response);
            return true;
        } catch (error) {
            return false;
        }
    }
    return true;
}

export const MsgoutEntriesTable = (props: SortableTableProps) => {

    const resultsPerPage = 10;

    const [entries, setEntries] = useState<Msgout[]>();
    const [msgoutView, setMsgoutView] = useState<Msgout | null>();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();

    useEffect(() => {
        setCurrentPage(1);
    }, [props.searchText]);

    useEffect(() => {
        MsgoutService.searchMsgout(
            props.searchText,
            currentPage,
            resultsPerPage,
            props.sortField,
            props.sortDirection,
            props.focusedCustaccount
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            setEntries(response.data.content);
            setPaginationResponse(response.data);
        });

    }, [currentPage, props.searchText, props.focusedCustaccount]);

    console.log(entries);

    return (
        <div className="w-full">

            {msgoutView &&
                <>
                    <input type='checkbox' checked onChange={() => {
                    }} className='modal-toggle' />
                    <div className='modal'>
                        <div className='modal-box w-11/12 max-w-full'>

                            <MsgoutViewModal msgout={msgoutView} />

                            <div className='modal-action'>
                                <span onClick={() => setMsgoutView(null)}
                                      className='btn btn-sm btn-primary btn-outline'>Close</span>
                            </div>
                        </div>
                    </div>
                </>
            }

            <div className=''>
                <table className='table w-full table-compact table-zebra'>
                    <thead>
                    <tr >
                        <th className="">Date Created</th>
                        <th className="">Subject</th>
                        <th className="">File</th>
                        <th className="">Delivered</th>
                        <th className="">Delivery Response</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {
                        entries && entries.map(msgout => (
                            <React.Fragment key={msgout.uuid}>
                                <tr className="hover:drop-shadow">
                                    <td colSpan={1}>
                                        <AuditedInformation
                                            date={msgout.dateCreated}
                                            by={null}
                                            type={AUDITED_INFORMATION_TYPES.CREATED}
                                        />
                                    </td>
                                    <td colSpan={1}>
                                        <div
                                            className={"overflow-x-auto text-sm max-w-xs"}
                                        >
                                            {msgout.subject}
                                        </div>
                                    </td>
                                    <td colSpan={1}>
                                        <div className='flex items-center justify-between h-full'>
                                            <div className="flex items-center justify-start w-full h-full">
                                                <span className="badge badge-primary w-20 text-xs font-bold monospace">{convertFileSize(msgout.fileSize)}mb</span>
                                                <span className="btn btn-sm btn-circle btn-ghost tooltip flex justify-center items-center font-normal ml-1" data-tip={'Copy Filename'} onClick={()=>copyToClipboard(msgout.fileName)}>
                                                    <ClipboardDocumentIcon className="h-4 w-4"/>
                                                </span>
                                                <a
                                                    target="_blank"
                                                    href={`${OPS_API_HOST}/app/msgout/download/${msgout.uuid}?token=${AuthService.getUser()?.token}`}
                                                    className="tooltip h-full flex btn btn-ghost text-sm justify-start items-center monospace font-normal px-2 overflow-x-auto max-w-md"
                                                    data-tip="Download"
                                                >
                                                    {msgout.fileName}
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                    <td colSpan={1}>
                                        <span className={"w-full flex items-center justify-center"}>
                                            {
                                                msgout.delivered ?
                                                    <CheckCircleIcon className="w-5 h-5 text-success"/>
                                                    :
                                                    <XCircleIcon className="w-5 h-5 text-error"/>
                                            }
                                        </span>
                                    </td>
                                    <td colSpan={1}>
                                        {
                                            msgout.deliveryResponse ?
                                                parseDeliveryResponse(msgout.deliveryResponse) ?
                                                <MetadataViewHorizontal
                                                    className={"w-full max-w-xs"}
                                                    metadata={JSON.parse(msgout.deliveryResponse)}
                                                />
                                                    :
                                                    <div className="break-words text-base-content text-opacity-70 rounded-box max-w-xs w-full bg-white p-4 text-xs border-base-content border border-opacity-20 overflow-x-scroll min-h-12">
                                                        {
                                                            msgout.deliveryResponse
                                                        }
                                                    </div>
                                                :
                                                ''
                                        }


                                    </td>

                                    <td colSpan={1}>
                                        <button
                                            className="btn btn-primary btn-sm btn-outline flex justify-center tooltip"
                                            data-tip="View Metadata"
                                            onClick={() => setMsgoutView(msgout)}
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            </React.Fragment>
                            ))
                    }
                    </tbody>
                </table>

                {entries && entries.length === 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>

            <PaginationButtonGroup onPageClick={setCurrentPage} page={currentPage}
                                   resultsPerPage={resultsPerPage}
                                   numberOfResults={paginationResponse?.numberOfElements}
                                   totalResults={paginationResponse?.totalElements}
                                   numberOfPages={paginationResponse?.totalPages}></PaginationButtonGroup>

        </div>
    );
};

export default MsgoutEntriesTable;