import React, { useEffect, useState } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { LooseObject } from '../types/util-types';

interface FlowvarsProps {
    flowvarRules: LooseObject;
    flowvars: LooseObject;
    onFlowvarChange: Function,
    onAttributeAdd: Function
    onAttributeRemove: Function
}

export const FlowvarsCreate = (props: FlowvarsProps) => {
    return (
        <div className={'flex flex-col items-start justify-start'}>
            {
                Object.keys(props.flowvars).length > 0 && Object.keys(props.flowvars).map((key, index) => {
                    return <React.Fragment key={index}>
                        <div className='flex items-center justify-start gap-2 my-2'>
                            <span className='badge' style={{ minWidth: '6rem' }}>
                                {key}
                            </span>
                            <span
                                className={`badge badge-sm ${!('optional' in props.flowvarRules[key]) || !props.flowvarRules[key]['optional'] ? 'badge-error' : 'badge-success'}`}>
                                {!('optional' in props.flowvarRules[key]) || !props.flowvarRules[key]['optional'] ? 'required' : 'optional'}
                            </span>
                        </div>
                        <div className='flex flex-col items-start justify-center gap-1'>
                            {
                                props.flowvars[key].map((attributeObject: LooseObject, index: number) => {
                                    return (
                                        <div key={index} className="flex flex-wrap gap-1 ml-4">
                                            {
                                                Object.keys(attributeObject).map((attribute, innerIndex) => {
                                                    return <React.Fragment key={`${index}_${attribute}`}>
                                                        <input
                                                            type='text'
                                                            placeholder={attribute}
                                                            className='input input-bordered input-sm'
                                                            value={props.flowvars[key][index][attribute]}
                                                            onChange={(event)=>{
                                                                props.onFlowvarChange(key, attribute, index, event.target.value);
                                                            }}
                                                        />
                                                        {
                                                            (Object.keys(attributeObject).length - 1) === innerIndex && (index === props.flowvars[key].length - 1) &&
                                                            <span
                                                                className='btn btn-circle btn-sm ml-2 flex items-center justify-center tooltip font-normal'
                                                                data-tip={'Add ' + key}
                                                                onClick={() => {
                                                                    let newFlowvars = { ...props.flowvars };
                                                                    let attributeObject: LooseObject = {};
                                                                    props.flowvarRules[key].attributes.forEach((attribute: string) => {
                                                                        attributeObject[attribute] = '';
                                                                    });
                                                                    newFlowvars[key].push(attributeObject);
                                                                    props.onAttributeAdd(newFlowvars);
                                                                }}
                                                            >
                                                    <PlusIcon className={'w-5 h-5'} />
                                                </span>
                                                        }
                                                        {
                                                            (props.flowvars[key].length > 1) && (Object.keys(attributeObject).length - 1) === innerIndex && (index === props.flowvars[key].length - 1) &&
                                                            <span
                                                                className='btn btn-circle btn-sm ml-2 flex items-center justify-center tooltip font-normal'
                                                                data-tip={'Remove ' + key}
                                                                onClick={() => {
                                                                    let newFlowvars = { ...props.flowvars };
                                                                    newFlowvars[key].pop();
                                                                    props.onAttributeRemove(newFlowvars);
                                                                }}
                                                            >
                                                    <MinusIcon className={'w-5 h-5'} />
                                                </span>
                                                        }
                                                    </React.Fragment>;
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </React.Fragment>;
                })
            }
        </div>
    );
};

export default FlowvarsCreate;