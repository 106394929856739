import React, { useEffect, useState } from 'react';
import { MasterdataMapping } from '../../types/masterdata-mapping';
import { XdocService } from '../../services/xdoc.service';
import { AxiosResponse } from 'axios';
import { MasterdataTemplateFieldModel, MasterdataTemplateModel } from '../../types/masterdata-template';
import MetadataValidatedInput from './metadata-validated-input.component';

interface MasterdataMetadataEditRowsProps {
    isNewEntry: boolean;
    isMapping: boolean;
    metadata: { [id: string]: any };
    masterdataTemplate: MasterdataTemplateModel | null;
    onChange: ((metadata: { [id: string]: MasterdataEditField; }) => void);
}

export interface MasterdataEditField {
    template: MasterdataTemplateFieldModel | null,
    value: any
}

export const MasterdataMetadataEditRows = (props: MasterdataMetadataEditRowsProps) => {

    const [triggerReload, setTriggerReload] = useState<number>(0);

    const [draftKey, setDraftKey] = useState<string>();
    const [metadataFields, setMetadataFields] = useState<{ [id: string]: MasterdataEditField; }>({});

    const addKey = () => {

        if (!draftKey) return;
        if (metadataFields && metadataFields[draftKey]) return;

        let copy = { ...metadataFields };
        copy[draftKey] = {
            template: null,
            value: ''
        };

        setDraftKey('');
        setMetadataFields(copy);
    };

    const removeKey = (key: string) => {
        let copy: any = { ...metadataFields };
        copy[key].value = 'NULL';
        setMetadataFields(copy);
        props.onChange(copy);
        setTriggerReload(triggerReload + 1);
    };

    const getMetadataFieldsFromProps = (metadata: { [id: string]: any }) => {
        let copy: { [id: string]: MasterdataEditField } = {};

        Object.keys(metadata).map((key) => {
            if (typeof metadata[key] === 'object') {
                copy[key] = { template: null, value: JSON.stringify(metadata[key]) };
            } else {
                copy[key] = { template: null, value: metadata[key] };
            }
        });

        return copy;
    };

    useEffect(()=>{
        setMetadataFields(getMetadataFieldsFromProps(props.metadata));
    }, [props.metadata])

    useEffect(() => {

        if (props.masterdataTemplate) {

            let copy: { [id: string]: MasterdataEditField } = {};

            if (!props.isNewEntry) {
                copy = getMetadataFieldsFromProps(props.metadata);
            }

            props.masterdataTemplate.masterdataTemplateFieldModels.map((masterdataTemplateField) => {

                if (props.isMapping && !masterdataTemplateField.mappingField) return;
                if (!props.isMapping && masterdataTemplateField.mappingField) return;

                if (!copy[masterdataTemplateField.name]) {
                    copy[masterdataTemplateField.name] = {
                        template: masterdataTemplateField,
                        value: props.metadata[masterdataTemplateField.name]
                    };
                } else if (!copy[masterdataTemplateField.name].template) {
                    copy[masterdataTemplateField.name].template = masterdataTemplateField;
                }
            });

            setMetadataFields(copy);
        } else if (props.isNewEntry) {
            setMetadataFields({});
        }

    }, [props.masterdataTemplate]);

    return (
        <>

            {
                metadataFields && Object.keys(metadataFields).map((key, index) => (
                    <tr key={key}>
                        <td className='w-1/3' align='right'>
                            {key} {metadataFields[key].template?.required && <span
                            className='text-orange-500 font-bold'> *</span>
                        }
                        </td>
                        <td className='text-left'>
                            <MetadataValidatedInput
                                key={triggerReload}
                                onChange={(value: any) => {
                                    metadataFields[key].value = value;
                                    setMetadataFields({ ...metadataFields });
                                    props.onChange({ ...metadataFields });
                                }}
                                template={metadataFields[key].template}
                                value={metadataFields[key].value}
                                name={key} />
                        </td>
                        <td className='w-1/5'>
                            {!metadataFields[key].template && metadataFields[key].value !== 'NULL' &&
                                <div className='text-center'>
                                    <span onClick={() => removeKey(key)}
                                                                   className='btn btn-xs btn-link'>remove</span></div>}
                        </td>
                    </tr>))
            }
            <tr>
                <td className='w-1/3' align='right'>
                    <input type='text'
                           value={draftKey}
                           onChange={(e) => {
                               setDraftKey(e.target.value);
                           }}
                           className='w-full input input-bordered input-xs ml-3' />
                </td>
                <td align='left' colSpan={2}>
                    {draftKey && <span onClick={() => addKey()} className='btn btn-xs btn-link'>add</span>}
                </td>
            </tr>
        </>
    );
};

export default MasterdataMetadataEditRows;