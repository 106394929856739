import React, { useEffect, useState } from 'react';

import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';

import Select from 'react-select';
import {EyeIcon, EyeSlashIcon, MinusIcon, PlusIcon, TrashIcon, XMarkIcon} from '@heroicons/react/20/solid';
import { generateSelectStylesDaisy } from '../../utils/styles';
import { ProcessView } from '../../types/process';
import { ProcessService } from '../../services/process.service';
import { FlowvarsCreate } from '../flowvars.component';
import { LooseObject } from '../../types/util-types';
import toast from 'react-hot-toast';
import {isLocal, isProd} from "../../server.config";
import {UserModel, UserModelCreate, UserModelUpdate} from "../../types/user";
import {UserService} from "../../services/user.service";
import {selectStyles} from "../processflow/create-update-modal.component";

interface UserCreateUpdateModalProps {
    onFinish: Function,
    type: string,
    userEdit: UserModel | null,
    focusedCustaccount: CustaccountModel | null
}

const roleSelectOptions = [
    {
        value: 'm2-admin',
        label: 'M2 Admin'
    },
    {
        value: 'm2-support',
        label: 'M2 Support'
    },
    {
        value: 'c-admin',
        label: 'Customer Admin'
    },
    {
        value: 'c-support',
        label: 'Customer Support'
    }
];

export const UserCreateUpdateModal = (props: UserCreateUpdateModalProps) => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);

    const [editPasswordVisible, setEditPasswordVisible] = useState<boolean>(false);

    const [role, setRole] = useState<string>(roleSelectOptions[0].value);

    const [custaccount, setCustaccount] = useState<CustaccountModel | undefined>(undefined);
    const [custaccountModels, setCustaccountModels] = useState<CustaccountModel[]>([]);

    const handleSubmit = () => {
        let valid = true;
        if (name === '') {
            toast.error('Name is required.');
            return;
        }
        if (email === '') {
            toast.error('Email is required.');
            return;
        }
        if (props.type === "create" && password === '') {
            toast.error('Password is required.');
            return;
        }
        if (custaccount === undefined) {
            toast.error('Custaccount is required.');
            return;
        }

        if (props.type === "create") {
            let createdUser: UserModelCreate = {
                custaccountControl: custaccount?.control,

                name: name,
                email: email,
                password: password,

                role: role
            };

            UserService.createUser(createdUser).then(
                (response) => {
                    if (response.status >= 200 && response.status < 400) {
                        toast.success("User created.")
                        props.onFinish();
                    }
                }
            );
        } else {
            if (!props.userEdit?.uuid) return;

            let updatedUser: UserModelUpdate = {
                uuid: props.userEdit?.uuid,
                custaccountControl: custaccount?.control,

                name: name,
                email: email,
                password: editPasswordVisible ? password : '',

                role: role
            };

            UserService.updateUser(updatedUser).then(
                (response) => {
                    if (response.status >= 200 && response.status < 400) {
                        toast.success("User updated.")
                        props.onFinish();
                    }
                }
            );
        }
    };

    useEffect(() => {
        CustaccountService.getCustaccounts().then(
            (response) => {
                setCustaccountModels(response.data.content);
            }
        );
    }, []);

    useEffect(()=> {
        if (props.userEdit) {
            setName(props.userEdit.name);
            setCustaccount(custaccountModels.find((custaccountModel) => custaccountModel.control === props.userEdit?.custaccountControl));
            setEmail(props.userEdit.email);
            setRole(props.userEdit.role);

            setConfirmPassword('');
            setPassword('');
        }
    }, [props.userEdit])

    return (
        <>
            <input type='checkbox' id={`user-${props.type}-modal`} className='modal-toggle' />

            <div className='modal'>
                <div className='modal-box max-w-4xl'>
                    <span className='w-full flex flex-col lg:flex-row justify-between items-center gap-2'>
                        <h3 className='font-bold text-base mr-2'>
                            <span className="capitalize">{props.type}</span> User
                        </h3>

                        <label htmlFor={`user-${props.type}-modal`} className='btn btn-sm btn-circle btn-ghost'>
                            <XMarkIcon className='w-5 h-5' />
                        </label>
                    </span>

                    <div className='divider my-2' />

                    <div className='flex flex-col justify-start items-start w-full gap-4'>
                        <div className='w-full'>
                            <label htmlFor='email' className='text-sm text-base-content text-opacity-60 mt-4'>
                                Email
                                {
                                    email === '' &&
                                    <span
                                        className={`text-error px-1`}>
                                            *
                                        </span>
                                }
                            </label>
                            <input
                                id='email'
                                type='email'
                                placeholder='Email'
                                className='input input-bordered w-full'
                                value={email}
                                onChange={(event)=>setEmail(event.currentTarget.value)}
                            />
                        </div>

                        <div className='w-full'>
                            <label htmlFor='name'
                                   className='text-sm text-base-content text-opacity-60'>
                                Name
                                {
                                    name === '' &&
                                    <span
                                        className={`text-error px-1`}>
                                            *
                                        </span>
                                }
                            </label>
                            <input
                                id='name'
                                required={true}
                                type='text'
                                placeholder='Name'
                                className='input input-bordered w-full'
                                value={name}
                                onChange={(event) => setName(event.currentTarget.value)}
                            />
                        </div>

                        {
                            (props.type === "create" || editPasswordVisible) &&
                            <div className="flex w-full justify-between gap-2" >
                                <div className='w-full'>
                                    <label htmlFor='password' className='text-sm text-base-content text-opacity-60 mt-4'>
                                        Password
                                        <span className="btn btn-xs btn-ghost btn-circle ml-2" onClick={()=>setPasswordVisible(!passwordVisible)}>
                                    {
                                        passwordVisible ?
                                            <EyeSlashIcon className={'w-3 h-3'}/>
                                            :
                                            <EyeIcon className={'w-3 h-3'}/>
                                    }
                                    </span>
                                    </label>
                                    <input
                                        id='password'
                                        type={passwordVisible ? 'text' : 'password'}
                                        placeholder='Password'
                                        className={`input input-bordered w-full ${password.length > 8 ? 'input-success' : 'input-error'}`}
                                        value={password}
                                        onChange={(event)=>setPassword(event.currentTarget.value)}
                                    />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor='confirm-password' className='text-sm text-base-content text-opacity-60 mt-4'>
                                        Confirm Password
                                        <span className="btn btn-xs btn-ghost btn-circle ml-2" onClick={()=>setConfirmPasswordVisible(!confirmPasswordVisible)}>
                                        {
                                            confirmPasswordVisible ?
                                                <EyeSlashIcon className={'w-3 h-3'}/>
                                                :
                                                <EyeIcon className={'w-3 h-3'}/>
                                        }
                                    </span>
                                    </label>
                                    <input
                                        id='confirm-password'
                                        type={confirmPasswordVisible ? 'text' : 'password'}
                                        placeholder='Confirm Password'
                                        className={`input input-bordered w-full ${(password.length > 8 && (password === confirmPassword)) ? 'input-success' : 'input-error'}`}
                                        value={confirmPassword}
                                        onChange={(event)=>setConfirmPassword(event.currentTarget.value)}
                                    />
                                </div>
                            </div>
                        }

                        {
                            props.type === "update" &&
                            <div className='btn btn-link' onClick={()=>setEditPasswordVisible(!editPasswordVisible)}>
                                {
                                    editPasswordVisible ? 'leave ' : 'update '
                                }
                                password
                            </div>
                        }
                        <div className='divider my-2' />

                    </div>

                    <div className='flex justify-between items-start w-full gap-4'>
                        <div className='w-full'>
                            <label htmlFor="custaccount" className='text-sm text-base-content text-opacity-60'>
                                Custaccount
                            </label>
                            {
                                !custaccount &&
                                <span
                                    className={`text-error px-1`}>
                                            *
                                        </span>
                            }

                            <Select
                                id={"custaccount"}
                                autoFocus={false}
                                onChange={(event) => {
                                    // @ts-ignore
                                    setCustaccount(custaccountModels.find((custaccountModel) => custaccountModel.control === event.value));
                                }}
                                styles={selectStyles}
                                placeholder='Select custaccount'
                                value={custaccount && { label: custaccount.name, value: custaccount.control }}
                                options={custaccountModels.map((custaccountModel) => {
                                    return (
                                        {
                                            label: custaccountModel.name,
                                            value: custaccountModel.control
                                        }
                                    );
                                })}
                            />
                        </div>

                        <div className=''>
                            <label className='text-sm text-base-content text-opacity-60'>
                                Role
                            </label>
                            {
                                !role &&
                                <span
                                    className={`text-error px-1`}>
                                            *
                                        </span>
                            }

                            <select onChange={(e) => setRole(e.target.value)}
                                    value={role}
                                    className='select select-bordered select-sm w-44'>
                                {roleSelectOptions.map((roleOption) => {
                                    return <option key={roleOption.value}>{roleOption.value}</option>;
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="divider my-4" />

                    <div className='modal-action'>
                        <label
                            // htmlFor={`user-${props.type}-modal`}
                            className='btn btn-primary btn-sm'
                            onClick={handleSubmit}
                        >
                            <span className="capitalize">{props.type}</span>
                        </label>
                        <label htmlFor={`user-${props.type}-modal`}
                               className='btn btn-secondary btn-sm btn-outline'>Close</label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserCreateUpdateModal;