import { jpaDateToString } from '../utils/date';

import React from 'react';
import {LooseObject} from "../types/util-types";

export const AUDITED_INFORMATION_TYPES: LooseObject = {
    UPDATED: 'updated',
    CREATED: 'created',
    LAST_QUEUED: 'last queued'
}

interface AuditedInformationProps {
    by: string | null;
    date: number | null;
    type: string;
}

export const AuditedInformation = (props: AuditedInformationProps) => {
    return (
        <div className={`badge badge-outline flex flex-wrap justify-start text-start items-center break-all gap-2 max-w-xs w-full text-xs overflow-x-auto h-fit px-1 py-4 shadow z-10 ${props.type === AUDITED_INFORMATION_TYPES.UPDATED ? 'badge-secondary' : props.type === AUDITED_INFORMATION_TYPES.CREATED ? 'badge-primary' : 'badge-accent'} tooltip `} data-tip={`${props.type === AUDITED_INFORMATION_TYPES.UPDATED ? 'Last Updated' : props.type === AUDITED_INFORMATION_TYPES.CREATED ? 'Date Created' : 'Last Queued'}`}>
            <p className="absolute bottom-0 right-1 text-2xs capitalize text-base-content text-opacity-50">
                {props.type}
            </p>
            <div className='font-bold monospace font-mono'>{
                props.date !== null ?
                    jpaDateToString(props.date)
                    :
                    '-'
            }</div>

            {
                props.by !== null && props.by !== undefined &&
                <div className="flex items-center">
                    <span>
                        [{props.by}]
                    </span>
                </div>
            }
        </div>
        // <div className={`flex flex-col flex-wrap justify-start items-start bg-primary text-primary-content p-2 rounded-box shadow tooltip w-full max-w-xs break-all`} data-tip={'Last updated'}>
        //     <div className='flex items-center'>
        //         <ArrowPathRoundedSquareIcon className='w-5 h-5 mr-1' />
        //         <div className='font-bold monospace font-xs'>{
        //             props.date !== null ?
        //                 jpaDateToString(props.date)
        //                 :
        //                 '-'
        //         }</div>
        //     </div>
        //     {
        //         props.by !== null &&
        //         <div className="flex items-center">
        //             <span>
        //                 [{props.by}]
        //             </span>
        //         </div>
        //     }
        // </div>
    );
};