import { MasterdataService } from '../../services/masterdata.service';
import { MasterdataMappingRequest } from '../../types/masterdata-mapping-request';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Masterdata } from '../../types/masterdata';
import MetadataValidatedInput from './metadata-validated-input.component';
import toast from 'react-hot-toast';
import { selectStyles } from '../processflow/create-update-modal.component';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export interface SelectOption {
    readonly value: string;
    readonly label: string;
}

export const MasterdataMappingRequestForm = (mappingRequest: MasterdataMappingRequest) => {

    const [masterdataMappingRequest, setMasterdataMappingRequest] = useState<MasterdataMappingRequest>(mappingRequest);

    const mappingComplete = (masterdataMappingRequest: MasterdataMappingRequest) => {
        if (masterdataMappingRequest.response == null) return false;

        let completedRequiredMetadata = true;

        if (masterdataMappingRequest.masterdataTemplateModel) {
            masterdataMappingRequest.masterdataTemplateModel.masterdataTemplateFieldModels.map((templateField) => {
                if (templateField.mappingField && templateField.required) {
                    if (masterdataMappingRequest.response.metadata[templateField.name] == undefined || masterdataMappingRequest.response.metadata[templateField.name] == '') {
                        completedRequiredMetadata = false;
                    }
                }
            });
        }

        return completedRequiredMetadata && masterdataMappingRequest.response.masterdataKey.length > 0;
    };

    const createMasterdataMapping = (masterdataMappingRequest: MasterdataMappingRequest) => {
        console.log(masterdataMappingRequest);
        if (!mappingComplete(masterdataMappingRequest)) {
            toast.error('Mapping details missing.');
            return;
        }

        MasterdataService.createMasterdataMapping(masterdataMappingRequest.response).then(() => {
            if (mappingRequest.completedCallback) {
                toast.success("Mapping saved");
                mappingRequest.completedCallback(masterdataMappingRequest);
            }
        }).catch(function(error) {
            ;
        });
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<SelectOption[]>((resolve) => {

            if (!inputValue) {
                resolve([]);
                return;
            }

            MasterdataService.searchMasterdata(
                masterdataMappingRequest.masterdataTypeCode,
                inputValue,
                1,
                10,
                null
            ).then(
                (response) => {
                    let selectOptions: SelectOption[] = [];

                    response.data.content.map((masterData) => {
                        selectOptions.push({
                            value: masterData.key,
                            label: '<div class="font-bold text-left">' + masterData.key + '</div><div class="text-left"> ' + masterData.keyReference + '</div>'
                        });
                    });

                    resolve(selectOptions);
                }
            );
        });

    return (
        <div>

            <div className='mt-1'>

                <AsyncSelect
                    placeholder='Your masterdata entry to map to...'
                    styles={selectStyles}
                    noOptionsMessage={({ inputValue }) => !inputValue ? 'Search your masterdata key or value' : 'Key not found in your masterdata'}
                    onChange={(item) => {
                        if (item) {
                            masterdataMappingRequest.response.masterdataKey = item.value.trim();
                            setMasterdataMappingRequest({ ...masterdataMappingRequest });
                        } else {
                            masterdataMappingRequest.response.masterdataKey = '';
                            setMasterdataMappingRequest({ ...masterdataMappingRequest });
                        }
                    }}
                    className=''
                    formatOptionLabel={function(data) {
                        return (
                            <span dangerouslySetInnerHTML={{ __html: data.label }} />
                        );
                    }}
                    cacheOptions
                    isClearable={true}
                    defaultOptions
                    loadOptions={promiseOptions}
                />

            </div>

            {masterdataMappingRequest.response.masterdataKey.length > 0 && masterdataMappingRequest.masterdataTemplateModel && masterdataMappingRequest.masterdataTemplateModel.masterdataTemplateFieldModels.filter((field) => {
                    return field.mappingField;
                }).length > 0 &&

                <div className='alert alert-warning shadow-lg my-1'>
                    <div>
                        <ExclamationTriangleIcon className="w-5 h-5"/>
                        <span>Please review additional options for this mapping</span>
                    </div>
                </div>}

            <table className='w-full'>

                <tbody>
                {
                    masterdataMappingRequest.response.masterdataKey.length > 0 && masterdataMappingRequest.masterdataTemplateModel && masterdataMappingRequest.masterdataTemplateModel.masterdataTemplateFieldModels.filter(masterdataTemplateField => {
                        return masterdataTemplateField.mappingField;
                    }).map(masterdataTemplateField => (

                        <tr key={masterdataTemplateField.name}>

                            <td className='text-right'>
                                {masterdataTemplateField.name} {masterdataTemplateField.required && <span
                                className='text-orange-500 font-bold'> *</span>
                            }
                            </td>

                            <td className='text-left'>

                                <MetadataValidatedInput
                                    onChange={(value: any) => {
                                        masterdataMappingRequest.response.metadata[masterdataTemplateField.name] = value;
                                        setMasterdataMappingRequest({ ...masterdataMappingRequest });
                                    }}
                                    template={masterdataTemplateField}
                                    value={masterdataMappingRequest.response.metadata[masterdataTemplateField.name]}
                                    name={masterdataTemplateField.name} />

                            </td>
                        </tr>
                    ))
                }
                </tbody>

            </table>

            <div className='col-span-4 text-right mt-1'>
                {mappingComplete(masterdataMappingRequest) && <button
                    onClick={() => createMasterdataMapping(masterdataMappingRequest)}
                    className='w-full btn-sm btn-primary mt-1 mb-2'
                >
                    Save Mapping
                </button>}
            </div>

        </div>
    );
};

export default MasterdataMappingRequestForm;