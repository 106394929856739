import React, { useEffect, useState } from 'react';
import { AuthService } from '../../services/auth.service';
import { AxiosError, AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import Select, { SingleValue } from 'react-select';
import { ProcessFlowModel, ProcessFlowSearchResponse, ProcessFlowView } from '../../types/processflow';
import { ProcessflowService } from '../../services/processflow.service';
import { MsginMapping, MsginMappingCreate, MsginMappingCreateRequest } from '../../types/msginmapping';
import { MsginMappingService } from '../../services/msginmapping.service';
import { PaginatedResponse } from '../../types/paginated-response';
import { selectStyles } from '../processflow/create-update-modal.component';

interface MsginMappingAddProps {
    onSuccess: Function,
    focusedCustaccount: CustaccountModel | null | undefined
}
export const MsginMappingAddModal = (props: MsginMappingAddProps) => {
    // const [custaccountModels, setCustaccountModels] = useState<CustaccountModel[]>([]);

    const [processFlowModels, setProcessFlowModels] = useState<ProcessFlowView []>([]);

    const [selectedCustaccountControl, setSelectedCustaccountControl] = useState<number | null>(null);
    const [selectedProcessFlowControl, setSelectedProcessFlowControl] = useState<number | null>(null);

    const [exampleFilename, setExampleFilename] = useState<string>('');

    const [startsWith, setStartsWith] = useState<string>('');
    const [contains, setContains] = useState<string>('');
    const [endsWith, setEndswith] = useState<string>('');

    const [startsWithIgnoreCase, setStartsWithIgnoreCase] = useState<boolean>(true);
    const [containsIgnoreCase, setContainsIgnoreCase] = useState<boolean>(true);
    const [endsWithIgnoreCase, setEndswithIgnoreCase] = useState<boolean>(true);

    useEffect(() => {
        ProcessflowService.getProcessFlows(
            '',
            1,
            10000,
            props.focusedCustaccount
        ).then((response) => {
                setProcessFlowModels(response.data.content);
            }
        )
        // @ts-ignore
        setSelectedCustaccountControl(props.focusedCustaccount?.control);
    }, [props.focusedCustaccount]);

    return (
        <>
            <input type="checkbox" id="msginmapping-add-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-full max-w-4xl gap-4">

                    <div className="flex w-full items-center justify-between px-2">
                        <h3 className="text-lg font-bold">
                            Msgin Mapping Add
                        </h3>
                    </div>

                    <div className='divider my-2' />

                    <div className="flex w-full justify-between items-center gap-4">
                        <div className="flex items-center w-full">
                            <p className="text-sm mr-4">
                                Custaccount
                            </p>
                            <span className="rounded-box bg-primary text-primary-content px-3 py-1">
                                {
                                    props.focusedCustaccount ?
                                        props.focusedCustaccount.name
                                        :
                                        AuthService.getUser()?.custaccountName
                                }
                            </span>
                        </div>

                        <div className="flex items-center w-full">
                            <p className='text-sm mr-2'>
                                Processflow
                            </p>
                            <Select
                                className='z-50 w-full'
                                onChange={(option: any) => {
                                    setSelectedProcessFlowControl(option.value);
                                }}
                                styles={selectStyles}
                                placeholder='process flow…'
                                options={processFlowModels.map((processFlowModel) => {
                                    return {
                                        value: processFlowModel.control,
                                        label: processFlowModel.descr
                                    };
                                })} />
                        </div>
                    </div>

                    <div className='divider my-2' />

                    <div className="flex w-full justify-between items-center">
                        <div className="w-full">
                            <p className="text-sm mb-1 text-start">
                                Example Filename
                            </p>
                            <input
                                type="text"
                                placeholder="Example filename…"
                                className="input input-bordered w-full"
                                onChange={(event)=>setExampleFilename(event.target.value)}
                            />
                        </div>
                    </div>

                    <span className="w-full flex items-center justify-center">
                        <div className='divider my-2 w-8/12' />
                    </span>

                    <div className="flex w-full justify-between items-center gap-4 my-2">
                        <div className="w-full">
                            <p className="text-sm mb-1 text-start">
                                Starts with
                            </p>

                            <input
                                type="text"
                                placeholder="starts with…"
                                className={`input input-bordered w-full ${
                                    startsWithIgnoreCase ?
                                        exampleFilename.toLowerCase().startsWith(startsWith.toLowerCase()) ?
                                            'input-success' :
                                            'input-error'
                                        :
                                        exampleFilename.startsWith(startsWith) ?
                                            'input-success' :
                                            'input-error'
                                }`}
                                onChange={(event)=>setStartsWith(event.target.value)}
                            />

                            <div className={"flex items-center mt-2"}>
                                <p className="text-xs text-opacity-60 mr-2 text-end">
                                    Ignore case?
                                </p>
                                <input
                                    type="checkbox"
                                    className="toggle toggle-success"
                                    checked={startsWithIgnoreCase}
                                    onChange={()=>setStartsWithIgnoreCase(!startsWithIgnoreCase)}
                                />
                            </div>
                        </div>

                        <div className='divider divider-horizontal mx-0' />

                        <div className="w-full">
                            <p className="text-sm mb-1 text-start">
                                Contains
                            </p>

                            <input
                                type="text"
                                placeholder="contains…"
                                className={`input input-bordered w-full ${
                                    containsIgnoreCase ?
                                        exampleFilename.toLowerCase().includes(contains.toLowerCase()) ?
                                            'input-success' :
                                            'input-error'
                                        :
                                        exampleFilename.includes(contains) ? 
                                            'input-success' : 
                                            'input-error'
                                    }`
                                }
                                onChange={(event)=>setContains(event.target.value)}
                            />

                            <div className={"flex items-center mt-2"}>
                                <p className="text-xs text-opacity-60 mr-2 text-end">
                                    Ignore case?
                                </p>
                                <input
                                    type="checkbox"
                                    className="toggle toggle-success"
                                    checked={containsIgnoreCase}
                                    onChange={()=>setContainsIgnoreCase(!containsIgnoreCase)}
                                />
                            </div>
                        </div>

                        <div className='divider divider-horizontal mx-0' />

                        <div className="w-full">
                            <p className="text-sm mb-1 text-start">
                                Extension
                            </p>

                            <input
                                type="text"
                                placeholder="extension…"
                                className={`input input-bordered w-full ${
                                    endsWith === '' ?
                                            'border-error'
                                        :
                                    endsWithIgnoreCase ?
                                        exampleFilename.toLowerCase().endsWith(endsWith.toLowerCase()) ?
                                            'input-success' :
                                            'input-error'
                                        :
                                        exampleFilename.endsWith(endsWith) ?
                                            'input-success' :
                                            'input-error'
                                }`}
                                onChange={(event)=>setEndswith(event.target.value)}
                            />

                            <div className={"flex items-center mt-2"}>
                                <p className="text-xs text-opacity-60 mr-2 text-end">
                                    Ignore case?
                                </p>
                                <input
                                    type="checkbox"
                                    className="toggle toggle-success"
                                    checked={endsWithIgnoreCase}
                                    onChange={()=>setEndswithIgnoreCase(!endsWithIgnoreCase)}
                                />
                            </div>
                        </div>
                    </div>

                    <span className="w-full flex items-center justify-center">
                        <div className='divider my-2 w-8/12' />
                    </span>

                    <div className="modal-action">
                        <label htmlFor="msginmapping-add-modal" className="btn btn-sm btn-secondary btn-outline">Close</label>
                        <label onClick={()=>{
                            if (selectedCustaccountControl === null) {
                                toast.error("Please select a custaccount.")
                            } else if (selectedProcessFlowControl === null) {
                                toast.error("Please select a processflow.")
                            } else {
                                if (endsWith === '') {
                                    toast.error("File extension cannot be blank.")
                                } else if (endsWithIgnoreCase && !exampleFilename.toLowerCase().endsWith(endsWith.toLowerCase())) {
                                    toast.error("Extension not found in example filename.")
                                } else if (!endsWithIgnoreCase && !exampleFilename.endsWith(endsWith)) {
                                    toast.error("Extension not found in example filename.")
                                } else if (containsIgnoreCase && !exampleFilename.toLowerCase().includes(contains.toLowerCase())) {
                                    toast.error("Contains rule not found in example filename.")
                                } else if (!containsIgnoreCase && !exampleFilename.includes(contains)) {
                                    toast.error("Contains rule not found in example filename.")
                                } else if (startsWithIgnoreCase && !exampleFilename.toLowerCase().startsWith(startsWith.toLowerCase())) {
                                    toast.error("Starts with rule not found in example filename.")
                                } else if (!startsWithIgnoreCase && !exampleFilename.startsWith(startsWith)) {
                                    toast.error("Starts with rule not found in example filename.")
                                } else {
                                    const msginMapping: MsginMappingCreate = {
                                        custaccountControl: selectedCustaccountControl,
                                        processFlowControl: selectedProcessFlowControl,

                                        startsWith: startsWith,
                                        endsWith: endsWith,
                                        fileNameContains: contains,

                                        startsWithCaseSensitive: !startsWithIgnoreCase,
                                        endsWithCaseSensitive: !endsWithIgnoreCase,
                                        fileNameContainsCaseSensitive: !containsIgnoreCase
                                    };

                                    const msginMappingRequest: MsginMappingCreateRequest = {
                                        fileName: exampleFilename,
                                        msginMapping: msginMapping
                                    }

                                    MsginMappingService.addMsginMapping(msginMappingRequest).then((response: AxiosResponse)=>{
                                        if (response.data === true) {
                                            toast.success("Msginmapping created.")
                                            props.onSuccess();
                                        }
                                    })
                                }
                            }
                        }} className={`btn btn-sm btn-primary btn-outline`}>
                            Add
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MsginMappingAddModal;