import React, { useEffect, useState } from 'react';
import MetadataView from '../metadata-view.component';
import { Xdoc } from '../../types/xdoc';
import { XdocService } from '../../services/xdoc.service';
import { jpaDateToString } from '../../utils/date';
import MetadataViewHorizontal from '../metadata-view-horizontal.component';
import { BooleanIcon } from '../boolean-icon.component';
import { codeToDescr } from '../../utils/xdoc';
import { ChevronDownIcon, ChevronUpIcon, ClipboardIcon, EyeIcon } from '@heroicons/react/20/solid';
import { copyToClipboard } from '../../utils/interaction';
import { CustaccountModel } from '../../types/custaccount';
import { OPS_API_HOST } from '../../server.config';
import { AuthService } from '../../services/auth.service';
import { parseDeliveryResponse } from '../msgout/entries-table.component';
import toast from 'react-hot-toast';
import { LooseObject } from '../../types/util-types';
import XdocMetadataEditComponent, { METADATA_EDIT_MAP } from './metadata-edit.component';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

interface XdocViewModalProps {
    xdoc: Xdoc;
    focusedCustaccount: CustaccountModel | null;
}

export const XdocViewModal = (props: XdocViewModalProps) => {

    const [xdocView, setXdocView] = useState<Xdoc>();
    const [captureMetadataView, setCaptureMetadataView] = useState<boolean>(true);

    const [viewXdocLines, setViewXdocLines] = useState<boolean>(true);

    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

    const [refresher, setRefresher] = useState<number>(0);

    useEffect(() => {

        if (!props.xdoc.uuid) return;

        XdocService.getXdoc(props.xdoc.uuid, props.focusedCustaccount).then(
            (response) => {
                setXdocView(response.data);
                if (response.data.xdocDetModels.length>30) setViewXdocLines(false);
            }
        );

    }, [props.focusedCustaccount]);

    useEffect(()=>{
        XdocService.getXdoc(props.xdoc.uuid, props.focusedCustaccount).then(
            (response) => {
                setXdocView(response.data);
                if (response.data.xdocDetModels.length>30) setViewXdocLines(false);
            }
        );
    }, [refresher])


    if (!xdocView) {
        return (<div>loading...</div>);
    }

    return (
        <div>

            <div className='badge badge-lg badge-primary'>Header</div>
            <div className='divider my-0' />

            <div className='flex w-full justify-center items-start p-4 my-4 bg-base-300'>
                <div className="flex flex-col w-full justify-start items-center gap-2">
                    <div className="flex w-full justify-start items-center">
                        <h4 className="font-bold text-sm w-32 mr-4">
                            Docno
                        </h4>
                        <span className="badge monospace cursor-pointer" onClick={()=>copyToClipboard(xdocView.uuid ? xdocView.docNo : '')}>
                            {xdocView?.docNo}
                        </span>
                    </div>

                    <div className="flex w-full justify-start items-center">
                        <h4 className="font-bold text-sm w-32 mr-4">
                            Date
                        </h4>
                        <AuditedInformation by={xdocView.createdBy} date={xdocView.docDate} type={AUDITED_INFORMATION_TYPES.CREATED} />
                    </div>

                    <div className="flex w-full justify-start items-center">
                        <h4 className="font-bold text-sm w-32 mr-4">
                            Partner
                        </h4>
                        <p className="badge">
                            {xdocView?.partnerCode}
                        </p>
                    </div>

                    <div className="flex w-full justify-start items-center">
                        <h4 className="font-bold text-sm w-32 mr-4">
                            Status
                        </h4>
                        <p className="badge badge-outline">
                            {codeToDescr(xdocView?.statusCode)}
                        </p>
                    </div>

                    <div className="flex w-full justify-start items-center text-sm">
                        <h4 className="font-bold text-sm w-32 mr-4">
                            Updated
                        </h4>
                        <AuditedInformation
                            by={xdocView.updatedBy}
                            date={xdocView.dateUpdated}
                            type={AUDITED_INFORMATION_TYPES.UPDATED}
                        />
                    </div>

                    {
                        (AuthService.isM2Admin() || AuthService.isM2Support()) &&
                        <>
                            <div className='divider my-2' />
                            <div className="flex w-full justify-start items-center">
                                <h4 className="font-bold text-sm w-32 mr-4">
                                    Uuid
                                </h4>
                                <span className="badge monospace cursor-pointer" onClick={()=>copyToClipboard(xdocView.uuid ? xdocView.uuid : '')}>
                                {xdocView.uuid}
                            </span>
                            </div>
                        </>
                    }
                </div>
                <div className="w-full">
                    <MetadataViewHorizontal
                        metadata={xdocView.metadata}
                        className={null}
                    />
                </div>
            </div>

            {
                Object.keys(METADATA_EDIT_MAP).includes(props.xdoc.typeCode) && (AuthService.isM2Admin()) &&
                (xdocView.statusCode === 'integration_failed' || xdocView.statusCode === 'manual_entry_required') &&
                <XdocMetadataEditComponent
                    xdoc={props.xdoc}
                    focusedCustaccount={props.focusedCustaccount}
                    onRefresh={()=>setRefresher(refresher+1)}
                />
            }

            <div className='badge badge-lg badge-primary mt-2'>
                Lines
            </div>
            <div className='divider my-0' />

            {viewXdocLines ?
                xdocView.xdocDetModels.map((xdocDetView, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className='flex w-full justify-center items-start p-4 my-4 bg-base-300'>
                            <div className="flex flex-col w-full h-full justify-start items-start gap-2">
                                <div className="flex w-full justify-start items-center">
                                    <h4 className="font-bold text-sm w-32 mr-4">
                                        Line
                                    </h4>
                                    <p className="text-sm monospace">
                                        {xdocDetView?.lineNumber}
                                    </p>
                                </div>

                                <div className="flex w-full justify-start items-center">
                                    <h4 className="font-bold text-sm w-32 mr-4">
                                        Status Code
                                    </h4>
                                    <p className="text-sm monospace">
                                        {codeToDescr(xdocDetView?.statusCode)}
                                    </p>
                                </div>

                                <div className="flex w-full justify-start items-center">
                                    <h4 className="font-bold text-sm w-32 mr-4">
                                        Updated
                                    </h4>
                                    <div className="text-sm">
                                        <AuditedInformation
                                            by={xdocDetView.updatedBy}
                                            date={xdocDetView.dateUpdated}
                                            type={AUDITED_INFORMATION_TYPES.UPDATED}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full max-h-96">
                                <MetadataViewHorizontal
                                    metadata={xdocDetView.metadata}
                                    className={"h-48"}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )
            }) :
                <div className='flex w-full justify-start items-center p-4 my-4 bg-base-300'>
                    <span className={"font-bold monospace mr-2"}>
                        {
                            xdocView.xdocDetModels.length
                        }
                    </span>
                    Detail lines [Truncated for Readability]

                    <div className='divider divider-horizontal' />
                    <button
                        className={"btn btn-sm btn-ghost"}
                        onClick={()=>{setViewXdocLines(true)}}
                    >
                        <EyeIcon className={"h-4 w-4 mr-1"}/>
                        View Detail
                    </button>
                </div>
            }

            <div className='badge badge-lg badge-primary mt-2'>Msgin</div>
            <div className='divider my-0' />

            {xdocView.msginAttachmentModels.length !== 0 &&
                <table className='table w-full table-compact table-zebra'>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Subject</th>
                        <th>Received Via</th>
                        <th>Filename</th>
                    </tr>
                    </thead>
                    <tbody>

                    {xdocView.msginAttachmentModels.map((msgin) => (
                        msgin.msginAttachments.map((msginAttachment) => (
                            <tr key={msginAttachment.uuid}>
                                <td>
                                    {msgin?.dateCreated}
                                </td>
                                <td>
                                    {msgin?.subject}
                                </td>
                                <td>
                                    {msgin?.transferMethod}
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href={`${OPS_API_HOST}/app/msgin/attachment/download/${msginAttachment.uuid}?token=${AuthService.getUser()?.token}`}
                                        className="tooltip flex justify-start items-center btn btn-link text-sm monospace font-normal px-2 max-w-lg overflow-x-scroll" data-tip={"Download"}>
                                        {msginAttachment.fileName}
                                    </a>
                                </td>
                            </tr>))))
                    }
                    </tbody>
                </table>
            }

            <div className="flex items-center mt-2 gap-2">
                <div className='badge badge-lg badge-primary'>
                    Capture
                </div>
                {
                    xdocView.captureContent &&
                    <>
                        <button
                            className="btn btn-circle btn-sm flex items-center justify-center tooltip font-normal"
                            data-tip={`View ${captureMetadataView ? 'Raw' : 'Pretty'}`}
                            onClick={()=>setCaptureMetadataView(!captureMetadataView)}
                        >
                            <EyeIcon className="w-4 h-4"/>
                        </button>
                        <button
                            className="btn btn-circle btn-sm flex items-center justify-center tooltip font-normal"
                            data-tip={"Copy to Clipboard"}
                            onClick={()=>copyToClipboard(xdocView.captureContent ? xdocView.captureContent : '')}
                        >
                            <ClipboardIcon className="w-4 h-4"/>
                        </button>
                    </>
                }
            </div>
            <div className='divider my-0' />
                {
                    captureMetadataView ?
                            xdocView.captureContent &&
                                <MetadataViewHorizontal
                                    metadata={JSON.parse(xdocView.captureContent)}
                                    className={null}
                                />
                        :
                        xdocView.captureContent &&
                            <div className="w-full bg-base-300 rounded-box p-4 text-base-content h-96 text-xs overflow-y-scroll monospace max-w-full">
                                {
                                    xdocView.captureContent
                                }
                            </div>
                }
            <div className='badge badge-lg badge-primary mt-2'>Audit</div>
            <div className='divider my-0' />

            <table className='table w-full table-compact table-zebra'>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Line</th>
                    <th>By</th>
                    <th>Message</th>
                    <th>Metadata</th>
                </tr>
                </thead>
                <tbody>
                {xdocView.xdocAuditModels.map((xdocAudit, xdocAuditIndex) => (
                    <tr key={xdocAuditIndex}>
                        <td className="monospace">
                            {xdocAudit?.dateCreated}
                        </td>
                        <td>
                            {xdocAudit?.lineNumber ?? 'n/a'}
                        </td>
                        <td>
                            {xdocAudit?.createdBy}
                        </td>
                        <td>
                            {xdocAudit?.message}
                        </td>
                        <td>
                            {
                                xdocAudit?.metadata &&
                                <MetadataViewHorizontal
                                    className={"mt-2 w-96"}
                                    metadata={xdocAudit.metadata}
                                />
                            }
                        </td>
                    </tr>))}
                </tbody>
            </table>

            <div className='badge badge-lg badge-primary mt-2'>Msgout</div>
            <div className='divider my-0' />

            {
                xdocView.msgoutModels.length !== 0 &&
                <table className='table w-full table-compact table-zebra'>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Filename</th>
                        <th align='center'>Delivered</th>
                        <th>Delivery Response</th>
                    </tr>
                    </thead>
                    <tbody>
                    {xdocView.msgoutModels.map((msgout) => (
                        <tr key={msgout.uuid}>
                            <td>
                                {msgout?.dateCreated}
                            </td>
                            <td>
                                <a target="_blank" href={`${OPS_API_HOST}/app/msgout/download/${msgout.uuid}?token=${AuthService.getUser()?.token}`} className="tooltip h-full flex btn btn-link px-0 text-sm items-center justify-start" data-tip="Download">
                                    {msgout.fileName}
                                </a>
                            </td>
                            <td align='center'>
                                <BooleanIcon success={msgout.delivered} />
                            </td>
                            <td>
                                <div className='w-96 overflow-auto'>
                                    {
                                        msgout.deliveryResponse ?
                                            parseDeliveryResponse(msgout.deliveryResponse) ?
                                                <MetadataViewHorizontal
                                                    className={"w-full max-w-xs"}
                                                    metadata={JSON.parse(msgout.deliveryResponse)}
                                                />
                                                :
                                                <div className="break-words text-base-content text-opacity-70 rounded-box max-w-xs w-full bg-white p-4 text-xs border-base-content border border-opacity-20 overflow-x-scroll min-h-12">
                                                    {
                                                        msgout.deliveryResponse
                                                    }
                                                </div>
                                            :
                                            ''
                                    }
                                </div>
                            </td>
                        </tr>))}
                    </tbody>
                </table>
            }

            {
                AuthService.isM2Admin() &&
                <div className="w-full rounded border-b border-error bg-error bg-opacity-20 mt-2">
                    <div className="w-full flex justify-between items-center p-2 cursor-pointer hover:bg-red-500 hover:bg-opacity-10" onClick={()=>setDeleteVisible(!deleteVisible)}>
                        <div className='badge badge-lg badge-error h-full'>Danger Zone</div>
                        <span className="btn btn-sm btn-circle btn-ghost">
                            {
                                deleteVisible ?
                                    <ChevronUpIcon className={"w-5 h-5"}/>
                                    :
                                    <ChevronDownIcon className={"w-5 h-5"}/>
                            }
                        </span>
                    </div>

                    {
                        deleteVisible &&
                        <div className="w-full p-2">
                            <h4 className="text-sm font-bold btn btn-ghost btn-disabled text-opacity-100">
                                Download Msgin Files
                            </h4>
                            {xdocView.msginAttachmentModels.length !==0 ? xdocView.msginAttachmentModels.map((msgin) => (
                                msgin.msginAttachments.map((msginAttachment) => (
                                    <a
                                        key={msginAttachment.uuid}
                                        target="_blank"
                                        href={`${OPS_API_HOST}/app/msgin/attachment/download/${msginAttachment.uuid}?token=${AuthService.getUser()?.token}`}
                                        className="tooltip flex justify-start items-center btn btn-link text-sm monospace font-normal px-2 max-w-lg overflow-x-scroll" data-tip={"Download"}>
                                        {msginAttachment.fileName}
                                    </a>
                                )))) : <p className="font-light pl-4 mb-3">-</p>
                            }
                            <div className='divider my-2' />
                            <span className="text-sm font-bold btn btn-ghost" onClick={()=>{
                                let copyData: LooseObject = {};
                                copyData['docNo'] = xdocView?.docNo;
                                copyData['uuid'] = xdocView?.uuid;
                                copyData['partnerCode'] = xdocView?.partnerCode;
                                copyData['typeCode'] = xdocView?.typeCode;
                                copyData['statusCode'] = xdocView?.statusCode;

                                copyData['metadata'] = xdocView?.metadata;

                                copyData['docDate'] = xdocView?.docDate;
                                copyData['dateCreated'] = xdocView?.dateCreated;
                                copyData['dateUpdated'] = xdocView?.dateUpdated;

                                copyToClipboard(JSON.stringify(copyData));
                            }}>
                            <ClipboardIcon className={"w-5 h-5 mr-2"} />
                            Copy Essential Details
                        </span>

                            <div className="w-full flex justify-end">
                            <span onClick={() => {
                                if (window.confirm("Are you sure? Make sure you have downloaded the associated msgin files before deleting this xdoc so that you can re-process it.")) {
                                    if (!xdocView?.uuid) {
                                        toast.error("Can not delete xDoc at this time. Try refreshing page.")
                                    } else {
                                        XdocService.deleteXdoc(xdocView?.uuid, props.focusedCustaccount).then(
                                            (response) => {
                                                if (response.status >= 200 && response.status < 300) {
                                                    toast.success("Delete successful");
                                                    window.location.reload();
                                                }
                                            }
                                        );
                                    }
                                }
                            }} className='btn btn-sm btn-error btn-outline hover:bg-opacity-80'
                            >
                                Delete
                            </span>
                            </div>
                        </div>
                    }
                </div>
            }

        </div>
    );
};

export default XdocViewModal;