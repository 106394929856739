import http from '../http-common';
import { AuthUser } from '../types/auth';

export const AuthService = {
    login,
    logout,
    checkLoggedIn,
    resetPassword,
    updatePassword,
    getUser,
    isM2Admin,
    isM2Support,
    isCAdmin
};

function checkLoggedIn(): boolean {
    return !!localStorage.getItem('user');
}

function getUser(): AuthUser | null {
    let json = localStorage.getItem('user');
    if (json) {
        try {
            return JSON.parse(json);
        } catch (e) {
            return null;
        }

    } else {
        return null;
    }
}

function isM2Admin(): boolean {
    const user = getUser();
    if (user && user.role == 'm2-admin') return true;
    return false;
}

function isCAdmin(): boolean {
    const user = getUser();
    if (user && user.role == 'c-admin') return true;
    return false;
}

function isM2Support(): boolean {
    const user = getUser();
    if (user && user.role == 'm2-support') return true;
    return false;
}

async function login(email: string, password: string) {

    return http.post<any>('/app/login', { email, password });
}

async function resetPassword(email: string) {
    return http.post<any>('/app/me/password-reset', { email });
}

async function updatePassword(password: string) {
    return http.put<any>('/app/me/password', { password });
}

function logout() {
    localStorage.removeItem('user');
}