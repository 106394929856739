import { MasterdataService } from '../../services/masterdata.service';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MasterdataEntriesTable from '../../components/masterdata/entries-table.component';
import { AxiosResponse } from 'axios/index';
import MasterdataEditModal from '../../components/masterdata/edit-modal.component';
import { Masterdata } from '../../types/masterdata';
import MasterdataUploadModal from '../../components/masterdata/upload-modal.component';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid';
import { FocusablePageProps } from '../../types/util-types';
import toast from 'react-hot-toast';

const MasterdataPage = (props: FocusablePageProps) => {
    const navigate = useNavigate();

    const [triggerReload, setTriggerReload] = useState<number>(0);

    const [masterdataAdd, setMasterdataAdd] = useState<Masterdata | null>(null);
    const [masterdataTypeCode, setMasterdataTypeCode] = useState<string>('');
    const [masterdataTypeCodes, setMasterdataTypeCodes] = useState<string[]>([]);

    const [searchText, setSearchText] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const [numberOfMappingRequests, setNumberOfMappingRequests] = useState<number>(0);

    const addMasterdata = () => {
        setMasterdataAdd({
            control: undefined,
            uuid: undefined,
            dateCreated: null,
            dateUpdated: null,
            createdBy: null,
            updatedBy: null,
            key: '',
            keyReference: '',
            masterdataMappingModels: [],
            metadata: {},
            typeCode: '',
            masterdataTemplateModel: null
        });
    };

    useEffect(() => {
        MasterdataService.getMappingRequestTypes(
            props.focusedCustaccount
        ).then((response: AxiosResponse<any>) => {
            let sum = 0;
            Object.keys(response.data).map((key) => {
                sum += response.data[key];
            });
            setNumberOfMappingRequests(sum);
        });

    }, [props.focusedCustaccount]);

    useEffect(() => {
        document.title = 'Masterdata';
    }, []);

    useEffect(() => {

        MasterdataService.getMasterdataTypes(props.focusedCustaccount).then((response) => {

            let masterdataTypeCodes: string[] = [];

            Object.keys(response.data).map((typeCode) => {
                masterdataTypeCodes.push(typeCode);
            });

            setMasterdataTypeCodes(masterdataTypeCodes);

        });

    }, [triggerReload, props.focusedCustaccount]);

    return (
        <div>
            {masterdataAdd &&
                <>
                    <input type='checkbox' onChange={() => {
                    }} checked className='modal-toggle' />
                    <div className='modal'>
                        <div className='modal-box w-11/12 max-w-5xl'>

                            <MasterdataEditModal
                                onChange={((updatedMasterdata: Masterdata) => {
                                    setMasterdataAdd(updatedMasterdata);
                                })
                                }
                                masterdata={masterdataAdd}
                                focusedCustaccount={props.focusedCustaccount}
                            />

                            <div className='modal-action'>
                                <span onClick={() => setMasterdataAdd(null)}
                                      className='btn btn-sm btn-secondary btn-outline'>Close</span>
                                <span onClick={() => {
                                    MasterdataService.createMasterdata(masterdataAdd, props.focusedCustaccount).then((response) => {
                                        if (response.status >= 200 && response.status < 300) {
                                            toast.success("Masterdata added.");
                                        }
                                        setMasterdataAdd(null);
                                        setTriggerReload(triggerReload + 1);
                                    });
                                }} className='btn btn-sm btn-primary btn-outline'>
                                    Add
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            }

            <div className='p-2 flex w-full justify-center'>
                <div className="w-full max-w-8xl bg-base-300 shadow flex flex-col">
                    <div className='flex space-x-3 items-center justify-between w-full p-2'>

                    <div className='text-sm breadcrumbs'>
                        <ul>
                            <li>
                                <span className="badge badge-lg badge-primary">
                                    Masterdata
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className='flex'>
                        {(numberOfMappingRequests && numberOfMappingRequests > 0) ? <div>
                                <div className='indicator mx-2'>
                                    <span className='indicator-item badge badge-error badge-sm'>{numberOfMappingRequests}</span>
                                    <button onClick={() => navigate('/masterdata/mapping-request')}
                                            className='btn btn-sm btn-accent btn-outline'>
                                        Mapping Requests
                                    </button>
                                </div>
                            </div>
                            :
                            <div className='indicator mx-2'>
                                <span className='indicator-item badge badge-success badge-sm'>
                                    0
                                </span>
                                <button className='btn btn-sm btn-disabled'>
                                    Mapping Requests
                                </button>
                            </div>
                        }

                        <div className='divider divider-horizontal mr-2'/>

                        <div className='indicator mx-2'>
                            <button onClick={() => addMasterdata()} className='btn btn-sm btn-primary btn-outline'>
                                <PlusIcon className="w-4 h-4 mr-1"/>
                                Add
                            </button>
                        </div>

                        <MasterdataUploadModal />

                        <div className='divider divider-horizontal mx-2'/>

                        <div className='form-control'>
                            <div className='input-group'>
                                <select onChange={(e) => setMasterdataTypeCode(e.target.value)} defaultValue=''
                                        className='select select-bordered select-sm'>
                                    <option value=''>Data Type</option>
                                    {masterdataTypeCodes.sort().map((masterdataTypeCode) => {
                                        return <option key={masterdataTypeCode}>{masterdataTypeCode}</option>;
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='form-control'>
                            <div className='input-group'>
                                <input onChange={(e) => setSearchInputValue(e.target.value)} type='text'
                                       onKeyDown={(e) => {
                                           if (e.code === 'Enter') {
                                               setSearchText(searchInputValue);
                                               setTriggerReload(triggerReload + 1);
                                           }
                                       }}
                                       placeholder='key/reference search…'
                                       className='input input-bordered input-sm' />
                                <button onClick={() => setSearchText(searchInputValue)}
                                        className='btn btn-sm'>
                                    <MagnifyingGlassIcon className="h-4 w-4"/>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

                <MasterdataEntriesTable
                    key={triggerReload}
                    masterdataTypeCode={masterdataTypeCode}
                    searchText={searchText}
                    focusedCustaccount={props.focusedCustaccount}
                />

                </div>
            </div>
        </div>
    );
};

export default MasterdataPage;