import React, { useEffect, useState } from 'react';
import { AuthUser } from '../types/auth';
import { AuthService } from '../services/auth.service';
import toast from 'react-hot-toast';


export const PasswordMaintain = () => {

    const [authUser, setAuthUser] = useState<AuthUser | null>();
    const [password, setPassword] = useState<string>('');

    useEffect(() => {
        setAuthUser(AuthService.getUser());

    }, []);

    if (!authUser) return <></>;

    return (
        <div>

            {authUser.isTempPassword && <div className='flex justify-center items-center'>
                <div className='flex-col alert alert-warning shadow-lg mt-1 w-fit self-center'>

                    <div>
                        <svg xmlns='http://www.w3.org/2000/svg' className='stroke-current flex-shrink-0 h-6 w-6'
                             fill='none' viewBox='0 0 24 24'>
                            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'
                                  d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z' />
                        </svg>
                        <div>You are using a temporary password</div>
                    </div>

                    <div>
                        <input
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            className='input input-sm text-base-content' type='password' placeholder='new password' />
                        <div onClick={() => {
                            AuthService.updatePassword(password).then((response) => {
                                if (response.status === 200) {
                                    toast.success('Your password has been updated.');
                                    const copy = { ...authUser };
                                    copy.isTempPassword = false;
                                    localStorage.setItem('user', JSON.stringify(copy));
                                    setAuthUser(copy);
                                }
                            });
                        }} className='btn btn-sm btn-primary btn-link'>
                            Update
                        </div>
                    </div>

                </div>
            </div>}

        </div>
    );
};