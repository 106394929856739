import http from '../http-common';
import { UserModel, UserModelCreate, UserModelUpdate, UserSearchResponse } from '../types/user';

export const UserService = {
    searchUsers,
    createUser,
    updateUser,
    deleteUser,
    getUser
};

async function searchUsers(custaccountControl: number | null, searchText: string, resultPage: number, resultsPerPage: number) {
    return http.get<UserSearchResponse>('/app/user?resultPage=' + resultPage + '&resultsPerPage=' + resultsPerPage + (searchText ? ('&searchText=' + searchText) : '') + (custaccountControl ? ('&custaccountControl=' + custaccountControl) : ''));
}

async function createUser(userModel: UserModelCreate) {
    return http.post<UserModel>('/app/user/', userModel);
}

async function updateUser(userModel: UserModelUpdate) {
    return http.put<UserModel>(`/app/user/${userModel.uuid}`, userModel);
}

async function deleteUser(userModel: UserModel) {
    return http.delete<UserModel>('/app/user/' + userModel.uuid);
}

async function getUser(uuid: string) {
    return http.get<UserModel>('/app/user/' + uuid);
}