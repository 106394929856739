import React, { useEffect, useState } from 'react';
import { Xdoc } from '../../types/xdoc';
import { XdocService } from '../../services/xdoc.service';
import { ChevronDownIcon, ChevronUpIcon, ClipboardIcon, EyeIcon, MinusIcon } from '@heroicons/react/20/solid';
import { CustaccountModel } from '../../types/custaccount';
import toast from 'react-hot-toast';
import { LooseObject, MetadataItem } from '../../types/util-types';
import exp from 'constants';

interface XdocMetadataEditProps {
    xdoc: Xdoc,
    focusedCustaccount: CustaccountModel | null;
    onRefresh: Function
}

const REQUIRED_FIELDS: Array<String> = [
    "delivery_address_line1",
    "delivery_address_line2",
    "delivery_address_city",
    "delivery_address_country",
    "delivery_address_postal_telephone",
    "delivery_address_postal_code",
    "invoice_address_line1",
    "invoice_address_line2"
];

export const METADATA_EDIT_MAP: LooseObject = {
    'despatch_receipt': [
        "delivery_address_line1",
        "delivery_address_line2",
        "delivery_address_line3",
        "delivery_address_line4",
        "delivery_address_city",
        "delivery_address_county",
        "delivery_address_country",
        "delivery_address_postal_code",
        "delivery_address_postal_name",
        "delivery_address_postal_email",
        "delivery_address_postal_contact",
        "delivery_address_postal_telephone"
    ],
    'order': [
        "delivery_address_line1",
        "delivery_address_line2",
        "invoice_address_line1",
        "invoice_address_line2"
    ]
}

export const XDOC_PENDING_STATUS_MAP: LooseObject = {
    'despatch_receipt': "confirmation_pending",
    'order': "integration_pending"
}

export const XdocMetadataEditComponent = (props: XdocMetadataEditProps) => {
    const [metadataItems, setMetadataItems] = useState<MetadataItem []>([]);
    const [expanded, setExpanded] = useState<boolean>(false);

    const [validError, setValidError] = useState<boolean>(true);

    useEffect(()=> {
        const keys = METADATA_EDIT_MAP[props.xdoc.typeCode];
        let initialMetadata: MetadataItem [] = [];
        keys.forEach((key: string) => {
            initialMetadata.push({
                'key': key,
                'value': props.xdoc.metadata[key]
            })
        })
        setMetadataItems(initialMetadata);
    }, [props.xdoc])

    // useEffect(()=>{
    //     if (props.xdoc.typeCode === 'despatch_receipt') {
    //         setValidError(true);
    //     } else {
    //         props.xdoc.msgoutModels && props.xdoc.msgoutModels.forEach((msgout)=>{
    //             if (msgout.deliveryResponse.includes('AddressLine')) {
    //                 setValidError(true);
    //             }
    //         })
    //     }
    // }, [props.xdoc])

    return (
        <>
            {
                validError ?
                    <div className="w-full rounded border border-error p-2">
                        <span className="flex justify-end items-center cursor-pointer rounded p-2 hover:bg-base-300" onClick={()=>setExpanded(!expanded)}>
                            <p className="text-sm text-error mx-2">
                                <strong className="mr-4">Note</strong>We've identified some issues with this xdoc's metadata which may be altered to allow the xdoc to be processed.
                            </p>

                            {
                                expanded ?
                                    <span className={"btn btn-sm btn-circle btn-ghost"}>
                                        <ChevronUpIcon className={"w-4 h-4 text-error"}/>
                                    </span>
                                    :
                                    <span className={"btn btn-sm btn-circle btn-ghost"}>
                                        <ChevronDownIcon className={"w-4 h-4 text-error"}/>
                                    </span>
                            }

                        </span>

                        {
                            expanded && metadataItems.map((metadataItem, index) => (
                                <div className="flex items-center w-full justify-end gap-1 my-1" key={index}>
                                    {
                                        REQUIRED_FIELDS.includes(metadataItem.key) &&
                                        <span className={"text-error font-bold mx-2"}>
                                    *
                                </span>
                                    }
                        <span className="text-xs badge-primary badge badge-sm">
                            {
                                metadataItem.key
                            }
                        </span>
                            <input
                                type='text'
                                className={`input input-xs input-bordered w-full max-w-lg ${(REQUIRED_FIELDS.includes(metadataItem.key) && !metadataItem.value) ? 'input-error' : ''}`}
                                value={metadataItem.value}
                                onChange={(event)=>{
                                    let newMetadataItems = [...metadataItems];
                                    newMetadataItems[index]['value'] = event.currentTarget.value;
                                    setMetadataItems(newMetadataItems);
                                }}
                            />
                            <span className="btn btn-xs btn-circle btn-error tooltip tooltip-left flex items-center justify-center text-xs font-normal"
                                  data-tip={"Remove from Update"}
                                  onClick={()=>{
                                      let newMetadataItems = [...metadataItems];
                                      newMetadataItems.splice(index, 1);
                                      setMetadataItems(newMetadataItems);
                                  }}
                            >
                            <MinusIcon className={"w-4 h-4"}/>
                        </span>
                                </div>
                            ))
                        }

                        {
                            expanded &&
                            <div className="flex justify-end items-center rounded p-2">
                    <span
                        className="btn btn-secondary btn-sm btn-link"
                        onClick={()=>{
                            let blankValues: string [] = [];

                            metadataItems.forEach((metadataItem: MetadataItem) => {
                                if (metadataItem.value === "") {
                                    blankValues.push(metadataItem.key);
                                }
                            })

                            if (blankValues.length > 0) {
                                if (!window.confirm("You're attempting to set blank values for " + blankValues.join(', ') + ". Are you sure you want to continue?")) return;
                            }

                            XdocService.updateXdocMetadata(props.xdoc.uuid, props.focusedCustaccount, metadataItems).then((response)=>{
                                if (response.status >= 200 && response.status < 300) {
                                    toast.success("Xdoc metadata updated successfully.")
                                }
                                if (window.confirm("Do you want to update this xDoc to pending status as well?")) {
                                    XdocService.resetXdocStatus(props.xdoc.uuid, props.focusedCustaccount, XDOC_PENDING_STATUS_MAP[props.xdoc.typeCode]).then((response)=>{
                                        if (response.status >= 200 && response.status < 300) {
                                            toast.success("Xdoc status reset to pending.")
                                        }
                                        props.onRefresh();
                                    })
                                } else {
                                    props.onRefresh();
                                }
                            })
                        }}
                    >
                        Update Fields
                    </span>
                            </div>
                        }
                    </div>
                    :
                    <></>
            }
        </>
    );
};

export default XdocMetadataEditComponent;