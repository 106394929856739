import { QuestionMarkCircleIcon, XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';

interface BooleanIcon {
    success: boolean | null;
}

export const BooleanIcon = (props: BooleanIcon) => {

    if (props.success == null) {
        return <QuestionMarkCircleIcon className='text-yellow-600 w-6 h-6 mr-1' />;
    }

    if (props.success) {
        return <CheckCircleIcon className='text-green-600 w-6 h-6 mr-1' />;
    } else {
        return <XCircleIcon className='text-red-600 w-6 h-6 mr-1' />;
    }
};
