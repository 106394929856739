import { XdocService } from '../../services/xdoc.service';
import React, { useEffect, useState } from 'react';
import PaginationButtonGroup from '.././pagination-button-group.component';
import { PaginatedResponse } from '../../types/paginated-response';
import { AxiosResponse } from 'axios';
import { jpaDateToString } from '../../utils/date';

import { UserModel } from '../../types/user';
import { UserService } from '../../services/user.service';
import { CustaccountModel } from '../../types/custaccount';
import { SearchableTableProps } from '../../types/util-types';
import { AuthService } from '../../services/auth.service';
import toast from 'react-hot-toast';
import UserCreateUpdateModal from './create-update-modal.component';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

interface UserEntriesProps extends SearchableTableProps {
    custaccountModels: CustaccountModel[];
    focusedCustaccount: CustaccountModel | null
}

export const UserEntriesTable = (props: UserEntriesProps) => {
    const resultsPerPage = 10;

    const [entries, setEntries] = useState<UserModel[]>();
    const [refresher, setRefresher] = useState<number>(0);

    const [userEdit, setUserEdit] = useState<UserModel | null>();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();

    useEffect(() => {
        setCurrentPage(1);
    }, [props.searchText]);

    useEffect(() => {
        UserService.searchUsers(props.focusedCustaccount?.control ? props.focusedCustaccount?.control : null, props.searchText, currentPage, resultsPerPage).then((response: AxiosResponse<PaginatedResponse>) => {
            setEntries(response.data.content);
            setPaginationResponse(response.data);
        });
    }, [currentPage, props.searchText, props.focusedCustaccount, refresher]);

    const getCustaccountName = (custaccountControl: number) => {
        let name: string = '';
        props.custaccountModels.map((custaccountModel) => {
            if (custaccountModel.control === custaccountControl) {
                name = custaccountModel.name;
            }
        });

        return name;
    };

    const handleUserDelete = (userEntry: UserModel) => {
        if (window.confirm("Are you sure you want to delete " + userEntry.email + "?")) {
            UserService.deleteUser(userEntry).then(
                (response) => {
                    if (response.status >= 200 && response.status < 300) {
                        toast.success("Delete successful");
                        // window.location.reload();
                    }
                    setRefresher(refresher+1);
                }
            );
        }

    }

    return (
        <div>
            {
                userEdit &&
                <UserCreateUpdateModal
                    onFinish={()=>{
                        UserService.searchUsers(props.focusedCustaccount?.control ? props.focusedCustaccount?.control : null, props.searchText, currentPage, resultsPerPage).then((response: AxiosResponse<PaginatedResponse>) => {
                            if (response) {
                                setEntries(response.data.content);
                                setPaginationResponse(response.data);
                            }
                        });
                    }}
                    type={"update"}
                    userEdit={userEdit}
                    focusedCustaccount={props.focusedCustaccount}
                />
            }

            <div className='mt-3'>
                <table className='table w-full table-compact'>
                    <thead>
                    <tr>
                        <th>Created / Updated</th>
                        <th>Custaccount</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Last Login</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        entries && entries.map(userEntry => (
                            <tr key={userEntry.uuid}>
                                <td>
                                    <div className="flex flex-col gap-2">
                                        <AuditedInformation
                                            date={userEntry.dateUpdated}
                                            by={userEntry.updatedBy}
                                            type={AUDITED_INFORMATION_TYPES.UPDATED}
                                        />
                                        <AuditedInformation
                                            date={userEntry.dateCreated}
                                            by={userEntry.createdBy}
                                            type={AUDITED_INFORMATION_TYPES.CREATED}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>{getCustaccountName(userEntry.custaccountControl)}</div>
                                </td>
                                <td>
                                    <div>{userEntry.email}</div>
                                </td>
                                <td>
                                    <div>{userEntry.name}</div>
                                </td>
                                <td>
                                    <div>{userEntry.role}</div>
                                </td>
                                <td>
                                    <div className="monospace">{userEntry.lastLoginDate && jpaDateToString(userEntry.lastLoginDate)}</div>
                                </td>
                                <td align='center'>

                                    <div className='flex items-center justify-between'>

                                        {
                                            AuthService.isM2Admin() &&
                                            <>
                                                <label
                                                    htmlFor={`user-update-modal`}
                                                    className="btn btn-secondary btn-link btn-sm text-secondary hover:opacity-70"
                                                    onClick={()=>setUserEdit(userEntry)}
                                                >
                                                    edit
                                                </label>
                                                <div onClick={() => handleUserDelete(userEntry)}
                                                     className='btn btn-sm btn-error btn-outline mr-2'
                                                >
                                                    Delete
                                                </div>
                                            </>
                                        }
                                    </div>
                                </td>
                            </tr>))
                    }
                    </tbody>
                </table>

                {entries && entries.length == 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>

            <PaginationButtonGroup onPageClick={setCurrentPage} page={currentPage}
                                   resultsPerPage={resultsPerPage}
                                   numberOfResults={paginationResponse?.numberOfElements}
                                   totalResults={paginationResponse?.totalElements}
                                   numberOfPages={paginationResponse?.totalPages}
            />

        </div>
    );
};

export default UserEntriesTable;