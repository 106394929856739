import http from '../http-common';
import { MsginMapping, MsginMappingCreate, MsginMappingCreateRequest } from '../types/msginmapping';
import { CustaccountModel } from '../types/custaccount';

export const MsginMappingService = {
    getAllMsginMappings,
    addMsginMapping,
    removeMsginMapping,
    resubmitMsginMapping
};

async function getAllMsginMappings(focusedCustaccount: CustaccountModel | null) {
    return http.get<MsginMapping[]>('/app/msgin/mapping' + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function addMsginMapping(msginMappingCreateRequest: MsginMappingCreateRequest) {
    return http.post<MsginMappingCreate>('/app/msgin/mapping', msginMappingCreateRequest);
}

async function removeMsginMapping(control: number) {
    return http.delete<any>(`/app/msgin/mapping/${control}`);
}

async function resubmitMsginMapping(uuid: string) {
    return http.post<any>(`/app/msgin/mapping/${uuid}/submit`, JSON.stringify({}));
}