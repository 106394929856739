import http from '../http-common';
import { MsginSearchResponse } from '../types/msgin';
import { CustaccountService } from './custaccount.service';
import { CustaccountModel } from '../types/custaccount';

export const MsginService = {
    searchMsgin,
    resubmitMsginAttachment,
    uploadMsginAttachment
};

async function searchMsgin(searchText: string, resultPage: number, resultsPerPage: number, sortField: string, sortDirection: string, focusedCustaccount: CustaccountModel | null) {
    return http.get<MsginSearchResponse>('/app/msgin?resultPage=' + resultPage + '&resultsPerPage=' + resultsPerPage + (searchText ? ('&searchText=' + searchText.replace('[', '%5B')).replace(']', '%5D') : '') + (sortField ? ('&sortField=' + sortField) : '') + (sortDirection ? ('&sortDirection=' + sortDirection) : '') + (focusedCustaccount ? ('&focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function resubmitMsginAttachment(uuid: string) {
    return http.post<any>(`/app/msgin/attachment/submit/${uuid}`);
}

async function uploadMsginAttachment(file: File, focusedCustaccount: CustaccountModel | null) {
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    };

    const formData = new FormData();
    formData.append('file', file);
    formData.append('comment', 'Uploaded via msgin page.');

    return http.post<any>(`/app/msgin/attachment/upload${focusedCustaccount ? '?focusedCustaccountControl='+focusedCustaccount.control : ''}`, formData, config);
}

// async function getMsgin(uuid: string) {
//     return http.get<Xdoc>('/app/xdoc/' + uuid);
// }