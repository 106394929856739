import React, {useEffect, useState} from 'react';
import MetadataViewHorizontal from '../metadata-view-horizontal.component';
import {jpaDateToString} from "../../utils/date";
import {ProcessFlowExecModelInspect} from "../../types/processflow";
import {ProcessflowService} from "../../services/processflow.service";
import {AxiosError, AxiosResponse} from "axios";
import toast from "react-hot-toast";
import {engineService} from "../../services/engine.service";
import {OPS_WEBSOCKET_HOST} from "../../server.config";
import {SocketSubscriptionComponent} from "../socket-subscription.component";
import {QueuesResponse, TOPICS} from "../../types/socket";
import {StompSessionProvider} from "react-stomp-hooks";
import {TailSpin} from "../tail-spin.component";

interface ProcessFlowExecTailModalProps {
    tailedProcessFlowExec: ProcessFlowExecModelInspect | null,
}

export const ProcessFlowExecTailModal = (props: ProcessFlowExecTailModalProps) => {
    const [tailContent, setTailContent] = useState<string>('');
    const [tailInit, setTailInit] = useState<boolean>(false);

    useEffect(() => {
        console.log("Use Effect Trigger");

        if (props.tailedProcessFlowExec) {
            engineService.initTail(
                props.tailedProcessFlowExec.execId
            ).then((response: AxiosResponse) => {
                setTailInit(true);
                setTailContent('');
            }).catch((error: AxiosError) => {
                console.log(error.message);
                setTailInit(false);
            });
        }
    }, [props.tailedProcessFlowExec])

    return (
        <>
            {
                props.tailedProcessFlowExec &&
                <StompSessionProvider
                    url={OPS_WEBSOCKET_HOST}
                >
                    {/*SUBSCRIBE TO ENGINE AND RETRIEVE ENGINE DETAILS*/}
                    <SocketSubscriptionComponent path={'topic'}
                                                 topic={`${TOPICS.TAIL}-${props.tailedProcessFlowExec.execId}`}
                                                 callback={(tailline: string) => {
                                                     let response: QueuesResponse = JSON.parse(tailline);
                                                     let message = tailContent + response.payLoad.toString() + '<br>';

                                                     if (message.length < 5000) {
                                                         setTailContent(message);
                                                     } else {
                                                         setTailContent(message.slice(-5000));
                                                     }
                                                 }}/>
                </StompSessionProvider>
            }

            <input type="checkbox" id="process-flow-exec-tail-modal" className="modal-toggle"/>

            <div className="modal">
                <div className="modal-box max-w-8xl">

                    {
                        props.tailedProcessFlowExec &&
                        <>
                            <span className="w-full flex flex-col lg:flex-row justify-between items-center gap-2">
                        <div className="flex items-center">
                            <h3 className="font-bold text-base mr-2 w-28">Process</h3>
                            <span className="rounded-box text-primary bg-base-200 text-md py-2 px-4 font-bold">
                                {
                                    props.tailedProcessFlowExec?.procName
                                }
                            </span>
                        </div>

                        <div className="flex items-center">
                            <h3 className="font-bold text-base mr-2 w-28">Description</h3>
                            <span className="rounded-box text-primary bg-base-200 text-md py-2 px-4 font-bold">
                                {
                                    props.tailedProcessFlowExec?.procDescr
                                }
                            </span>
                        </div>
                    </span>

                            <div className='divider my-1'/>
                            <span className="w-full flex flex-col lg:flex-row justify-between items-center gap-2">
                        <div className="">
                            <div className="flex items-center mb-2">
                                <h3 className="font-bold mr-2 w-28">Id</h3>
                                <span className="rounded-box bg-base-300 text-sm py-2 px-4 monospace">
                                        {
                                            props.tailedProcessFlowExec?.execId
                                        }
                                </span>
                            </div>

                            <div className="flex items-center">
                                <h3 className="font-bold mr-2 w-28">Owner</h3>
                                <span className="rounded-box bg-base-300 text-sm py-2 px-4">
                                        {
                                            props.tailedProcessFlowExec?.procOwner
                                        }
                                </span>
                            </div>
                        </div>

                        <div className="">
                            <div className="flex items-center mb-2">
                                <h3 className="font-bold mr-2 w-28">Created</h3>
                                <span className="rounded-box bg-base-300 text-sm py-2 px-4">
                                        {
                                            props.tailedProcessFlowExec?.dateCreated && jpaDateToString(props.tailedProcessFlowExec?.dateCreated)
                                        }
                                </span>
                            </div>
                            <div className="flex items-center">
                                <h3 className="font-bold mr-2 w-28">Last Queued</h3>
                                <span className="rounded-box bg-base-300 text-sm py-2 px-4 monospace">
                                        {
                                            props.tailedProcessFlowExec?.dateLastQueued && jpaDateToString(props.tailedProcessFlowExec?.dateLastQueued)
                                        }
                                </span>
                            </div>
                        </div>
                    </span>

                            <div className='divider my-1'/>

                            {
                                props.tailedProcessFlowExec?.jobParamJson && props.tailedProcessFlowExec?.jobParamJson !== "{}" &&
                                <>
                                    <h3 className="font-bold mr-2 w-28 mb-2">Job Param</h3>
                                    <MetadataViewHorizontal
                                        metadata={JSON.parse(props.tailedProcessFlowExec?.jobParamJson)}
                                        className={null}
                                    />
                                    <div className='divider my-1'/>
                                </>
                            }

                            {
                                props.tailedProcessFlowExec?.flowVars && Object.keys(props.tailedProcessFlowExec?.flowVars).length !== 0 &&
                                <>
                                    <h3 className="font-bold mr-2 w-28 mb-2">Flow Vars</h3>

                                    <MetadataViewHorizontal
                                        metadata={props.tailedProcessFlowExec?.flowVars}
                                        className={null}
                                    />
                                    <div className='divider my-1'/>
                                </>
                            }
                        </>
                    }

                    <h3 className="font-bold mr-2 w-28 mb-2">Output Tail</h3>
                    {
                        tailInit ?
                            <pre>
                                {
                                    tailContent === "" ?
                                        <div
                                            className='flex items-center justify-center w-full max-w-full bg-base-300 rounded-box p-4 text-base-content h-96 text-xs overflow-y-scroll flex-wrap monospace break-words'>
                                            <TailSpin/>
                                        </div>
                                        :
                                        <div
                                            className="w-full bg-base-300 rounded-box p-4 text-base-content h-96 text-xs overflow-y-scroll monospace"
                                            dangerouslySetInnerHTML={{__html: tailContent}}
                                        />
                                }

                            </pre>
                            :
                            <div
                                className='flex items-center justify-center w-full max-w-full bg-base-300 rounded-box p-4 text-base-content h-96 text-xs overflow-y-scroll flex-wrap monospace break-words'>
                                <TailSpin/>
                            </div>
                    }

                    <div className="modal-action">
                        <label
                            className="btn btn-secondary btn-sm btn-outline"
                            onClick={() => {
                                setTailContent('');
                                setTailInit(false);
                            }}
                            htmlFor="process-flow-exec-tail-modal"
                        >Close</label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProcessFlowExecTailModal;