import React, { useEffect, useState } from 'react';
import ProcessFlowExecEntriesTable from '../../components/processflowexec/entries-table.component';
import { OPS_WEBSOCKET_HOST } from '../../server.config';
import { SocketSubscriptionComponent } from '../../components/socket-subscription.component';
import { TOPICS } from '../../types/socket';
import { StompSessionProvider } from 'react-stomp-hooks';
import {
    ArrowPathIcon, ArrowUturnLeftIcon, ChevronDownIcon, MagnifyingGlassIcon, TrashIcon
} from '@heroicons/react/20/solid';

import { FocusablePageProps } from '../../types/util-types';
import { ProcessFlowExecModel, ProcessFlowExecSearchResponse } from '../../types/processflow';
import { ProcessflowService } from '../../services/processflow.service';
import { AxiosError, AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { PaginatedResponse } from '../../types/paginated-response';
import { AuthService } from '../../services/auth.service';
import OrphanedEntriesTable from '../../components/processflowexec/orphaned-table.component';

const PROCESS_FLOW_EXEC_TYPE_CODES: string[] = [
    'Failed',
    'Successful'
]

const PROCESS_FLOW_EXEC_DATE_LIMITS: number[] = [
    1,
    2,
    3,
    7,
    31,
    365
]

const ProcessFlowExecPage = (props: FocusablePageProps) => {
    const [success, setSuccess] = useState<boolean>(false);
    const [noOlderThanDays, setNoOlderThanDays] = useState<number>(7);
    const [refresher, setRefresher] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    useEffect(() => {
        document.title = 'Process Flow Executions';
    }, []);

    const onFailedJobUpdate = (message: string) => {
        setRefresher(refresher+1);
    }

    const handleDiscardAll = () => {
        toast.success("Starting mass discard...")

        const counter = 1;
        const batchSize = 1000;
        const page: Promise<any []> = ProcessflowService.getFailedProcessFlowExec(
            false,
            1000,
            1,
            batchSize,
            props.focusedCustaccount
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            toast.success("Discarding batch " + counter + " of size " + batchSize);
            response.data.content.forEach((processFlowExec: ProcessFlowExecModel) => {
                ProcessflowService.discardProcessFlowExec(
                    processFlowExec.execId
                ).then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        toast.success('Job discarded.');
                    }
                }).catch((error: AxiosError) => {
                    toast.error(error.message);
                });
            })

            toast.success("Mass discard complete.");
            return response.data.content;
        });
    };

    const handleRequeueAll = () => {
        toast.success("Starting mass requeue...")

        const counter = 1;
        const batchSize = 1000;
        const page: Promise<any []> = ProcessflowService.getFailedProcessFlowExec(
            false,
            1000,
            1,
            batchSize,
            props.focusedCustaccount
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            toast.success("Requeue batch " + counter + " of size " + batchSize);
            response.data.content.forEach((processFlowExec: ProcessFlowExecModel) => {
                ProcessflowService.requeueProcessFlowExec(
                    processFlowExec.execId
                ).then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        toast.success('Job requeued.');
                    }
                }).catch((error: AxiosError) => {
                    toast.error(error.message);
                });
            })

            toast.success("Mass requeue complete.");
            return response.data.content;
        });
    };

    const handleDiscardAllOrphaned = () => {
        toast.success("Starting mass discard...")

        const counter = 1;
        const batchSize = 1000;
        const page: Promise<any []> = ProcessflowService.getOrphanedProcessFlowExec(
            1,
            1000,
            props.focusedCustaccount
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            toast.success("Discarding batch " + counter + " of size " + batchSize);
            response.data.content.forEach((processFlowExec: ProcessFlowExecModel) => {
                ProcessflowService.discardProcessFlowExec(
                    processFlowExec.execId
                ).then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        toast.success('Job discarded.');
                    }
                }).catch((error: AxiosError) => {
                    toast.error(error.message);
                });
            })

            toast.success("Mass discard complete.");
            return response.data.content;
        });
    };

    const handleRequeueAllOrphaned = () => {
        toast.success("Starting mass discard...")

        const counter = 1;
        const batchSize = 1000;
        const page: Promise<any []> = ProcessflowService.getOrphanedProcessFlowExec(
            1,
            1000,
            props.focusedCustaccount
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            toast.success("Requeue batch " + counter + " of size " + batchSize);
            response.data.content.forEach((processFlowExec: ProcessFlowExecModel) => {
                ProcessflowService.requeueProcessFlowExec(
                    processFlowExec.execId
                ).then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        toast.success('Job requeued.');
                    }
                }).catch((error: AxiosError) => {
                    toast.error(error.message);
                });
            })

            toast.success("Mass requeue complete.");
            return response.data.content;
        });
    };

    return (
        <div>
            <StompSessionProvider
                url={OPS_WEBSOCKET_HOST}
            >
                <SocketSubscriptionComponent path={"topic"} topic={TOPICS.FAILED_JOBS} callback={onFailedJobUpdate} />
            </StompSessionProvider>

            <div className='p-2 flex w-full justify-center'>
                <div className="w-full max-w-8xl bg-base-300 shadow flex flex-col">
                    <div className='flex space-x-3 items-center justify-between w-full p-2'>
                        <div className='text-sm breadcrumbs'>
                            <ul>
                                <li>
                                <span className="badge badge-lg badge-primary">
                                    Process Flow Executions
                                </span>
                                </li>
                            </ul>
                        </div>

                        <div className='flex items-center gap-2'>
                            <div className='form-control'>
                                <div className='input-group'>
                                    <input
                                        onChange={(e) => setSearchInputValue(e.target.value)}
                                        type='search'
                                        placeholder='uuid search…'
                                        className='input input-bordered input-sm w-60'
                                        value={searchInputValue}
                                    />
                                    <button onClick={() => setSearchText(searchInputValue)}
                                            className='btn btn-sm'>
                                        <MagnifyingGlassIcon className="h-4 w-4"/>
                                    </button>
                                </div>
                            </div>

                            <div className='form-control'>
                                <div className='input-group flex items-center'>
                                    <label htmlFor="date-filter"
                                           className="text-xs text-base-content text-opacity-70 mr-2">Outcome</label>
                                    <select onChange={(e) => {
                                        if (e.target.value === "Successful") {
                                            setSuccess(true);
                                        } else {
                                            setSuccess(false);
                                        }
                                    }}
                                            value={success ? "Successful" : "Failed"}
                                            className='select select-bordered select-sm'>
                                        {
                                            PROCESS_FLOW_EXEC_TYPE_CODES.map((typeCode) => {
                                                return <option key={typeCode}>{typeCode}</option>;
                                            })}
                                    </select>
                                </div>
                            </div>

                            <div className='form-control'>
                                <div className='input-group flex items-center'>
                                    <label htmlFor="date-filter"
                                           className="text-xs text-base-content text-opacity-70 mr-2">Days Old</label>
                                    <select
                                        id={"date-filter"}
                                        onChange={(e) => {
                                            setNoOlderThanDays(Number(e.target.value));
                                        }}
                                        value={noOlderThanDays}
                                        className='select select-bordered select-sm tooltip tooltip-right'
                                        data-tip={"Days Old"}
                                    >
                                        {
                                            PROCESS_FLOW_EXEC_DATE_LIMITS.map((dateLimit) => {
                                                return <option key={dateLimit}>{dateLimit}</option>;
                                            })}
                                    </select>
                                </div>
                            </div>
                            <div className='divider divider-horizontal mx-0'/>

                            {
                                AuthService.isM2Admin() &&
                                <ul className="menu menu-horizontal px-1 z-50">
                                    <li tabIndex={0}>
                                        <a className="h-full btn btn-sm btn-primary normal-case items-center justify-center text-primary-content">
                                            <ChevronDownIcon className="w-4 h-4"/>
                                            Bulk
                                        </a>

                                        <ul className="p-2 bg-base-200 gap-2 shadow min-w-full w-48">
                                            <button
                                                className={`btn btn-ghost hover:text-primary normal-case gap-2`}
                                                onClick={() => handleRequeueAll()}
                                            >
                                                <ArrowUturnLeftIcon className="w-5 h-5"/>
                                                Requeue All
                                            </button>

                                            <button className={`btn btn-error hover:bg-opacity-90 normal-case gap-2`}
                                                    onClick={() => handleDiscardAll()}
                                            >
                                                <TrashIcon className="w-5 h-5"/>
                                                Discard All
                                            </button>
                                        </ul>
                                    </li>
                                </ul>
                            }

                            <button
                                className={`btn btn-circle btn-ghost btn-sm flex items-center justify-center tooltip tooltip-right`}
                                onClick={() => {
                                    setRefresher(refresher + 1);
                                }}
                                data-tip={"Refresh"}
                            >
                                <ArrowPathIcon className="w-5 h-5"/>
                            </button>
                        </div>

                    </div>

                    <ProcessFlowExecEntriesTable
                        searchText={searchText}
                        success={success}
                        noOlderThanDays={noOlderThanDays}
                        refresher={refresher}
                        focusedCustaccount={props.focusedCustaccount}
                    />

                </div>
            </div>

            <div className='p-2 flex w-full justify-center'>
                <div className="w-full max-w-8xl bg-base-300 border border-warning shadow flex flex-col">
                    <div className='flex space-x-3 items-center justify-between w-full p-2 bg-warning bg-opacity-40'>
                        <div className='text-sm breadcrumbs'>
                            <ul>
                                <li>
                                <span className="badge badge-lg badge-primary">
                                    Orphaned Jobs
                                </span>
                                </li>
                            </ul>
                        </div>

                        <div className='flex items-center gap-2'>
                            <div className='divider divider-horizontal mx-0'/>

                            <ul className="menu menu-horizontal px-1 z-50">
                                <li tabIndex={0}>
                                    <a className="h-full btn btn-sm btn-primary normal-case items-center justify-center text-primary-content">
                                        <ChevronDownIcon className="w-4 h-4"/>
                                        Bulk
                                    </a>

                                    <ul className="p-2 bg-base-200 gap-2 shadow min-w-full w-48">
                                        <button
                                            className={`btn btn-ghost hover:text-primary normal-case gap-2`}
                                            onClick={() => handleRequeueAllOrphaned()}
                                        >
                                            <ArrowUturnLeftIcon className="w-5 h-5"/>
                                            Requeue All
                                        </button>
                                        <button className={`btn btn-error hover:bg-opacity-90 normal-case gap-2`}
                                                onClick={() => handleDiscardAllOrphaned()}
                                        >
                                            <TrashIcon className="w-5 h-5"/>
                                            Discard All
                                        </button>
                                    </ul>
                                </li>
                            </ul>

                            <button
                                className={`btn btn-circle btn-ghost btn-sm flex items-center justify-center tooltip tooltip-right`}
                                onClick={() => {
                                    setRefresher(refresher + 1);
                                }}
                                data-tip={"Refresh"}
                            >
                                <ArrowPathIcon className="w-5 h-5"/>
                            </button>
                        </div>
                    </div>

                    <OrphanedEntriesTable
                        focusedCustaccount={props.focusedCustaccount}
                        refresher={refresher}
                    />
                </div>
            </div>

        </div>
    );
};

export default ProcessFlowExecPage;