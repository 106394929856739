let opsHost: string;

const hostname = window && window.location && window.location.hostname;
if (
    hostname === 'dev-admin.m2north.co.uk'
) {
    opsHost = 'https://demo.ops.m2north.co.uk';
} else if (hostname === 'admin.m2north.co.uk') {
    opsHost = 'https://ops.m2north.co.uk';
} else if (hostname === 'local-admin.m2north.co.uk') {
    opsHost = 'http://localhost:8080';
} else {
    opsHost = 'http://localhost:8080';
}

export const OPS_WEBSOCKET_HOST: string = `${opsHost}/ops-ws`;
export const OPS_BROKER: string = `${opsHost}/app/broker`;
export const OPS_API_HOST: string = opsHost;

export const isProd = () => {
    return hostname === 'admin.m2north.co.uk';
}
export const isDev = () => {
    return hostname === 'dev-admin.m2north.co.uk';
}
export const isLocal = () => {
    return (hostname !== 'admin.m2north.co.uk') && (hostname !== 'dev-admin.m2north.co.uk');
}