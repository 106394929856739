import { StompSessionProvider } from 'react-stomp-hooks';
import { OPS_API_HOST, OPS_WEBSOCKET_HOST } from '../../server.config';
import React, { useEffect, useState } from 'react';
import { SocketSubscriptionComponent } from '../../components/socket-subscription.component';
import {
    ActiveJobsResponse,
    EngineSocketResponse,
    EngineStatusResponse, LoadSummaryResponse, QueuesResponse,
    RepoStatusResponse, SettingsResponse, StorageVolumeResponse,
    TOPICS
} from '../../types/socket';
import EngineInstanceComponent from '../../components/engine/engine-instance.component';
import { TailSpin } from '../../components/tail-spin.component';
import toast from 'react-hot-toast';
import { ProcessFlowExecModel, ProcessFlowExecModelInspect } from '../../types/processflow';
import { AxiosError, AxiosResponse } from 'axios';
import { engineService } from '../../services/engine.service';
import { PauseIcon, PlayIcon } from '@heroicons/react/20/solid';
import { ProcessflowService } from '../../services/processflow.service';
import ProcessFlowExecTailModal from '../../components/processflowexec/tail-modal.component';

interface ServerMap {
    [hostName: string]: EngineSocketResponse;
}

const EnginePage = () => {
    const [servers, setServers] = useState<ServerMap>({});
    const [tailedProcessFlowExec, setTailedProcessFlowExec] = useState<ProcessFlowExecModelInspect | null>( null);

    const handleTailProcessFlow = (processFlowExec: ProcessFlowExecModel) => {
        ProcessflowService.inspectProcessFlowExec(
            processFlowExec.execId
        ).then((response: AxiosResponse) => {
            if (response.status >= 200 && response.status < 300) {
                console.log(response)
                toast.success('Information retrieved.');
                setTailedProcessFlowExec(
                    response.data
                );
            }
        }).catch((error: AxiosError) => {
            toast.error(error.message);
        });
    }

    const handlePauseEngines = () => {
        if (window.confirm("Pause all engines?")) {
            engineService.pauseAll(
            ).then((response)=>{
                if (response.status === 200) {
                    toast.success('Engines Paused');
                }
            });
        }
    }

    const handleEnableEngines = () => {
        if (window.confirm("Enable all engines?")) {
            engineService.enableAll(
            ).then((response)=>{
                if (response.status === 200) {
                    toast.success('Engines Enabled');
                }
            });
        }
    }

    const onEngineResponse = (message: string) => {
        console.log(message);
        let server: EngineSocketResponse = JSON.parse(message);
        setServers({
            ...servers,
            [server.engineStatus.hostName]: server
        });
    };

    const onUpdateServers = (message: string, key: string) => {
        let response: QueuesResponse | SettingsResponse | EngineStatusResponse | ActiveJobsResponse | RepoStatusResponse | LoadSummaryResponse | StorageVolumeResponse;
        response = JSON.parse(message);

        if (Object.keys(servers).includes(response.hostName)) {
            let server: EngineSocketResponse = servers[response.hostName];
            // @ts-ignore
            server[key] = response;

            setServers({
                ...servers,
                [server.activeJobs.hostName]: server
            });
        } else {
            let server: EngineSocketResponse = {
                engineStatus: {
                    hostName: response.hostName,
                    payLoad: ""
                },
                activeJobs: {
                    hostName: response.hostName,
                    payLoad: []
                },
                loadSummary: {
                    hostName: response.hostName,
                    payLoad: null
                },
                queues: {
                    hostName: response.hostName,
                    payLoad: []
                },
                repoStatus: {
                    hostName: response.hostName,
                    payLoad: null
                },
                settings: {
                    hostName: response.hostName,
                    payLoad: []
                },
                storageVolumes: {
                    hostName: response.hostName,
                    payLoad: []
                }
            };
            // @ts-ignore
            server[key] = response;

            setServers({
                ...servers,
                [response.hostName]: server
            });
        }
    };

    useEffect(() => {
        document.title = 'Engine';
    }, []);

    return (
        <>
            <StompSessionProvider
                url={OPS_WEBSOCKET_HOST}
            >
                {/*SUBSCRIBE TO ENGINE AND RETRIEVE ENGINE DETAILS*/}
                <SocketSubscriptionComponent path={'app'} topic={TOPICS.ENGINE_INIT} callback={onEngineResponse} />

                <SocketSubscriptionComponent path={'topic'} topic={TOPICS.ACTIVE_JOBS} callback={(message: string)=>onUpdateServers(message, TOPICS.ACTIVE_JOBS)} />
                <SocketSubscriptionComponent path={'topic'} topic={TOPICS.ENGINE_STATUS} callback={(message: string)=>onUpdateServers(message, TOPICS.ENGINE_STATUS)} />
                <SocketSubscriptionComponent path={'topic'} topic={TOPICS.REPO_STATUS} callback={(message: string)=>onUpdateServers(message, TOPICS.REPO_STATUS)} />

                <SocketSubscriptionComponent path={'topic'} topic={TOPICS.LOAD_SUMMARY} callback={(message: string)=>onUpdateServers(message, TOPICS.LOAD_SUMMARY)} />
                <SocketSubscriptionComponent path={'topic'} topic={TOPICS.QUEUES} callback={(message: string)=>onUpdateServers(message, TOPICS.QUEUES)} />

                <SocketSubscriptionComponent path={'topic'} topic={TOPICS.SETTINGS} callback={(message: string)=>onUpdateServers(message, TOPICS.SETTINGS)} />
                <SocketSubscriptionComponent path={'topic'} topic={TOPICS.STORAGE_VOLUMES} callback={(message: string)=>onUpdateServers(message, TOPICS.STORAGE_VOLUMES)} />
            </StompSessionProvider>

            <ProcessFlowExecTailModal
                tailedProcessFlowExec={tailedProcessFlowExec}
            />

            <div className='p-2 flex w-full justify-center z-0'>
                <div className='w-full max-w-8xl bg-base-300 shadow flex flex-col p-2'>
                    <div className='w-full flex items-center justify-between pb-4 pt-2 px-4'>
                        <h3 className='text-lg text-start w-full font-bold '>
                            Engines
                        </h3>

                        <div className="flex items-center justify-end gap-2">
                            <span className="btn btn-warning btn-sm btn-outline flex items-center justify-center tooltip tooltip-bottom" onClick={handlePauseEngines} data-tip={"Pause All Engines"}>
                                <PauseIcon className='w-4 h-4 mr-2'/>
                                Pause
                            </span>

                            <span className="btn btn-success btn-sm btn-outline flex items-center justify-center tooltip tooltip-bottom" onClick={handleEnableEngines} data-tip={"Enable All Engines"}>
                                <PlayIcon className='w-4 h-4 mr-2'/>
                                Enable
                            </span>
                        </div>
                    </div>

                    {
                        Object.keys(servers).length !== 0 ?
                                Object.keys(servers).map((hostName: string, index) => {
                                    return (
                                        <div key={hostName} className='flex flex-col gap-2 items-center justify-between w-full border-b'>
                                            <EngineInstanceComponent
                                                engineInstance={servers[hostName]}
                                                onTailProcess={handleTailProcessFlow}
                                            />
                                            {
                                                index < (Object.keys(servers).length - 1) &&
                                                <div className='divider my-0' />
                                            }
                                        </div>
                                    );
                                }) :
                                    <div className='flex items-center justify-center w-full my-12'>
                                            <TailSpin />
                                    </div>
                    }
                </div>
            </div>
        </>
    );
};

export default EnginePage;