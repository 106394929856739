import { XdocService } from '../../services/xdoc.service';
import React, { useEffect, useState } from 'react';
import PaginationButtonGroup from '.././pagination-button-group.component';
import { PaginatedResponse } from '../../types/paginated-response';
import { AxiosResponse } from 'axios';

import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import { SearchableTableProps } from '../../types/util-types';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

export const CustaccountEntriesTable = (props: SearchableTableProps) => {

    const resultsPerPage = 10;

    const [entries, setEntries] = useState<CustaccountModel[]>();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();

    useEffect(() => {
        setCurrentPage(1);
    }, [props.searchText]);

    useEffect(() => {

        CustaccountService.searchCustaccounts(props.searchText ? props.searchText : '', currentPage, resultsPerPage).then((response: AxiosResponse<PaginatedResponse>) => {
            setEntries(response.data.content);
            setPaginationResponse(response.data);
        });

    }, [currentPage, props.searchText]);

    return (
        <div>
            <div className='mt-3'>
                <table className='table w-full table-compact'>
                    <thead>
                    <tr>
                        <th>Date Created</th>
                        <th>System Code</th>
                        <th>Name</th>
                        <th>Currency Symbol</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        entries && entries.map(custaccount => (
                            <tr key={custaccount.uuid}>
                                <td>
                                    <div className="flex flex-col gap-2">
                                        <AuditedInformation
                                            date={custaccount.dateUpdated}
                                            by={custaccount.updatedBy}
                                            type={AUDITED_INFORMATION_TYPES.UPDATED}
                                        />
                                        <AuditedInformation
                                            date={custaccount.dateCreated}
                                            by={custaccount.createdBy}
                                            type={AUDITED_INFORMATION_TYPES.CREATED}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>{custaccount.systemCode}</div>
                                </td>
                                <td>
                                    <div>{custaccount.name}</div>
                                </td>
                                <td>
                                    <div>{custaccount.currencySymbol}</div>
                                </td>
                                <td>

                                </td>
                            </tr>))
                    }
                    </tbody>
                </table>

                {entries && entries.length == 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>

            <PaginationButtonGroup onPageClick={setCurrentPage} page={currentPage}
                                   resultsPerPage={resultsPerPage}
                                   numberOfResults={paginationResponse?.numberOfElements}
                                   totalResults={paginationResponse?.totalElements}
                                   numberOfPages={paginationResponse?.totalPages} />

        </div>
    );
};

export default CustaccountEntriesTable;