import React, { useEffect, useState } from 'react';
import { Msgout } from '../../types/msgout';
import { codeToDescr, convertFileSize } from '../../utils/xdoc';
import MetadataViewHorizontal from '../metadata-view-horizontal.component';
import { OPS_API_HOST } from '../../server.config';
import { AuthService } from '../../services/auth.service';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

interface MsgoutViewModal {
    msgout: Msgout;
}

export const MsgoutViewModal = (props: MsgoutViewModal) => {

    if (!props.msgout) {
        return (<div>loading...</div>);
    }

    return (
        <div>
            <div className='badge badge-lg badge-primary'>Msgout</div>
            <div className='divider my-0' />

            <table className='table w-full table-compact'>
                <thead>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <table className='table w-full table-compact'>
                            <thead>
                            </thead>
                            <tbody>
                            <tr>
                                <th className='w-1/3' align='right'>File Name</th>
                                <td>
                                    <div className='flex items-center justify-between h-full'>
                                        <div className="flex items-center justify-start w-full h-full">
                                            <a target="_blank" href={`${OPS_API_HOST}/app/msgout/download/${props.msgout.uuid}?token=${AuthService.getUser()?.token}`} className="tooltip h-full flex btn btn-link px-0 text-sm items-center" data-tip="Download">
                                                {props.msgout.fileName}
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className='w-1/3' align='right'>
                                    Size
                                </th>
                                <td>
                                    <span className="badge text-xs font-bold monospace mr-2">{convertFileSize(props.msgout.fileSize)}mb</span>
                                </td>
                            </tr>
                            <tr>
                                <th className='w-1/3' align='right'>Date</th>
                                <td className="monospace">
                                    <AuditedInformation
                                        date={props.msgout?.dateCreated}
                                        by={null}
                                        type={AUDITED_INFORMATION_TYPES.CREATED}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th className='w-1/3' align='right'>Subject</th>
                                <td>
                                    {props.msgout?.subject}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <MetadataViewHorizontal
                            metadata={props.msgout.metadata}
                            className={null}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default MsgoutViewModal;