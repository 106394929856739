import { MasterdataMappingRequest } from './masterdata-mapping-request';
import { MasterdataEditField } from '../components/masterdata/metadata-edit-rows.component';

export interface MasterdataMapping {
    control: number | undefined,
    masterdataControl: number | undefined,
    masterdataTypeCode: string | undefined,
    scope: string,
    key: string,
    keyReference: string,
    metadata: { [id: string]: any; }
}

export class CreateMasterdataMappingRequest {
    masterdataTypeCode: string;
    scope: string;
    masterdataKey: string;
    mappingKey: string;
    mappingKeyReference: string | null;
    metadata: { [id: string]: any; };

    constructor(masterdataMappingRequest: MasterdataMappingRequest) {
        this.masterdataTypeCode = masterdataMappingRequest.masterdataTypeCode;
        this.scope = masterdataMappingRequest.scope;
        this.masterdataKey = '';
        this.mappingKey = masterdataMappingRequest.mappingKey;
        this.mappingKeyReference = masterdataMappingRequest.mappingKeyReference;
        this.metadata = {};
    }
}