import { MasterdataService } from '../../services/masterdata.service';
import React, { useEffect, useState } from 'react';
import { Masterdata, MasterdataResultSet } from '../../types/masterdata';
import PaginationButtonGroup from '.././pagination-button-group.component';
import { PaginatedResponse } from '../../types/paginated-response';
import { AxiosResponse } from 'axios';
import MasterdataEditModal from './edit-modal.component';
import { SearchableTableProps } from '../../types/util-types';
import toast from 'react-hot-toast';
import MetadataViewHorizontal from '../metadata-view-horizontal.component';
import { ChevronDownIcon, ChevronUpIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

interface MasterdataEntriesProps extends SearchableTableProps {
    masterdataTypeCode: string
}

export const MasterdataEntriesTable = (props: MasterdataEntriesProps) => {

    const resultsPerPage = 10;

    const [triggerReload, setTriggerReload] = useState<number>(0);

    const [masterdata, setMasterdata] = useState<Masterdata[]>();

    const [masterdataEdit, setMasterdataEdit] = useState<Masterdata | null>();
    const [metadataVisibleIndex, setMetadataVisibleIndex] = useState<number>(-1);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();

    useEffect(() => {
        setCurrentPage(1);
        setMetadataVisibleIndex(-1);
    }, [props.masterdataTypeCode, props.searchText]);

    useEffect(() => {
        MasterdataService.searchMasterdata(
            props.masterdataTypeCode,
            props.searchText,
            currentPage,
            resultsPerPage,
            props.focusedCustaccount
            ).then((response: AxiosResponse<PaginatedResponse>) => {
                setMasterdata(response.data.content);
                setPaginationResponse(response.data);
        });
        setMetadataVisibleIndex(-1);
    }, [currentPage, props.masterdataTypeCode, props.searchText, triggerReload, props.focusedCustaccount]);

    return (
        <div>

            {masterdataEdit &&
                <>
                    <input type='checkbox' checked onChange={() => {
                    }} className='modal-toggle' />
                    <div className='modal'>
                        <div className='modal-box w-11/12 max-w-5xl'>

                            <MasterdataEditModal
                                onChange={((updatedMasterdata: Masterdata) => {
                                    setMasterdataEdit(updatedMasterdata);
                                })
                                }
                                focusedCustaccount={props.focusedCustaccount}
                                masterdata={masterdataEdit}
                            />

                            <div className='modal-action'>
                                <span onClick={() => {
                                    if (!masterdataEdit.uuid) return;
                                    let res = window.confirm('Confirm entry delete?');
                                    if (res) {
                                        MasterdataService.deleteMasterdata(masterdataEdit.uuid, props.focusedCustaccount).then((response) => {
                                            if (response.status >= 200 && response.status < 300) {
                                                toast.success("Delete Successful");
                                            }
                                            setMasterdataEdit(null);
                                            setTriggerReload(triggerReload + 1);
                                        });
                                    }
                                }}
                                      className='btn btn-sm btn-error btn-outline'>Delete</span>
                                <span onClick={() => setMasterdataEdit(null)}
                                      className='btn btn-sm btn-secondary btn-outline'>Cancel</span>
                                <span onClick={() => {
                                    MasterdataService.updateMasterdata(masterdataEdit, props.focusedCustaccount).then((response) => {
                                        if (response.status >= 200 && response.status < 300) {
                                            toast.success("Update Successful");
                                        }
                                        setMasterdataEdit(null);
                                        setTriggerReload(triggerReload + 1);
                                    });
                                }} className='btn btn-sm btn-primary btn-outline'>Update</span>
                            </div>
                        </div>
                    </div>
                </>
            }

            <div className='mt-3'>
                <table className='table w-full table-compact table-zebra'>
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th>Key</th>
                        <th>Reference</th>
                        <th>Metadata</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        masterdata && masterdata.map((masterdataEntry, index) => (
                            <tr key={masterdataEntry.control}>
                                <td>{masterdataEntry.typeCode}</td>
                                <td>
                                    <div>
                                        {masterdataEntry.key}
                                    </div>
                                </td>
                                <td>
                                    <div className='my-3 max-w-xs overflow-x-auto'>{masterdataEntry.keyReference}</div>
                                </td>
                                <td>
                                    <div className="max-w-xs overflow-x-auto">
                                        {
                                            metadataVisibleIndex===index ?
                                                masterdataEntry.metadata && (Object.keys(masterdataEntry.metadata).length !== 0) ?
                                                    <>
                                                        <span className="btn btn-sm btn-ghost w-80" onClick={()=>setMetadataVisibleIndex(-1)}>
                                                            <ChevronUpIcon className="w-4 h-4 mr-2"/>
                                                            Hide
                                                        </span>

                                                        <MetadataViewHorizontal
                                                            className={"mt-2 w-80"}
                                                            metadata={masterdataEntry.metadata}
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        <span className="btn btn-sm btn-ghost w-80" onClick={()=>setMetadataVisibleIndex(-1)}>
                                                            <ChevronUpIcon className="w-4 h-4 mr-2"/>
                                                            Hide
                                                        </span>
                                                        <p className="rounded-box bg-base-100 border border-base-content border-opacity-20 flex justify-center items-center py-2 px-4 mt-2 text-base-content text-opacity-50 text-xs w-80">
                                                            None
                                                        </p>
                                                    </>
                                                :
                                                <span className="btn btn-sm btn-ghost w-80" onClick={()=>setMetadataVisibleIndex(index)}>
                                                    <ChevronDownIcon className="w-4 h-4 mr-2"/>
                                                    View
                                                </span>
                                        }

                                    </div>
                                </td>
                                <td align='center'>
                                    <div className='flex items-center justify-between'>

                                        <div onClick={() => setMasterdataEdit(masterdataEntry)}
                                             className='btn btn-sm btn-primary btn-link mr-2'>
                                            Edit
                                        </div>

                                        <AuditedInformation
                                            date={masterdataEntry.dateUpdated}
                                            by={masterdataEntry.updatedBy}
                                            type={AUDITED_INFORMATION_TYPES.UPDATED}
                                        />

                                    </div>

                                </td>
                            </tr>))
                    }
                    </tbody>
                </table>

                {masterdata && masterdata.length == 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>

            <PaginationButtonGroup onPageClick={setCurrentPage} page={currentPage}
                                   resultsPerPage={resultsPerPage}
                                   numberOfResults={paginationResponse?.numberOfElements}
                                   totalResults={paginationResponse?.totalElements}
                                   numberOfPages={paginationResponse?.totalPages}></PaginationButtonGroup>

        </div>
    );
};

export default MasterdataEntriesTable;