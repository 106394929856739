import { MasterdataService } from '../../services/masterdata.service';
import { MasterdataMappingRequest, MasterdataMappingRequestResponse } from '../../types/masterdata-mapping-request';
import React, { useEffect, useState } from 'react';
import { CreateMasterdataMappingRequest, MasterdataMapping } from '../../types/masterdata-mapping';
import MasterdataMappingRequestForm from './mapping-request-form.component';
import { AxiosResponse } from 'axios';
import PaginationButtonGroup from '../pagination-button-group.component';
import { ChevronDownIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/20/solid';
import { PaginatedResponse } from '../../types/paginated-response';
import { CustaccountModel } from '../../types/custaccount';
import { AuthService } from '../../services/auth.service';
import toast from 'react-hot-toast';
import { AUDITED_INFORMATION_TYPES, AuditedInformation } from '../audited-information.component';

interface MasterdataMappingRequestsProps {
    masterdataTypeCode: string,
    mappingKey: string,
    focusedCustaccount: CustaccountModel | null
}

export const MasterdataMappingRequestsTable = (props: MasterdataMappingRequestsProps) => {

    const resultsPerPage = 10;

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();
    const [refresher, setRefresher] = useState<number>(0);

    const [masterdataMappingRequests, setMasterdataMappingRequests] = useState<MasterdataMappingRequest[]>();

    useEffect(() => {
        setCurrentPage(1);
    }, [props.masterdataTypeCode, props.mappingKey]);

    useEffect(() => {

        MasterdataService.getMappingRequests(props.masterdataTypeCode, props.mappingKey, currentPage, resultsPerPage, props.focusedCustaccount).then((response) => {

            let masterdataMappingRequests = response.data.content.map((masterdataMappingRequest: MasterdataMappingRequest) => {
                masterdataMappingRequest.response = new CreateMasterdataMappingRequest(masterdataMappingRequest);
                return masterdataMappingRequest;
            });

            setMasterdataMappingRequests(masterdataMappingRequests);
            setPaginationResponse(response.data);
        });

    }, [currentPage, props.masterdataTypeCode, props.mappingKey, props.focusedCustaccount, refresher]);

    const deleteMasterdataMappingRequest = (completedMasterdataMappingRequest: MasterdataMappingRequest) => {

        if (!masterdataMappingRequests) return;

        let filtered = masterdataMappingRequests.filter((masterdataMappingRequest: MasterdataMappingRequest) => {
            return masterdataMappingRequest.control != completedMasterdataMappingRequest.control;
        });

        if (filtered.length === 0 && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else {
            setMasterdataMappingRequests(filtered);
        }
    };

    const wrapLongDescription = (descr: string | null) => {
        return descr;
    };

    return (
        <div>

            <div className='mt-3'>

                <table className='table w-full table-compact table-zebra'>
                    <thead>
                    <tr>
                        <th className='text-center'>Mapping Type</th>
                        <th className='text-center'>Scope</th>
                        <th className='text-left'>
                            <div className='flex content-center justify-left'>
                                <div>
                                    External Code
                                </div>
                                <div><ChevronRightIcon className='w-5 h-5' /></div>
                                <div>
                                    Your Code
                                </div>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        masterdataMappingRequests && masterdataMappingRequests.map(mappingRequest => (
                            <tr key={mappingRequest.control}>
                                <td className='text-center'>{mappingRequest.masterdataTypeCode}</td>
                                <td className='text-center'>{mappingRequest.scope}</td>
                                <td className='text-left'>
                                    <div className='grid grid-cols-1 content-center justify-items-start'>
                                        <div>
                                            <div className='text-left font-bold'>{mappingRequest.mappingKey}</div>
                                            <div
                                                className='text-left'>{wrapLongDescription(mappingRequest.mappingKeyReference)}</div>
                                        </div>
                                        <div className='w-full'>
                                            <MasterdataMappingRequestForm {...mappingRequest}
                                                                          completedCallback={deleteMasterdataMappingRequest} />
                                        </div>
                                    </div>
                                    <div className={"flex float-right items-center justify-end gap-1"}>
                                        <div className="flex flex-col gap-2 w-full">
                                            <AuditedInformation date={mappingRequest.dateCreated} by={mappingRequest.createdBy} type={AUDITED_INFORMATION_TYPES.CREATED}/>
                                            <AuditedInformation date={mappingRequest.dateUpdated} by={mappingRequest.updatedBy} type={AUDITED_INFORMATION_TYPES.UPDATED}/>
                                        </div>

                                        {
                                            (AuthService.isM2Admin() || AuthService.isCAdmin()) &&
                                            <div
                                                className='float-right btn btn-sm btn-circle btn-error tooltip flex items-center justify-center'
                                                data-tip={'Reject Request'}
                                                onClick={()=>{
                                                    MasterdataService.deleteMasterdataMappingRequest(mappingRequest.control).then((response)=>{
                                                        if (response.status >= 200 && response.status < 300) {
                                                            toast.success("Mapping request successfully deleted.")
                                                        }
                                                        setRefresher(refresher+1);
                                                    })
                                                }}
                                            >
                                                <TrashIcon className={"w-4 h-4"}/>
                                            </div>
                                        }
                                    </div>

                                </td>
                            </tr>))
                    }
                    </tbody>
                </table>

                {masterdataMappingRequests && masterdataMappingRequests.length === 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No mapping results found.</span>
                    </div>}

            </div>

            <PaginationButtonGroup onPageClick={setCurrentPage} page={currentPage}
                                   resultsPerPage={resultsPerPage}
                                   numberOfResults={paginationResponse?.numberOfElements}
                                   totalResults={paginationResponse?.totalElements}
                                   numberOfPages={paginationResponse?.totalPages}
            />

        </div>
    );
};

export default MasterdataMappingRequestsTable;