import React, { useEffect, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import MsginEntriesTable from '../../components/msgin/entries-table.component';
import MsginUploadModal from '../../components/msgin/upload-modal.component';
import { sleep } from '../../utils/interaction';
import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import { AuthService } from '../../services/auth.service';
import CustaccountSelectorComponent from '../../components/custaccount/selector.component';
import { FocusablePageProps } from '../../types/util-types';

const MsginPage = (props: FocusablePageProps) => {

    const [searchText, setSearchText] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    useEffect(() => {
        document.title = 'Msgin';
    }, []);

    return (
        <>
            <div className='p-2 flex w-full justify-center z-0'>
                <div className="w-full max-w-8xl bg-base-300 shadow flex flex-col">
                    <div className='flex space-x-3 items-center justify-between w-full p-2'>

                        <div className='text-sm breadcrumbs'>
                            <ul>
                                <li>
                                <span className="badge badge-lg badge-primary">
                                    Msgin
                                </span>
                                </li>
                            </ul>
                        </div>

                        <div className='flex gap-2 items-center'>
                            <label htmlFor="msgin-upload-modal" className="btn btn-primary btn-sm btn-link">
                                <DocumentArrowUpIcon className="w-4 h-4 mr-1"/>
                                Upload
                            </label>

                            <div className='form-control'>
                                <div className='input-group'>
                                    <input onChange={(e) => setSearchInputValue(e.target.value)}
                                           type='search'
                                           placeholder='filename search…'
                                           className='input input-bordered input-sm w-60' />
                                    <button onClick={() => setSearchText(searchInputValue)}
                                            className='btn btn-sm'>
                                        <MagnifyingGlassIcon className="h-4 w-4"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <MsginEntriesTable
                        sortField='dateUpdated'
                        sortDirection='desc'
                        searchText={searchText}
                        focusedCustaccount={props.focusedCustaccount}
                    />
                </div>
            </div>

            <MsginUploadModal
                callback={(file: File)=>{
                    setSearchText('#'+Math.random()+'#');
                    sleep(50).then(()=>{
                        setSearchText('');
                    });
                }}
                focusedCustaccount={props.focusedCustaccount}
            />
        </>
    );
};

export default MsginPage;