import React, { useEffect, useState } from 'react';
import ProcessFlowEntriesTable from '../../components/processflow/entries-table.component';

import { MagnifyingGlassIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/20/solid';
import ProcessFlowCreateUpdateModal from '../../components/processflow/create-update-modal.component';
import { FocusablePageProps } from '../../types/util-types';

const ProcessFlowPage = (props: FocusablePageProps) => {
    const [searchText, setSearchText] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    useEffect(() => {
        document.title = 'Process Flows';
    }, []);

    return (
        <div>
            <ProcessFlowCreateUpdateModal
                onFinish={()=>{
                    if (searchText.length > 2) setSearchText('');
                    else setSearchText(searchText+'#');
                }}
                type={"create"}
                processFlowEdit={null}
                focusedCustaccount={props.focusedCustaccount}
            />

            <div className='p-2 flex w-full justify-center'>
                <div
                    className="w-full bg-base-300 shadow flex flex-col"
                    style={{
                        maxWidth: '100rem'
                    }}
                >

                <div className='flex space-x-3 justify-between items-center w-full p-2'>

                    <div className='text-sm breadcrumbs'>
                        <ul>
                            <li>
                                <span className="badge badge-lg badge-primary">
                                    Process Flows
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className='flex gap-2 items-center'>
                        <label htmlFor={`process-flow-create-modal`} className="btn btn-primary btn-sm " >
                            <PlusIcon className="w-4 h-4 mr-1"/>
                            Add Processflow
                        </label>

                        <div className='form-control'>
                            <div className='input-group'>
                                <input
                                    onChange={(e) => setSearchInputValue(e.target.value)}
                                    type='search'
                                    placeholder='processflow search…'
                                    className='input input-bordered input-sm w-60'
                                    value={searchInputValue}
                                />
                                <button onClick={() => setSearchText(searchInputValue)}
                                        className='btn btn-sm'>
                                    <MagnifyingGlassIcon className="h-4 w-4"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                    <ProcessFlowEntriesTable
                        searchText={searchText}
                        focusedCustaccount={props.focusedCustaccount}
                    />
                </div>
            </div>

        </div>
    );
};

export default ProcessFlowPage;