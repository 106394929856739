import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios/index';
import { XdocService } from '../../services/xdoc.service';
import XdocEntriesTable from '../../components/xdoc/entries-table.component';
import { codeToDescr } from '../../utils/xdoc';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { CustaccountModel } from '../../types/custaccount';
import { CustaccountService } from '../../services/custaccount.service';
import CustaccountSelectorComponent from '../../components/custaccount/selector.component';
import { AuthService } from '../../services/auth.service';
import { FocusablePageProps } from '../../types/util-types';
import { compareFunction } from '../../utils/interaction';

const XDocPage = (props: FocusablePageProps) => {

    const [xdocTypeCode, setXdocTypeCode] = useState<string>('');
    const [xdocStatusCode, setXdocStatusCode] = useState<string>('');

    const [searchText, setSearchText] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const [partnerCodeSearchText, setPartnerCodeSearchText] = useState<string>('');
    const [partnerCodeInputValue, setPartnerCodeSearchInputValue] = useState<string>('');

    const [xdocTypeCodes, setXdocTypeCodes] = useState<string[]>([]);
    const [xdocStatusCodes, setXdocStatusCodes] = useState<string[]>([]);

    useEffect(() => {
        XdocService.getXocTypeSummary(props.focusedCustaccount).then((response: AxiosResponse<any>) => {
            let xdocTypeCodes: string[] = [];

            Object.keys(response.data).map((key) => {
                xdocTypeCodes.push(key);
            });

            setXdocTypeCodes(xdocTypeCodes);
        });

    }, [props.focusedCustaccount]);

    useEffect(() => {
        XdocService.getXocStatusSummary(props.focusedCustaccount).then((response: AxiosResponse<any>) => {
            let xdocStatusCodes: string[] = [];

            Object.keys(response.data).map((key) => {
                xdocStatusCodes.push(key);
            });

            setXdocStatusCodes(xdocStatusCodes);
        });
    }, [props.focusedCustaccount]);

    useEffect(() => {
        document.title = 'Xdocs';
    }, []);

    return (
        <div>
            <div className='p-2 flex w-full justify-center'>
                <div className="w-full max-w-8xl bg-base-300 shadow flex flex-col">

                <div className='flex space-x-3 justify-center items-center justify-between w-full p-2'>

                    <div className='text-sm breadcrumbs'>
                        <ul>
                            <li>
                                <span className="badge badge-lg badge-primary">
                                    xDocs
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className='flex'>
                        <div className='form-control'>
                            <div className='input-group'>
                                <select onChange={(e) => setXdocTypeCode(e.target.value)} defaultValue=''
                                        className='select select-bordered select-sm'>
                                    <option value=''>xDoc Type</option>
                                    {xdocTypeCodes
                                        .sort()
                                        .map((xdocTypeCode) => {
                                        return <option key={xdocTypeCode}
                                                       value={xdocTypeCode}>{codeToDescr(xdocTypeCode)}</option>;
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='form-control'>
                            <div className='input-group'>
                                <select onChange={(e) => setXdocStatusCode(e.target.value)} defaultValue=''
                                        className='select select-bordered select-sm'>
                                    <option value=''>xDoc Status</option>
                                    {
                                        xdocStatusCodes
                                            .sort()
                                            .map((xdocStatusCode) => {
                                        return <option key={xdocStatusCode}
                                                       value={xdocStatusCode}>{codeToDescr(xdocStatusCode)}</option>;
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='form-control'>
                            <div className='input-group'>
                                <input onChange={(e) => setPartnerCodeSearchInputValue(e.target.value)}
                                       type='search'
                                       placeholder='partnerCode search…'
                                       className='input input-bordered input-sm w-30' />
                            </div>
                        </div>

                        <div className='form-control'>
                            <div className='input-group'>
                                <input onChange={(e) => setSearchInputValue(e.target.value)}
                                       type='search'
                                       placeholder='docno search…'
                                       className='input input-bordered input-sm w-60' />
                                <button onClick={() => {
                                    setSearchText(searchInputValue);
                                    setPartnerCodeSearchText(partnerCodeInputValue);
                                }}
                                        className='btn btn-sm'
                                >
                                    <MagnifyingGlassIcon className="h-4 w-4"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                    <XdocEntriesTable
                        sortField='dateUpdated'
                        sortDirection='desc'
                        xdocTypeCode={xdocTypeCode}
                        xdocStatusCode={xdocStatusCode}
                        searchText={searchText}
                        partnerCode={partnerCodeSearchText}
                        focusedCustaccount={props.focusedCustaccount}
                    />
                </div>
            </div>

        </div>
    );
};

export default XDocPage;