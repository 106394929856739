export const DAISY_THEME_MAP = {
    corporate: {
        primary: '#3480C7',
        'primary-content': '#FCFBF8',

        secondary: '#1e293b',
        'secondary-content': '#FCFBF8',

        accent: '#59677D',
        'accent-content': '#FCFBF8',

        neutral: '#f7f8fa',
        'neutral-content': '#020617',

        'base-100': '#EEF4F4',
        'base-200': '#f2f8f7',
        'base-300': '#F7FCFA',
        'base-content': '#020617',

        success: '#16a34a',
        'success-content': '#F7FCFA',
        warning: '#ca8a04',
        'warning-content': '#F7FCFA',
        error: '#dc2626',
        'error-content': '#F7FCFA',

        radius: '2px'
    },
    business: {
        primary: '#539BF3',
        'primary-content': '#FAFAFF',

        secondary: '#818188',
        'secondary-content': '#FAFAFF',

        accent: '#F3F3F7',
        'accent-content': '#10101B',

        neutral: '#27272a',
        'neutral-content': '#FAFAFF',

        'base-100': '#10101B',
        'base-200': '#191C2A',
        'base-300': '#1D2231',
        'base-content': '#FAFAFF',

        success: '#4ade80',
        'success-content': '#10101B',

        warning: '#facc15',
        'warning-content': '#10101B',

        error: '#f87171',
        'error-content': '#10101B',

        radius: '2px'
    },
    dark: {
        primary: '#A08EE1',
        'primary-content': '#F8FAFE',

        secondary: '#3C4562',
        'secondary-content': '#F8FAFE',

        accent: '#E5EAF0',
        'accent-content': '#0C111C',

        neutral: '#1F2937',
        'neutral-content': '#F8FAFE',

        'base-100': '#110C1C',
        'base-200': '#1a1626',
        'base-300': '#222131',
        'base-content': '#F8FAFE',

        success: '#4ade80',
        'success-content': '#0C111C',
        warning: '#facc15',
        'warning-content': '#0C111C',
        error: '#f87171',
        'error-content': '#0C111C',
    },
};

export function addOpacity(hexColor, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return hexColor + _opacity.toString(16).toUpperCase();
}

export function generateSelectStylesDaisy(daisyTheme, windowSize = { innerWidth: 1920 }) {
    return {
        control: (base, state) => ({
            ...base,
            fontSize: '0.875rem',
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],

            border: `1px solid ${DAISY_THEME_MAP[daisyTheme]['base-muted']}`,
            backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-100'],
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            minHeight: '1.5rem',
            padding: '0.1rem 0.25rem',
            boxShadow: state.isFocused
                ? `0px 0px 0px 0px ${DAISY_THEME_MAP[daisyTheme]['bg-base-100']}`
                : `0px 0px 0px 0px ${DAISY_THEME_MAP[daisyTheme]['bg-base-100']}`,

            outline: state.isFocused && `2px solid ${DAISY_THEME_MAP[daisyTheme]['base-muted']}`,
            outlineOffset: state.isFocused && '2px',

            '&:hover': {
                cursor: 'pointer',
                boxShadow: `0 0 0 0 ${DAISY_THEME_MAP[daisyTheme]['bg-base-100']}`
            }
        }),
        menu: (provided, state) => ({
            ...provided,
            fontSize: '0.875rem',
            backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-100'],
            opacity: 1,
            color: DAISY_THEME_MAP[daisyTheme]['base-100'],
            border: `1px solid ${DAISY_THEME_MAP[daisyTheme]['base-100']}`,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            dropShadow: `0 0 1px 2px ${DAISY_THEME_MAP[daisyTheme]['base-100']}`,
            zIndex: 1000,

            padding: '0.5rem'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: DAISY_THEME_MAP[daisyTheme][`base-100`],
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            padding: '0.25rem 0.5rem',
            width: 'auto',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            fontWeight: 'bold',

            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0
        }),
        singleValueLabel: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            boxShadow: 0
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: DAISY_THEME_MAP[daisyTheme][`base-200`],
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            padding: '0.1rem 0.25rem',
            width: 'auto',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            fontWeight: 'bold',

            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            boxShadow: 0
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            backgroundColor: DAISY_THEME_MAP[daisyTheme][`primary`],
            color: DAISY_THEME_MAP[daisyTheme]['primary-content'],
            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0,
            '&:hover': {
                backgroundColor: DAISY_THEME_MAP[daisyTheme][`primary`],
                color: DAISY_THEME_MAP[daisyTheme]['base-content']
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            boxShadow: 0
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],

            border: 0,
            boxShadow: 0,
            minHeight: '2rem',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',

            '&:hover': {
                border: 0
            }
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0,
            '&:hover': {
                color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-300'],
                cursor: 'pointer'
            }
        }),
        indicatorSeparator: (styles) => ({ display: 'none' }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-100'],
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            cursor: 'pointer',
            border: 0,
            boxShadow: 0,
            '&:hover': {
                backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-300'],
                cursor: 'pointer'
            }
        }),
        loadingIndicator: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme][`primary`]
        }),
        input: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            boxShadow: 0,
            minHeight: '1.8rem'
        })
    };
}