import React, { useEffect, useState } from 'react';
import PaginationButtonGroup, {ResultsPerPageOptions} from '.././pagination-button-group.component';
import { PaginatedResponse } from '../../types/paginated-response';
import { AxiosError, AxiosResponse } from 'axios';
import { jpaDateToString } from '../../utils/date';

import { ProcessflowService } from '../../services/processflow.service';
import { ProcessFlowExecModel, ProcessFlowExecModelInspect } from '../../types/processflow';
import {
    ArrowPathIcon, ArrowUturnLeftIcon, BackspaceIcon, ClipboardIcon, MagnifyingGlassIcon,
    QuestionMarkCircleIcon,
    TrashIcon
} from '@heroicons/react/20/solid';
import toast from 'react-hot-toast';
import ProcessFlowExecViewModal from './view-modal.component';
import { CustaccountModel } from '../../types/custaccount';
import ProcessFlowExecTailModal from "./tail-modal.component";
import OrphanedModalComponent from "./orphaned-modal.component";
import { copyToClipboard } from '../../utils/interaction';

interface OrphanedEntriesTableProps {
    refresher: number,
    focusedCustaccount: CustaccountModel | null
}

export const OrphanedEntriesTable = (props: OrphanedEntriesTableProps) => {
    const [resultsPerPage, setResultsPerPage] = useState<number>(50);

    const [entries, setEntries] = useState<ProcessFlowExecModel[]>();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginationResponse, setPaginationResponse] = useState<PaginatedResponse>();

    const [inspectedProcessFlowExec, setInspectedProcessFlowExec] = useState<ProcessFlowExecModelInspect | null>( null);

    useEffect(() => {
        ProcessflowService.getOrphanedProcessFlowExec(
            currentPage,
            resultsPerPage,
            props.focusedCustaccount
        ).then((response: AxiosResponse<PaginatedResponse>) => {
            if (response) {
                console.log(response.data.content);
                setEntries(response.data.content);
                setPaginationResponse(response.data);
            }
        });

    }, [currentPage, props.focusedCustaccount, props.refresher]);

    const handleRequeue = (processFlowExec: ProcessFlowExecModel) => {
        if (!processFlowExec.scheduled) {
            ProcessflowService.requeueProcessFlowExec(
                processFlowExec.execId
            ).then((response: AxiosResponse) => {
                if (response.status === 200) {
                    toast.success('Job requeued.');

                    ProcessflowService.getOrphanedProcessFlowExec(
                        currentPage,
                        resultsPerPage,
                        props.focusedCustaccount
                    ).then((response: AxiosResponse<PaginatedResponse>) => {
                        if (response) {
                            setEntries(response.data.content);
                            setPaginationResponse(response.data);
                        }
                    });

                }
            }).catch((error: AxiosError) => {
                toast.error(error.message);
            });
        }
    }

    const handleInspect = (processFlowExec: ProcessFlowExecModel) => {
        ProcessflowService.inspectProcessFlowExec(
            processFlowExec.execId
        ).then((response: AxiosResponse) => {
            if (response.status === 200) {
                console.log(response);
                toast.success('Information retrieved.');
                setInspectedProcessFlowExec(
                    response.data
                );
            }
        }).catch((error: AxiosError) => {
            toast.error(error.message);
        });
    }

    const handleDiscard = (processFlowExec: ProcessFlowExecModel) => {
            ProcessflowService.discardProcessFlowExec(
                processFlowExec.execId
            ).then((response: AxiosResponse) => {
                if (response.status === 200) {
                    toast.success('Job discarded.');

                    ProcessflowService.getOrphanedProcessFlowExec(
                        currentPage,
                        resultsPerPage,
                        props.focusedCustaccount
                    ).then((response: AxiosResponse<PaginatedResponse>) => {
                        if (response) {
                            setEntries(response.data.content);
                            setPaginationResponse(response.data);
                        }
                    });
                }
            }).catch((error: AxiosError) => {
                toast.error(error.message);
            });
    }

    return (
        <div className="max-w-8xl w-full">
            <OrphanedModalComponent
                inspectedProcessFlowExec={inspectedProcessFlowExec}
                onDiscard={handleDiscard}
                onRequeue={handleRequeue}
            />

            <div className=''>
                <table className={`table w-full table-compact table-zebra ${entries && entries.length > 0 && 'border-2 border-warning'}`}>
                    <thead>
                    <tr>
                        <th className="">Date Last Queued</th>
                        <th className="">Owner</th>
                        <th className="">Process</th>
                        <th className="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        entries && entries.map(processflowexec => (
                            <React.Fragment key={processflowexec.execId}>
                                <tr className="hover:drop-shadow">
                                    <td colSpan={1} className="monospace">
                                        {processflowexec.dateLastQueued ?
                                            jpaDateToString(processflowexec.dateLastQueued)
                                            :
                                            processflowexec.dateCreated ?
                                                jpaDateToString(processflowexec.dateCreated)
                                                :
                                                '-'
                                        }
                                    </td>
                                    <td colSpan={1}>
                                        {processflowexec.flowOwner}
                                    </td>
                                    <td colSpan={1}>
                                        <div className="w-full py-2.5 text-sm">
                                            <span className="flex items-center">
                                                <span className="w-24 font-bold">
                                                    Flow Description
                                                </span>
                                                <span className="w-full text-center">
                                                    {processflowexec.flowDescr}
                                                </span>
                                            </span>
                                            <div className='my-1' />
                                            <span className="flex items-center">
                                                <span className="w-24 font-bold">
                                                    Process
                                                </span>
                                                <span className="rounded-btn px-2 py-0.5 w-full text-center text-primary font-bold">
                                                    {processflowexec.procName}
                                                </span>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex items-center w-full gap-2 justify-center">
                                            <button
                                                className={`btn btn-success btn-link btn-sm hover:opacity-70`}
                                                onClick={()=>handleRequeue(processflowexec)}>
                                                requeue
                                            </button>

                                            <label
                                                htmlFor={"orphaned-process-flow-exec-modal"}
                                                className="btn btn-link text-secondary hover:opacity-70 btn-sm"
                                                onClick={()=>handleInspect(processflowexec)}
                                            >
                                                inspect
                                            </label>

                                            <button
                                                className="btn btn-warning btn-outline btn-sm"
                                                onClick={()=>handleDiscard(processflowexec)}>
                                                discard
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                            ))
                    }
                    </tbody>
                </table>

                {entries && entries.length === 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>

            <PaginationButtonGroup onPageClick={setCurrentPage} page={currentPage}
                                   resultsPerPage={resultsPerPage}
                                   numberOfResults={paginationResponse?.numberOfElements}
                                   totalResults={paginationResponse?.totalElements}
                                   numberOfPages={paginationResponse?.totalPages}
            />

            <div className="w-full flex items-center justify-center gap-2 mb-2 monospace">
                <select
                    onChange={(e) => {
                        setResultsPerPage(Number(e.target.value))
                        setCurrentPage(1);
                    }}
                    value={resultsPerPage}
                    className='select select-bordered select-xs'>
                    {
                        ResultsPerPageOptions.map((results) => {
                            return <option key={results}>{results}</option>;
                        })
                    }
                </select>
            </div>
        </div>
    );
};

export default OrphanedEntriesTable;