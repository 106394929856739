import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import MetadataViewHorizontal from '../metadata-view-horizontal.component';
import { ProcessService } from '../../services/process.service';
import { ProcessView } from '../../types/process';
import { SearchableTableProps } from '../../types/util-types';

export const ProcessEntriesTable = (props: SearchableTableProps) => {
    const [entries, setEntries] = useState<ProcessView[]>([]);
    const [filteredEntries, setFilteredEntries] = useState<ProcessView[]>([]);

    useEffect(() => {
        ProcessService.getProcesses(
        ).then((response: AxiosResponse) => {
            setEntries(response.data);
            console.log(response.data);
        });
    }, []);

    useEffect(()=>{
        setFilteredEntries(entries.filter((entry) => (entry.owner.toLowerCase().includes(props.searchText.toLowerCase()) || entry.className.toLowerCase().includes(props.searchText.toLowerCase()))));
    }, [props.searchText, entries])

    return (
        <div className='w-full'>
            <div className="grid grid-cols-12 gap-2 rounded-box p-4 bg-base-200 w-full text-xs">

                <p className="font-bold col-span-2">
                    Owner
                </p>
                <p className="font-bold col-span-3">
                    Process
                </p>
                <p className="font-bold col-span-2">
                    Param In
                </p>
                <p className="font-bold">
                    Param Out
                </p>

                <p className="font-bold col-span-2">
                    Flow Parameters
                </p>

                <p className="font-bold col-span-2">
                    Masterdata
                </p>

                <div className='divider col-span-12 my-1'/>

                {
                    filteredEntries && filteredEntries.map((process, index) => (
                        <React.Fragment key={index}>
                            <div className="break-words font-bold col-span-2">{process.owner}</div>

                            <div className="col-span-3 break-words ">{process.className}</div>

                            <div className="break-words col-span-2">{process.jobDetailClassName}</div>

                            <div className="break-words ">{process.resultJobDetailClassName}</div>

                            <div className="col-span-2">
                                <MetadataViewHorizontal
                                    metadata={process.flowVars}
                                    className={null}
                                />
                            </div>

                            <div className="col-span-2">
                                {
                                    process.flowStepMasterdataTypes &&
                                    <MetadataViewHorizontal
                                        metadata={process.flowStepMasterdataTypes}
                                        className={null}
                                    />
                                }
                            </div>

                            <div className='divider col-span-12 my-2'/>
                        </React.Fragment>))
                }

                {entries && entries.length == 0 &&
                    <div className='flex w-full flex-1 justify-center m-5'>
                        <span>No results found.</span>
                    </div>}

            </div>
        </div>
    );
};

export default ProcessEntriesTable;