import http from '../http-common';
import { Xdoc, XdocSearchResponse } from '../types/xdoc';
import { CustaccountModel } from '../types/custaccount';
import { AuthService } from './auth.service';
import { LooseObject, MetadataItem } from '../types/util-types';

export const XdocService = {
    getXocTypeSummary,
    getXocStatusSummary,
    searchXdocs,
    getXdoc,
    deleteXdoc,
    resetXdocStatus,
    updateXdocMetadata
};

async function getXocTypeSummary(focusedCustaccount: CustaccountModel | null) {
    return http.get<any>('/app/xdoc/type/summary' + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function getXocStatusSummary(focusedCustaccount: CustaccountModel | null) {
    return http.get<any>('/app/xdoc/status/summary' + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function searchXdocs(searchText: string, partnerCode: string, typeCode: string, statusCode: string, resultPage: number, resultsPerPage: number, sortField: string, sortDirection: string, focusedCustaccount: CustaccountModel | null) {
    return http.get<XdocSearchResponse>('/app/xdoc?discardLazyLoadedFields=xdocAuditModels,xdocDetModels,msginAttachmentModels,msgoutModels&resultPage=' + resultPage + '&resultsPerPage=' + resultsPerPage + (searchText ? ('&searchText=' + searchText.replace('[', '%5B')).replace(']', '%5D') : '') + (partnerCode ? ('&partnerCode=' + partnerCode.replace('[', '%5B')).replace(']', '%5D') : '') + (typeCode ? ('&typeCode=' + typeCode) : '') + (statusCode ? ('&statusCode=' + statusCode) : '') + (sortField ? ('&sortField=' + sortField) : '') + (sortDirection ? ('&sortDirection=' + sortDirection) : '') + (focusedCustaccount ? ('&focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function getXdoc(uuid: string | null, focusedCustaccount: CustaccountModel | null) {
    return http.get<Xdoc>('/app/xdoc/' + uuid  + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function deleteXdoc(uuid: string, focusedCustaccount: CustaccountModel | null) {
    return http.delete<Xdoc>('/app/xdoc/' + uuid  + (focusedCustaccount ? ('?focusedCustaccountControl=' + focusedCustaccount.control) : ''));
}

async function resetXdocStatus(uuid: string | null | undefined, focusedCustaccount: CustaccountModel | null, statusCode: string) {
    return http.post<Xdoc>('/app/xdoc/' + uuid  + (focusedCustaccount ? ('/status?focusedCustaccountControl=' + focusedCustaccount.control) : '') + `&status=${statusCode}`);
}

async function updateXdocMetadata(uuid: string | null | undefined, focusedCustaccount: CustaccountModel | null, metadata: MetadataItem []) {
    console.log(metadata);
    return http.put<Xdoc>('/app/xdoc/' + uuid  + (focusedCustaccount ? ('/metadata?focusedCustaccountControl=' + focusedCustaccount.control) : '') + '', metadata);
}