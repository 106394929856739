import React, { useEffect, useState } from 'react';
import { MasterdataMapping } from '../../types/masterdata-mapping';
// @ts-ignore
import { ObjectView } from 'react-object-view';
import { MasterdataTemplateFieldModel } from '../../types/masterdata-template';
import { MasterdataMappingRequest } from '../../types/masterdata-mapping-request';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

interface MasterdataMetadataValidatedInputProps {
    template: MasterdataTemplateFieldModel | null;
    name: string;
    value: any;
    onChange: ((value: any) => void);
}

export const MasterdataMetadataValidatedInput = (props: MasterdataMetadataValidatedInputProps) => {
    const [viewPassword, setViewPassword] = useState<boolean>(false);

    const getPlaceholder = () => {
        if (!props.template) return '';
        return props.template.required ? 'required' : 'optional';
    };

    if (!props.template) {
        return (
            <input
                type='text'
                value={props.value}
                onChange={(e) => {
                    if (props.onChange) props.onChange(e.target.value);
                }}
                placeholder=''
                className='input input-bordered input-xs ml-3 w-full'
            />
        );
    }

    return (
        <>
            {props.template.type === 'boolean' &&
                <input type='checkbox'
                       checked={props.value}
                       onChange={(e) => {
                           if (props.onChange) props.onChange(e.target.checked);
                       }}
                       placeholder={getPlaceholder()}
                       className='checkbox checkbox-sm ml-3'
                />
            }
            {props.template.type === 'integer' &&
                <input step='1' type='number'
                       value={props.value}
                       onChange={(e) => {
                           if (e.target.value == '' || isNaN(+e.target.value) || !Number.isInteger(+e.target.value)) {
                               if (props.onChange) props.onChange(null);
                           } else {
                               if (props.onChange) props.onChange(+e.target.value);
                           }
                       }}
                       placeholder={getPlaceholder()}
                       className='input input-bordered input-xs ml-3' />
            }
            {props.template.type === 'float' &&
                <input step='0.01' type='number'
                       value={props.value}
                       onChange={(e) => {
                           if (e.target.value == '' || isNaN(+e.target.value)) {
                               if (props.onChange) {
                                   props.onChange(null);
                               }
                           } else {
                               if (props.onChange) props.onChange(+e.target.value);
                           }
                       }}
                       placeholder={getPlaceholder()}
                       className='input input-bordered input-xs ml-3' />
            }
            {props.template.type === 'string' &&
                    props.template.privateField ?
                        <div className="flex items-center w-full">
                            <input type={viewPassword ? `text` : 'password'}
                                   value={props.value}
                                   onChange={(e) => {
                                       if (props.onChange) props.onChange(e.target.value);
                                   }}
                                   placeholder={getPlaceholder()}
                                   className='input input-bordered input-xs ml-3 w-full' />
                            <span className="btn btn-sm btn-ghost btn-circle ml-2"
                                  onClick={()=>setViewPassword(!viewPassword)}>
                                {
                                    viewPassword ?
                                        <EyeSlashIcon className="w-4 h-4"/>
                                        :
                                        <EyeIcon className="w-4 h-4"/>
                                }

                            </span>
                        </div>
                        :
                        <input type={`text`}
                               value={props.value}
                               onChange={(e) => {
                                   if (props.onChange) props.onChange(e.target.value);
                               }}
                               placeholder={getPlaceholder()}
                               className='input input-bordered input-xs ml-3 w-full' />

            }
            {props.template.type === 'date' &&
                <input type='date'
                       value={props.value}
                       onChange={(e) => {
                           if (props.onChange) props.onChange(e.target.value);
                       }}
                       placeholder={getPlaceholder()}
                       className='input input-bordered input-xs ml-3' />
            }
        </>
    );
};

export default MasterdataMetadataValidatedInput;